import axios from "axios";
import cookie from 'cookie';
const axiosInstance = axios.create()

const getConfig = () => {
    const { shopName, _token: token, _id: customerId, _c_id: customerToken, _customApp, _routePrefix: routePrefix } = cookie.parse(document.cookie);

    let customApp = [];

    if (_customApp) {
        customApp = _customApp.split(',');
    }

    const settings = { token, customerId, customerToken, customApp, shopName, routePrefix };
    return settings;
}


axiosInstance.interceptors.request.use((config) => {

    const { customerId, customerToken, routePrefix } = getConfig();
    if (customerToken && customerId) {

        config.headers['token'] = customerToken;
        config.headers['customerId'] = customerId;

    }
    const urlArr = config.url.split("/");
    config["url"] = `${routePrefix}/${urlArr.slice(2, urlArr.length).join("/")}`
    return config
})

export default axiosInstance;