import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import MediaFiles from "./components/ProductMedia/MediaFiles";
import Sync from "./components/ProductMedia/Sync";

import { useSettings } from "../AppContext";
const SETTING = "showProductMedia";

function ProductMedia() {
  const { resources } = useSettings();
  let resourcesList = [];

  if (resources) {
    resourcesList = resources.filter((item) => item.setting === SETTING);
  }

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((index) => setSelected(index), []);

  const tabs = [
    {
      id: "ProductMedia",
      title: "Product Media",
      content: "Product Media",
      page: <MediaFiles />,
      panelID: "ProductMedia",
    },
    {
      id: "Sync",
      title: "Sync",
      content: "Sync",
      page: <Sync />,
      panelID: "Sync",
    },
  ];

  if (resourcesList && resourcesList.length === 1) {
    resourcesList = resourcesList[0].resources;

    if (resourcesList && resourcesList.length > 0) {
      resourcesList = resourcesList.split(",").map((item) => item.trim());
      tabs = tabs.filter((item) => resourcesList.includes(item.id));
    }
  }

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default ProductMedia;
