import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import Invited from "./components/Invites/Invited";
import Registered from "./components/Invites/Registered";
import UserTypes from "./components/Invites/UserTypes";
import store from "store-js";

export default function PartnersUser() {
  const currentTab = store.get("partnerTab") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("partnerTab", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Invited",
      title: "Invited Users",
      content: "Invited",
      page: <Invited status={"invited"}/>,
      panelID: "Invited",
    },
    {
      id: "Registered",
      title: "Registered Users",
      content: "Registered",
      page: <Registered status={"registered"}/>,
      panelID: "Registered",
    },
    {
      id: "UserTypes",
      title: "User Types",
      content: "User Types",
      page: <UserTypes/>,
      panelID: "UserTypes",
    },
  ];

  return (
    <Page
      breadcrumbs={[{ url: "/" }]}
      title={tabs[selected].title}
      fullWidth
    >
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
