import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import Pending from "./components/students/Pending";
import Approved from "./components/students/Approved";
import Rejected from "./components/students/Rejected";
import store from "store-js";

export default function Students() {
  const currentTab = store.get("studentTab") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("studentTab", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Pending",
      title: "Pending",
      content: "Pending",
      page: <Pending />,
      panelID: "Pending",
    },
    {
      id: "Approved",
      title: "Approved",
      content: "Approved",
      page: <Approved />,
      panelID: "Approved",
    },
    {
      id: "Rejected",
      title: "Rejected",
      content: "Rejected",
      page: <Rejected />,
      panelID: "Rejected",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title="Students" fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
