import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'

import 'bootstrap/dist/css/bootstrap.min.css';
import { dataTypes } from './constants';

class FilterSOLR extends Component {
    constructor(props) {
      super(props);

      this.state = {
        filter: props.filter
      };
    }

    componentDidMount() {
    }
        
    updateType = async (e) => {
      const filter = this.state.filter;
      filter.type = e.target.value;
      
      this.setState({
          filter
      });

      this.props.filterChanged(filter);
    }

    updateOutput = async (e) => {
      const filter = this.state.filter;
      filter.output = e.target.checked;
      
      this.setState({
          filter
      });

      this.props.filterChanged(filter);
    }

    updateSearch = async (e) => {
      const filter = this.state.filter;
      filter.search = e.target.checked;
      
      this.setState({
          filter
      });

      this.props.filterChanged(filter);
    }

    render() {
        return (
            <Container className='p-2'>
              <Row className='p-2'>
                <Col>
                  <Form.Label className='mt-2'>Filter:</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Label>{this.state.filter.name}</Form.Label>
                </Col>
              </Row>
              <Row className='p-2'>
                <Col>
                  <Form.Label className='mt-2'>Data Type:</Form.Label>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    as="select"
                    style={{
                      maxWidth: '200px',
                      fontSize: '14px',
                    }}
                    value={this.state.filter.type} 
                    onChange={this.updateType}
                  >
                    {dataTypes && dataTypes.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
              <Row className='p-2'>
                <Col>
                  <label style={{
                    display: 'block',
                    paddingLeft: '15px',
                    textIndent: '-15px',
                  }} >
                    <input
                      type="checkbox"
                      style={{
                        width: '15px',
                        height: '15px',
                        padding: '0',
                        margin: '0',
                        verticalAlign: 'bottom',
                        position: 'relative',
                        top: '-3px'
                      }}
                      checked={ this.state.filter.output }
                      onClick={ this.updateOutput }
                    /> Return to PLP
                  </label>
                </Col>
              </Row>
              <Row className='p-2'>
                <Col>
                  <label style={{
                    display: 'block',
                    paddingLeft: '15px',
                    textIndent: '-15px',
                  }} >
                    <input
                      type="checkbox"
                      style={{
                        width: '15px',
                        height: '15px',
                        padding: '0',
                        margin: '0',
                        verticalAlign: 'bottom',
                        position: 'relative',
                        top: '-3px'
                      }}
                      checked={ this.state.filter.search }
                      onClick={ this.updateSearch }
                    /> Include in Search
                  </label>
                </Col>
              </Row>
            </Container>
        );
    }
}

export default FilterSOLR;
