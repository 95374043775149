import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./PDFViewer.css";

function PDFViewer({ name, PDFBase64 }) {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);

  const pdfRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setError(null);
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF: ", error);
    setError("Failed to load PDF document.");
    setNumPages(null);
  };

  const zoomIn = () => setScale((prevScale) => prevScale + 0.1);
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.1));
  const reset = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const downloadPDF = () => {
    if (!PDFBase64) {
      setError("No PDF data available for download.");
      return;
    }
    const link = document.createElement("a");
    link.href = PDFBase64;
    link.download = `${name}.pdf`;
    link.click();
  };

  useEffect(() => {
    const container = pdfRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;

      prevPosition = { x: e.clientX, y: e.clientY };

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    container?.addEventListener("mousedown", handleMouseDown);
    container?.addEventListener("mousemove", handleMouseMove);
    container?.addEventListener("mouseup", handleMouseUp);

    return () => {
      container?.removeEventListener("mousedown", handleMouseDown);
      container?.removeEventListener("mousemove", handleMouseMove);
      container?.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  if (!PDFBase64) {
    return null;
  }

  return (
    <div
      className="pdf-container"
      style={{
        overflow: "scroll",
      }}
    >
      <div className="btn-container-pdf">
        <button onClick={zoomIn}>
          <span className="material-symbols-outlined">add</span>
        </button>
        <button onClick={zoomOut}>
          <span className="material-symbols-outlined">remove</span>
        </button>
        <button onClick={reset}>
          <span className="material-symbols-outlined">history</span>
        </button>
        <button onClick={downloadPDF}>
          <span className="material-symbols-outlined">download</span>
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div
        ref={pdfRef}
        className="pdf-document"
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          transformOrigin: "0 0", // Ensure scaling from the top-left corner
        }}
      >
        <Document
          file={PDFBase64}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page key={index} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </div>
  );
}

export default PDFViewer;
