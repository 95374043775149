import React, { Component } from "react";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import Button from "react-bootstrap/Button";

import "bootstrap/dist/css/bootstrap.min.css";

class EmailDomainsUI extends Component {
  constructor(props) {
    super(props);

    const { emailDomains, statusError, statusText } = props;

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          pinned: "left",
          width: 30,
          field: "selectedBtn",
        },
        {
          headerName: "Domain",
          field: "domain",
          flex: 1,
          editable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      rowData: emailDomains,
      loading: false,
      statusError: statusError,
      statusText: statusText,
      deleteDisabled: true,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.emailDomains !== prevState.rowData) {
      this.setState({
        rowData: prevProps.emailDomains,
      });

      this.gridApi.setRowData(prevProps.emailDomains);
    }

    if (prevProps.statusError !== prevState.statusError) {
      this.setState({
        statusError: prevProps.statusError,
      });

      if (prevProps.statusError) {
        this.gridApi.setRowData(prevProps.emailDomains);
      }
    }

    if (prevProps.statusText !== prevState.statusText) {
      this.setState({
        statusText: prevProps.statusText,
      });
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onCellValueChanged = async (params) => {
    const colId = params.column.colId;

    if (colId === "domain") {
      const { oldValue, newValue } = params;
      let emailDomains = this.state.rowData.map((item) => item.domain);

      if (oldValue !== undefined) {
        emailDomains = emailDomains.filter((item) => item !== oldValue);
      }

      emailDomains.push(newValue);
      emailDomains = [...new Set(emailDomains)].sort();

      this.props.emailDomainsChanged(emailDomains);
    }
  };

  addRow = async () => {
    this.gridApi.applyTransaction({ add: [{}] });
  };

  deleteRows = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const domains = [...new Set(selectedRows.map((item) => item.domain))];

    const data = this.state.rowData.filter(
      (item) => !domains.includes(item.domain)
    );

    let emailDomains = data.map((item) => item.domain);

    this.props.emailDomainsChanged(emailDomains);
  };

  render() {
    return (
      <div className="pageWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              marginRight: "5px",
            }}
          >
            <Button
              style={{
                fontSize: "1.4rem",
              }}
              variant="primary"
              onClick={this.addRow}
            >
              Add domain
            </Button>
          </div>
          <div>
            <Button
              style={{
                fontSize: "1.4rem",
              }}
              variant="primary"
              disabled={this.deleteDisabled}
              onClick={this.deleteRows}
            >
              Delete selected
            </Button>
          </div>
        </div>

        <div
          style={{
            height: "calc(100% - 100px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "70vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
              singleClickEdit={true}
              stopEditingWhenCellsLoseFocus={true}
              rowSelection="multiple"
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default EmailDomainsUI;
