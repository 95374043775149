const EXPIRATION_STATUS = [
    {
        title: 'Active',
        active: true
    },
    {
        title: 'Inactive',
        active: false
    },
    {
        title: 'All',
        active: null
    }
];

const CRUD = {
    c: 'Create',
    r: 'Read',
    u: 'Update',
    d: 'Delete'
}

module.exports = {
    EXPIRATION_STATUS,
    CRUD
};
