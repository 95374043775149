import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import "bootstrap/dist/css/bootstrap.min.css";

class RegistrationCodeUI extends Component {
  constructor(props) {
    super(props);

    const { data, partners } = props;

    let partnerValue = "";

    if (partners.length > 0) {
      if (data.partnerName) {
        partnerValue = data.partner.handle;
      } else {
        data.partner = partners[0];
        partnerValue = partners[0].handle;
      }
    }

    this.state = {
      data,
      partners,
      partnerValue,
    };
  }

  componentDidMount() {}

  updateRegistrationCode = async (e) => {
    const data = this.state.data;
    data.registrationCode = e.target.value;

    if (data.registrationCode.length === 0) {
      delete data.registrationCode;
    }

    this.setState({
      data,
    });

    this.props.dataChanged(data);
  };

  updateStartDate = async (e) => {
    const data = this.state.data;

    if (data.length === 0) {
      delete data.startDate;
    } else {
      data.startDate = e.target.value;
    }

    this.setState({
      data,
    });

    this.props.dataChanged(data);
  };

  updateEndDate = async (e) => {
    const data = this.state.data;

    if (data.length === 0) {
      delete data.endDate;
    } else {
      data.endDate = e.target.value;
    }

    this.setState({
      data,
    });

    this.props.dataChanged(data);
  };

  updatePartner = async (e) => {
    const value = e.target.value;
    const data = this.state.data;

    const partner = this.state.partners.find((item) => item.handle === value);

    data.partner = partner;

    this.setState({
      data,
      partnerValue: value,
    });

    this.props.dataChanged(data);
  };

  updateSpendLimit = async (e) => {
    const data = this.state.data;

    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value)) {
        data.spendLimit = parseInt(e.target.value);
      }

      this.setState({
        data,
      });

      this.props.dataChanged(data);
    } catch (err) {}
  };

  updateMaxRegistrationCount = async (e) => {
    const data = this.state.data;

    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value)) {
        data.maxRegistrationCount = parseInt(e.target.value);
      }

      this.setState({
        data,
      });

      this.props.dataChanged(data);
    } catch (err) {}
  };

  updateTags = async (e) => {
    const data = this.state.data;
    data.tags = e.target.value;

    if (data.tags.length === 0) {
      delete data.tags;
    }

    this.setState({
      data,
    });

    this.props.dataChanged(data);
  };

  render() {
    return (
      <Container className="p-2">
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Registration Code:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              maxLength="40"
              value={this.state.data.registrationCode}
              onChange={this.updateRegistrationCode}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Start Date:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="date"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.data.startDate ? this.state.data.startDate : ""}
              placeholder={this.state.data.startDate}
              onChange={this.updateStartDate}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">End Date:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="date"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.data.endDate ? this.state.data.endDate : ""}
              placeholder={this.state.data.endDate}
              onChange={this.updateEndDate}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Partners:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              as="select"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.partnerValue}
              onChange={this.updatePartner}
            >
              {this.state.partners &&
                this.state.partners.map((item, index) => (
                  <option key={index} value={item.handle}>
                    {item.name}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Spend Limit, $:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              style={{
                maxWidth: "100px",
                border: "1px solid black",
                fontSize: "14px",
              }}
              required
              type="number"
              maxLength="3"
              value={this.state.data.spendLimit}
              onChange={this.updateSpendLimit}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Max Count:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              style={{
                maxWidth: "100px",
                border: "1px solid black",
                fontSize: "14px",
              }}
              required
              type="number"
              maxLength="8"
              value={this.state.data.maxRegistrationCount}
              onChange={this.updateMaxRegistrationCount}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Tags:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              maxLength="8000"
              value={this.state.data.tags}
              onChange={this.updateTags}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RegistrationCodeUI;
