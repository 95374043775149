import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import RetireesInvite from "./components/retireeusers/retireeinvited";
import RetireesRegister from "./components/retireeusers/retireeregistered";
import store from "store-js";

import { useSettings } from "../AppContext";

export default function RetireeUsers() {
  const { shopName } = useSettings();
  const shop = ["sbd-employee-store-ca-dev.myshopify.com", "sbd-employee-store-ca-staging.myshopify.com", "sbd-employee-store-ca-prod.myshopify.com"];
  let mtdEnable = false;
  if (shop.includes(shopName)) {
     mtdEnable = true;
  }
  const currentTab = store.get("retireeTab") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("retireeTab", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Invited",
      title: "Invited",
      content: "Invited",
      page: <RetireesInvite mtdEnable={mtdEnable} />,
      panelID: "Invited",
    },
    {
      id: "Registered",
      title: "Registered",
      content: "Registered",
      page: <RetireesRegister  />,
      panelID: "Registered",
    },
  ];

  return (
    <Page
      breadcrumbs={[{ url: "/" }]}
      title={tabs[selected].id + " Retiree Users"}
      fullWidth
    >
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
