import React, { Component } from "react";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

class Registered extends Component {
  constructor(props) {
    super(props);

    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.state = {
      modules: AllCommunityModules,
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "Name",
          field: "displayName",
          sortable: true,
          width: 120,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "First Name",
          field: "firstName",
          width: 100,
          hide: true,
        },
        {
          headerName: "Last Name",
          field: "lastName",
          width: 100,
          hide: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 150,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "User Type",
          field: "userType",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Invited Date",
          field: "createdAt",
          width: 70,
          cellRenderer: (data) => {
            return moment(data.data.createdAt).format("MM/DD/YYYY");
          },
        },
      ],

      rowData: [],
      hasNextPage: false,
      hasPreviousPage: false,
      total: 0,
      count: 0,
      page: 1,
      pageSize: 10,
      loading: false,
      status: "",
      error: false,
      searchText: "",
    };
  }

  getList = (page) => {
    const headers = {
      "Content-Type": "application/json",
    };

    let pg = this.state.page;
    if (page !== undefined) {
      pg = page;
    }

    let url = `/api/invites/list?page=${pg}&pageSize=${this.state.pageSize}`;

    if (this.props.status && this.props.status.length > 0) {
      if (this.props.status === "registered") {
        url = `${url}&registered=1`;
      }
      if (this.props.status === "invited") {
        url = `${url}&registered=0`;
      }
    }

    if (this.state.searchText && this.state.searchText.length > 0) {
      url = `${url}&search=${encodeURIComponent(this.state.searchText)}`;
    }

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (success) => {
          const { status, msg, list, hasPrevious, hasNext, total } = success;
          this.updateStatus(!status, msg, true);

          if (!list || list.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }

          let count = this.state.page * this.state.pageSize;

          if (count > total) {
            count = total;
          }

          this.setState({
            rowData: list,
            hasNextPage: hasNext,
            hasPreviousPage: hasPrevious,
            total,
            count,
          });
        },

        (error) => {
          console.log(error);
          this.hideLoadingOverlay();
          this.updateStatus(true, "Error loading invite log", true);
        }
      );
  };

  componentDidMount() {
    this.getList();
  }

  onReady = (customer = null) => {
    this.closeCreateUserForm();
    this.showLoadingOverlay();
    this.getList();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
  }

  hideLoadingOverlay() {
    this.gridApi.hideOverlay();
  }

  reset = () => {
    this.setState({
      id: "",
      firstName: "",
      notValidFirstName: false,
      lastName: "",
      notValidLastName: false,
      email: "",
      notValidEmail: false,
      tags: "",
      valid: false,
      loading: false,
      disableSubmit: false,
      selectedRowData: [],
    });
  };

  updateStatus = (error, status, clear) => {
    this.setState({
      status,
      error,
    });

    const timeOut = error ? 10000 : 5000;

    if (clear) {
      setTimeout(() => {
        this.setState({ status: "" });
      }, timeOut);
    }
  };

  loadNextPage = () => {
    this.setState({ selectedRowData: [] });

    let page = this.state.page;

    if (this.state.hasNextPage) {
      page += 1;

      this.setState({ page: page });
    }

    this.getList(page);
  };

  loadPreviousPage = () => {
    this.setState({ selectedRowData: [] });

    let page = this.state.page;

    if (this.state.hasPreviousPage) {
      if (page !== 1) {
        page -= 1;
      }

      this.setState({ page: page });
    }

    this.getList(page);
  };

  searchValueChanged = (e) => {
    this.setState({ searchText: e.target.value });
  };

  search = () => {
    this.getList();
  };

  render() {
    const {
      userTypes,
      hasNextPage,
      hasPreviousPage,
      total,
      count,
      firstName,
      notValidFirstName,
      lastName,
      notValidLastName,
      email,
      notValidEmail,
      tags,
      valid,
      disableSubmit,
      status,
      error,
      selectedRowData,
    } = this.state;

    return (
      <div>
        <div class="ml-2 mt-3">
          <div style={{ display: "flex" }}>
            <input
              class="mr-1 searchtinput"
              maxlength="60"
              value={this.state.searchText}
              onKeyPress={(event) => event.key === "Enter" && this.search()}
              onChange={this.searchValueChanged}
            ></input>
            <button
              class="export btn btn-primary mr-2"
              style={{ width: "120px" }}
              onClick={this.search}
            >
              Search
            </button>

            {status && (
              <div
                class="ml-3 mt-2"
                style={{
                  color: error ? "red" : "green",
                }}
              >
                {status}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "10px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
            />
          </div>
        </div>
        <div className="headerbottom">
          {count > 0 && (
            <label className="mt-1 mr-2">
              {count} of {total}
            </label>
          )}
          <button
            class="previous"
            disabled={!hasPreviousPage}
            onClick={() => this.loadPreviousPage()}
          >
            Previous
          </button>
          <button
            class="next"
            disabled={!hasNextPage}
            onClick={() => this.loadNextPage()}
          >
            Next
          </button>
        </div>

        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default Registered;
