import { useState, useEffect } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  Card,
  DatePicker,
  Frame,
  Icon,
  Popover,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";

import * as moment from "moment";

import { useSettings } from "../../../AppContext";

import { BANNER_STATUS } from "./constants";

const FORM_TITLE = "Giveaway Report";
const DEFAULT_OPTIONS = "Select Giveaway";

function Report() {
  const { customerId } = useSettings();

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const [options, setOptions] = useState([]);
  const [option, setOption] = useState(DEFAULT_OPTIONS);
  const handleSelectChange = (value) => setOption(value);

  const getGiveaways = async () => {
    const url = `/api/giveaways/list`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            FORM_TITLE,
            msg ? msg : `Error loading giveaways`
          );
        }

        const list = data.list.map((item) => {
          return {
            label: item.name,
            value: `${item.id}`,
            exists: true,
          };
        });

        list.unshift({
          label: DEFAULT_OPTIONS,
          value: `0`,
        });

        setOptions(list);
      } else {
        setOptions([]);

        updateBanner(
          BANNER_STATUS.CRITICAL,
          FORM_TITLE,
          msg ? msg : `Error querying giveaways`
        );
      }
    } catch (err) {
      console.log(err);

      setOptions([]);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        FORM_TITLE,
        `Error getting giveaways`
      );
    }
  };

  useEffect(() => {
    getGiveaways();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createReport = async () => {
    const params = [];
    params.push(`admin=${customerId}`);

    const selectedOption = options.find((item) => item.value === option);

    if (selectedOption && selectedOption.exists) {
      params.push(`id=${selectedOption.value}`);
      params.push(`name=${selectedOption.label}`);
    }

    params.push(
      `startDate=${encodeURIComponent(
        moment(selectedStartDate).utc().format("MM/DD/YYYY")
      )}`
    );
    params.push(
      `endDate=${encodeURIComponent(
        moment(selectedEndDate).utc().format("MM/DD/YYYY")
      )}`
    );

    const url = `/api/giveaways/report?${params.join("&")}`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, {
        headers: headers,
      });

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            FORM_TITLE,
            msg ? msg : `Error creating report`
          );
        }
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.msg;
      const errMsg = err.response?.data?.errMsg;

      updateBanner(
        BANNER_STATUS.CRITICAL,
        FORM_TITLE,
        errMsg ? errMsg : msg ? msg : `Error creating report: ${err}`
      );
    }
  };

  const toggleToast = () => setShowToast((showToast) => !showToast);

  // Start date picker

  const [startDateVisible, setStartDateVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: selectedStartDate.getMonth(),
    startYear: selectedStartDate.getFullYear(),
  });

  const formattedValue = (selectedDate) =>
    //selectedDate.toISOString().slice(0, 10);
    selectedDate.getFullYear() +
    "-" +
    (selectedDate.getMonth() + 1).toString() +
    "-" +
    selectedDate.getDate().toString();

  function startDateOnClose({ relatedTarget }) {
    setStartDateVisible(false);
  }

  function startDateMonthChanged(month, year) {
    setStartDate({ startMonth: month, startYear: year });
  }

  function startDateChanged({ end: newSelectedDate }) {
    setSelectedStartDate(newSelectedDate);
    setStartDateVisible(false);
  }

  useEffect(() => {
    if (selectedStartDate) {
      setStartDate({
        startMonth: selectedStartDate.getMonth(),
        startYear: selectedStartDate.getFullYear(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate]);

  // End date picker

  const [endDateVisible, setEndDateVisible] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [{ endMonth, endYear }, setEndDate] = useState({
    endMonth: selectedEndDate.getMonth(),
    endYear: selectedEndDate.getFullYear(),
  });

  function endDateOnClose({ relatedTarget }) {
    setEndDateVisible(false);
  }

  function endDateMonthChanged(month, year) {
    setEndDate({ endMonth: month, endYear: year });
  }

  function endDateChanged({ end: newSelectedDate }) {
    setSelectedEndDate(newSelectedDate);
    setEndDateVisible(false);
  }

  useEffect(() => {
    if (selectedEndDate) {
      setEndDate({
        endMonth: selectedEndDate.getMonth(),
        endYear: selectedEndDate.getFullYear(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);

  useEffect(() => {
    const startDate = new Date();
    setSelectedStartDate(startDate);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 12);

    setSelectedEndDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          padding: "10px",
          borderBottom: "rgba(225, 227, 229, 1) solid",
          width: "100%",
        }}
      >
        <div
          style={{
            maxWidth: "400px",
          }}
        >
          <Card>
            <div
              style={{
                padding: "10px",
                borderBottom: "rgba(225, 227, 229, 1) solid",
              }}
            >
              <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
                <Select
                  label="Giveaway Name"
                  options={options}
                  onChange={handleSelectChange}
                  value={option}
                />
              </div>

              <div style={{ paddingBottom: "10px" }}>
                <div>
                  <div>
                    <Popover
                      active={startDateVisible}
                      autofocusTarget="none"
                      preferredAlignment="left"
                      fullWidth
                      preferInputActivator={false}
                      preferredPosition="below"
                      preventCloseOnChildOverlayClick
                      onClose={startDateOnClose}
                      activator={
                        <TextField
                          role="combobox"
                          label={"Start Date"}
                          prefix={<Icon source={CalendarMinor} />}
                          value={formattedValue(selectedStartDate)}
                          onFocus={() => setStartDateVisible(true)}
                          autoComplete="off"
                        />
                      }
                    >
                      <Card>
                        <DatePicker
                          month={startMonth}
                          year={startYear}
                          selected={selectedStartDate}
                          onMonthChange={startDateMonthChanged}
                          onChange={startDateChanged}
                        />
                      </Card>
                    </Popover>
                  </div>
                </div>
              </div>

              <div style={{ paddingBottom: "10px" }}>
                <div>
                  <div>
                    <Popover
                      active={endDateVisible}
                      autofocusTarget="none"
                      preferredAlignment="left"
                      fullWidth
                      preferInputActivator={false}
                      preferredPosition="below"
                      preventCloseOnChildOverlayClick
                      onClose={endDateOnClose}
                      activator={
                        <TextField
                          role="combobox"
                          label={"End Date"}
                          prefix={<Icon source={CalendarMinor} />}
                          value={formattedValue(selectedEndDate)}
                          onFocus={() => setEndDateVisible(true)}
                          autoComplete="off"
                        />
                      }
                    >
                      <Card>
                        <DatePicker
                          month={endMonth}
                          year={endYear}
                          selected={selectedEndDate}
                          onMonthChange={endDateMonthChanged}
                          onChange={endDateChanged}
                        />
                      </Card>
                    </Popover>
                  </div>
                </div>
              </div>

              <Button primary onClick={createReport}>
                Create Report
              </Button>
            </div>
          </Card>
        </div>
      </div>

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Report;
