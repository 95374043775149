import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import "bootstrap/dist/css/bootstrap.min.css";

import { ftpClients } from "./constants";

import { deepEqual } from "../../../utils";

class PriceSpiderConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {},
      originalSettings: {},
      statusError: false,
      statusText: "",
      refresh: false,
      loading: false,
    };
  }

  setDefaultSettings() {
    const settings = {
      wyomindProductFeedsUrl: "",
      priceSpiderHost: "",
      priceSpiderPort: "",
      priceSpiderUser: "",
      priceSpiderPassword: "",
      uploadDir: "",
      enable: false,
      ftpClient: "",
      downloadDir: "",
    };

    this.setState({
      settings,
    });
  }

  componentDidMount() {
    this.setDefaultSettings();
    this.getSettings();

    this.setState({
      statusError: false,
      statusText: "",
    });
  }

  getSettings = async () => {
    const url = `/api/priceSpider/settings`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, settings } = data;

        this.setState({
          settings,
          originalSettings: JSON.parse(JSON.stringify(settings)),
          statusError: !status,
          statusText: msg,
        });
      } else {
        this.setDefaultSettings();
        this.setState({
          statusError: false,
          statusText: `No settings found.`,
        });
      }
    } catch (err) {
      console.log(err);

      this.setDefaultSettings();
      this.setState({
        statusError: true,
        statusText: `Error getting settings: ${err}`,
      });
    }
  };

  updateWyomindProductFeedsUrl = async (e) => {
    const settings = this.state.settings;
    settings.wyomindProductFeedsUrl = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updatePriceSpiderHost = async (e) => {
    const settings = this.state.settings;
    settings.priceSpiderHost = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updatePriceSpiderPort = async (e) => {
    const settings = this.state.settings;
    settings.priceSpiderPort = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updatePriceSpiderUser = async (e) => {
    const settings = this.state.settings;
    settings.priceSpiderUser = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updatePriceSpiderPassword = async (e) => {
    const settings = this.state.settings;
    settings.priceSpiderPassword = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updateUploadDir = async (e) => {
    const settings = this.state.settings;
    settings.uploadDir = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updateEnable = (e) => {
    const settings = this.state.settings;
    settings.enable = e.target.checked;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updateFtpClient = async (e) => {
    const settings = this.state.settings;
    settings.ftpClient = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updateDownloadDir = async (e) => {
    const settings = this.state.settings;
    settings.downloadDir = e.target.value;

    this.setState({
      settings: settings,
      statusText: "",
    });
  };

  updateSettings = async () => {
    this.setState({
      loading: true,
    });

    const url = `/api/priceSpider/settings`;

    const updates = {};

    const settings = this.state.settings;
    const keys = Object.keys(settings);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = settings[key];

      if (this.state.originalSettings[key] !== value) {
        updates[key] = value;
      }
    }
    // RM stringify
    const data = updates;

    try {
      const response = await axios.post(url, data);

      let statusError = false;
      let statusText = "";

      if (response.status === 200 && response.data) {
        const { failed, updated } = response.data;

        if (failed && failed.length > 0) {
          statusError = true;
          statusText = `Not updated: ${failed.join(",")}`;
        } else {
          statusText = "Settings updated";

          const original = this.state.originalSettings;

          for (let i = 0; i < updated.length; i++) {
            const item = updated[i];
            original[item.setting] = item.value;
          }

          this.setState({
            originalSettings: original,
          });
        }
      } else {
        statusError = true;
        statusText = "Error updating settings";

        const original = this.state.originalSettings;

        this.setState({
          settings: JSON.parse(JSON.stringify(original)),
        });
      }

      this.setState({
        statusError,
        statusText,
      });
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating settings: ${err}`,
      });
    }

    this.setState({
      loading: false,
    });
  };

  updatePriceSpider = async () => {
    this.setState({
      loading: true,
    });

    const url = `/api/priceSpider/transfer`;

    try {
      const response = await axios.get(url);

      let statusError = false;
      let statusText = "";

      if (response.status === 200 && response.data) {
        const { status, msg } = response.data;

        if (!status) {
          statusError = true;
          statusText = msg;
        } else {
          statusText = "PriceSpider updated";
        }
      } else {
        statusError = true;
        statusText = "Error updating PriceSpider";
      }

      this.setState({
        statusError,
        statusText,
      });
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating PriceSpider: ${err}`,
      });
    }

    this.setState({
      loading: false,
    });
  };

  render() {
    return (
      <Container className="p-2">
        <Row className="p-2">
          <Col xs={12}>
            <Form.Label className="mt-2 font-weight-bold">
              Wyomind Product Feeds:
            </Form.Label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Url:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.wyomindProductFeedsUrl}
              onChange={this.updateWyomindProductFeedsUrl}
            />
          </Col>
        </Row>
        <Row className="p-2 pt-4">
          <Col xs={12}>
            <Form.Label className="mt-2 font-weight-bold">
              PriceSpider:
            </Form.Label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Host:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.priceSpiderHost}
              onChange={this.updatePriceSpiderHost}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Port:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.priceSpiderPort}
              onChange={this.updatePriceSpiderPort}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">User:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.priceSpiderUser}
              onChange={this.updatePriceSpiderUser}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Password:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.priceSpiderPassword}
              onChange={this.updatePriceSpiderPassword}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Upload Directory:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.uploadDir}
              onChange={this.updateUploadDir}
            />
          </Col>
        </Row>
        <Row className="p-2 pt-4">
          <Col xs={12}>
            <Form.Label className="mt-2 font-weight-bold">
              Custom App:
            </Form.Label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                checked={this.state.settings.enable}
                onChange={this.updateEnable}
              />
              <span className="pl-2">Enable</span>
            </label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">FTP Client:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              as="select"
              style={{
                maxWidth: "200px",
                fontSize: "14px",
              }}
              value={this.state.settings.ftpClient}
              onChange={this.updateFtpClient}
            >
              {ftpClients &&
                ftpClients.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Download Dir:</Form.Label>
          </Col>
          <Col xs={9}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.settings.downloadDir}
              onChange={this.updateDownloadDir}
              disabled
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <button
              onClick={this.updateSettings}
              className="export btn btn-primary mr-5"
              disabled={
                deepEqual(this.state.settings, this.state.originalSettings) ||
                this.state.loading
              }
              style={{ fontSize: "14px", width: "150px" }}
            >
              {this.state.loading && (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ padding: "10px", fontSize: "14px" }}>
                    Updating...
                  </span>
                </div>
              )}
              {!this.state.loading && <span>Update Settings</span>}
            </button>

            <button
              onClick={this.updatePriceSpider}
              className="export btn btn-primary mr-5"
              disabled={
                this.state.loading || !this.state.originalSettings.enable
              }
              style={{ fontSize: "14px", width: "150px" }}
            >
              {this.state.loading && (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ padding: "10px", fontSize: "14px" }}>
                    Updating...
                  </span>
                </div>
              )}
              {!this.state.loading && <span>Update PriceSpider</span>}
            </button>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <span
              style={{
                color: this.state.statusError ? "red" : "green",
                fontSize: "15px",
              }}
            >
              {this.state.statusText}
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PriceSpiderConfig;
