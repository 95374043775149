import React from "react";
import { Page, Card } from "@shopify/polaris";

import National from "./components/national/National";

export default function NationalPartners() {
  return (
    <Page breadcrumbs={[{ url: "/" }]} title="National" fullWidth>
      <Card>
        <National />
      </Card>
    </Page>
  );
}
