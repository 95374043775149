import { useState, useEffect, useRef } from "react";

import moment from "moment";
import axios from "../../../axiosInstance";
import {
  Banner,
  Frame,
  Icon,
  Pagination,
  Select,
  TextField,
  Toast,
  DataTable,
  Link,
  Spinner,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

import { Modal, Button as RButton } from "react-bootstrap";
import ImageZoomInOut from "./ImageZoomInOut";
import PDFViewer from "./PDFViewer";
import { useSettings } from "../../../AppContext";

import {
  CSR_APPROVAL_SORT_FIELDS,
  PAGE_SIZE,
  BANNER_STATUS,
  FILE_TYPE,
} from "./constants";

function CsrApprovals() {
  const { customerId } = useSettings();
  const [modalOpen, setModalOpen] = useState(false);

  const [showSpinner, setShowSpinner] = useState(true);
  const [showModalSpinner, setShowModalSpinner] = useState(false);

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState();
  const [submission, setSubmission] = useState({});
  const [pendingSubmission, setPendingSubmission] = useState([]);
  const [denyList, setDenyList] = useState([]);
  const [denyReason, setDenyReason] = useState("");
  const [holdList, setHoldList] = useState([]);
  const [holdReason, setHoldReason] = useState("");
  const [approveDisabled, setApproveDisabled] = useState(false);

  const getSubmissionData = async (id) => {
    const bannerTitle = "Submissions List";

    const url = `/api/promo/submission/${id}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, submission } = data;
        if (!status) {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error getting in submission details`
          );
        }
        return submission;
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error getting in submission details`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting submission details`
      );
    }
  };

  const getDenyList = async () => {
    const bannerTitle = "Deny Reason List";

    const url = `/api/promo/deny/list`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, list } = data;
        if (!status) {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error getting Deny list`
          );
        }
        let denyListOptions = [
          {
            label: "Select Deny Reason",
            value: "",
          },
        ];

        list.forEach((ele) => {
          denyListOptions.push({
            label: ele.reason,
            value: ele["id"].toString(),
          });
        });
        setDenyList(denyListOptions);
        return list;
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error getting Deny List`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting Deny list`
      );
    }
  };

  const getHoldList = async () => {
    const bannerTitle = "Hold Reason List";

    const url = `/api/promo/hold/list`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, list } = data;
        if (!status) {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error getting Hold list`
          );
        }
        let holdListOptions = [
          {
            label: "Select Hold Reason",
            value: "",
          },
        ];

        list.forEach((ele) => {
          holdListOptions.push({
            label: ele.reason,
            value: ele["id"].toString(),
          });
        });
        setHoldList(holdListOptions);
        return list;
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error getting Hold List`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting Hold list`
      );
    }
  };

  const handleModelOpen = (event, id, status) => {
    event.preventDefault();

    setModalOpen(true);
    setSubmission({});
    setShowModalSpinner(true);

    getSubmissionData(id).then(async (res) => {
      setSubmission(res);
      setShowModalSpinner(false);

      if (status == "Assigned") {
        await updateSubmissionStatus(id);
      }
    });
  };

  const updateSubmissionStatus = async (id) => {
    const bannerTitle = "Submission Status";

    try {
      const url = `/api/promo/submission/inReview/${id}`;
      await axios.post(url, {});
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error updating submission`
      );
    }
  };

  const pendingSubmissionMapping = (list) => {
    return list.map((ele) => {
      const link = (
        <Link
          removeUnderline
          onClick={(e) => {
            handleModelOpen(e, ele.id, ele.status);
          }}
        >
          {ele.email}
        </Link>
      );

      return [
        link,
        moment(ele.createdDate).utc().format("MM/DD h:mm a"),
        ele.ageOfSubmission,
        ele.status,
        moment(ele.assignedDate).utc().format("MM/DD h:mm a"),
        ele.shippingAddress1,
        ele.shippingAddress2,
        ele.addressUsed,
        ele.receiptIdUsed,
      ];
    });
  };

  const debounceTimeout = 1000;
  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const [query, setQuery] = useState({
    search: "",
    sortField: "Created Date, Old to New",
    sortOrder: "asc",
    page: 1,
    pageSize: 20,
    reviewer: customerId,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      params.push(`${key}=${encodeURIComponent(query[key])}`);
    }

    return params;
  };

  const getCsrApprovalSubmissions = async () => {
    const bannerTitle = "Submissions List";

    const params = queryParams();
    const url = `/api/promo/submissions/csrApproval?${params.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        setShowSpinner(false);
        const data = response.data;
        const { status, msg, hasPrevious, hasNext, total } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          setShowSpinner(false);
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading pending submission`
          );
        }

        setHasPrevious(hasPrevious);
        setHasNext(hasNext);

        const totalPages = Math.ceil(total / query.pageSize);
        setTotal(totalPages);

        let list = data.list;

        if (!list || list.length === 0) {
          setPendingSubmission([]);
          return;
        }

        const submissionList = pendingSubmissionMapping(list);
        setPendingSubmission(submissionList);
      } else {
        setShowSpinner(false);
        setPendingSubmission([]);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error querying pending submission`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting submissions list`
      );
    }
  };

  const exportCsrApprovals = async () => {
    const bannerTitle = "Submissions Export";

    const url = `/api/promo/submissions/export/csrApproval?reviewer=${customerId}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error in exporting pending submission`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error in exporting pending submission`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error exporting submissions`
      );
    }
  };

  const approveSubmission = async (id) => {
    const bannerTitle = "Submissions Approval";

    setApproveDisabled(true);

    const url = `/api/promo/submissions/approve`;

    try {
      const data = {
        list: [id],
      };
      const response = await axios.post(url, data);

      if (response && response.status === 200) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          updateBanner(
            BANNER_STATUS.SUCCESS,
            bannerTitle,
            msg ? msg : `Submission Approved Successfully`
          );
          setModalOpen(false);
          getCsrApprovalSubmissions();
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error in submission Approval`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error in submission Approval`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error approving submission`
      );
    }

    setApproveDisabled(false);
  };

  const denySubmission = async (id) => {
    const bannerTitle = "Deny Submission";

    try {
      const url = `/api/promo/submissions/deny`;

      const data = {
        list: [id],
        reason: denyReason,
      };
      const response = await axios.post(url, data);

      if (response && response.status === 200) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setModalOpen(false);

          updateBanner(
            BANNER_STATUS.INFO,
            bannerTitle,
            msg ? msg : `Submission Denied successfully`
          );

          setShowToast(true);
          getCsrApprovalSubmissions();
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error in denying submission`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error in denying submission`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error denying submission`
      );
    }
  };

  const holdSubmission = async (id) => {
    const bannerTitle = "Hold Submission";

    try {
      const url = `/api/promo/submissions/hold`;

      const data = {
        list: [id],
        reason: holdReason,
      };
      const response = await axios.post(url, data);

      if (response && response.status === 200) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setModalOpen(false);

          updateBanner(
            BANNER_STATUS.INFO,
            bannerTitle,
            msg ? msg : `Submission Hold successfully`
          );

          setShowToast(true);
          getCsrApprovalSubmissions();
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error putting submission on hold`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error putting submission on hold`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error putting submission on hold`
      );
    }
  };

  const searchChanged = (value) => {
    setQuery({
      ...query,
      search: value,
    });
  };

  const columnContentTypes = [
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
    "text",
  ];

  const headings = [
    "Email",
    "Submission Date",
    "Submission Age",
    "Status",
    "Assigned Date",
    "Address",
    " ",
    "Address Used",
    "ReceiptId Used",
  ];

  useEffect(() => {
    debounce(getCsrApprovalSubmissions, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    getDenyList();
    getHoldList();

    setApproveDisabled(false);
  }, []);

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const search = () => getCsrApprovalSubmissions();

  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState("5");

  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [selectedPageSize, setPageSize] = useState("2");

  useEffect(() => {
    const list = PAGE_SIZE.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setPageSizeOptions(list);
  }, []);

  const pageSizeChanged = (option) => {
    setPageSize(option);

    const found = PAGE_SIZE.find((item) => item.id === parseInt(option));

    setQuery({
      ...query,
      pageSize: found.size,
    });
  };

  useEffect(() => {
    const list = CSR_APPROVAL_SORT_FIELDS.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setSortOptions(list);

    const found = CSR_APPROVAL_SORT_FIELDS.find(
      (item) => item.field === query.sortField && item.order === query.sortOrder
    );

    if (found) {
      setSelectedSort(found.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortChanged = (option) => {
    setSelectedSort(option);

    const found = CSR_APPROVAL_SORT_FIELDS.find(
      (item) => item.id === parseInt(option)
    );

    setQuery({
      ...query,
      sortField: found.field,
      sortOrder: found.order,
    });
  };

  const getError = (error) => {
    console.log(error);

    let err = null;

    if (error?.response) {
      const { status, msg } = error.response.data;
      err = msg;
    } else if (error?.request) {
      err = "Request error";
    } else {
      err = error?.message || "";
    }

    return err;
  };

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <div style={{ minWidth: "330px" }}>
          <TextField
            placeholder="Search Submissions"
            value={query.search}
            onChange={searchChanged}
            autoComplete="off"
            prefix={<Icon source={SearchMinor} color="base" />}
            alignItems="end"
          />
        </div>

        {/*<div style={{ marginLeft: "5px", minWidth: "130px" }}>
          <Button primary fullWidth onClick={exportCsrApprovals}>
            {" "}
            Export
          </Button>
        </div>*/}

        <div style={{ width: "100%" }}></div>

        <div className="headerDropdown">
          <div className="dropdownTitle">
            <label>Page Size:</label>
          </div>
          <Select
            label=""
            placeholder="Page Size"
            options={pageSizeOptions}
            onChange={pageSizeChanged}
            value={selectedPageSize}
          />
        </div>

        <div className="headerDropdown">
          <div className="dropdownTitle">
            <label>Sort By:</label>
          </div>
          <Select
            label=""
            placeholder="Sort By"
            options={sortOptions}
            onChange={sortChanged}
            value={selectedSort}
          />
        </div>
        {total > 0 && (
          <label
            style={{
              minWidth: "90px",
              textAlign: "right",
              paddingLeft: "10px",
              paddingTop: "8px",
            }}
          >
            {query.page} of {total}
          </label>
        )}
        <div style={{ marginLeft: 10 }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQuery({
                ...query,
                page: query.page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQuery({
                ...query,
                page: query.page + 1,
              });
            }}
          />
        </div>
      </div>

      {showSpinner && (
        <div className="loader">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      )}
      {pendingSubmission && (
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={pendingSubmission}
        />
      )}
      {modalOpen && (
        <ModalWindow
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          submission={submission}
          denyList={denyList}
          approveSubmission={approveSubmission}
          denyReason={denyReason}
          setDenyReason={setDenyReason}
          holdList={holdList}
          holdReason={holdReason}
          approveDisabled={approveDisabled}
          setHoldReason={setHoldReason}
          denySubmission={denySubmission}
          holdSubmission={holdSubmission}
          getCsrApprovalSubmissions={getCsrApprovalSubmissions}
          showModalSpinner={showModalSpinner}
          setShowModalSpinner={setShowModalSpinner}
        />
      )}
      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

function getFileType(base64String) {
  switch (base64String.charAt(0)) {
    case "/":
      return FILE_TYPE.JPG;
    case "i":
      return FILE_TYPE.PNG;
    case "J":
      return FILE_TYPE.PDF;
    default:
      return FILE_TYPE.UNKNOWN;
  }
}

function ModalWindow({
  modalOpen,
  setModalOpen,
  submission,
  denyList,
  holdList,
  approveSubmission,
  denyReason,
  setDenyReason,
  holdReason,
  approveDisabled,
  setHoldReason,
  denySubmission,
  holdSubmission,
  getCsrApprovalSubmissions,
  showModalSpinner,
  setShowModalSpinner,
}) {
  let isPDFBase64, base64;

  if (!showModalSpinner && submission) {
    base64 = submission.receiptBase64;

    const fileType = getFileType(base64);

    base64 = `data:image/${fileType};base64,${base64}`;

    if (fileType == "pdf") {
      isPDFBase64 = true;
    } else if (fileType == "unknown") {
      base64 = `data:image/png;base64,${base64}`;
    }
  }

  return (
    <Modal
      id="submission-modal"
      show={modalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={(e) => {
        setModalOpen(false);
        getCsrApprovalSubmissions();
        setShowModalSpinner(false);
      }}
    >
      {showModalSpinner ? (
        <div style={{ height: "750px", width: "750px" }}>
          <Modal.Body className="modalPopupWithoutData">
            <div>
              <Spinner accessibilityLabel="Spinner example" size="large" />
            </div>
          </Modal.Body>
        </div>
      ) : (
        <div>
          <Modal.Header className="modalHeaderColor" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {submission.id} - {submission.displayName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalContent moalBodyColor">
            <div className="detail-view-modal">
              <div className="detail-section">
                <div className="detail-section--box">
                  <h3
                    className="Polaris-Text--root Polaris-Text--headingMd"
                    style={{ fontWeight: 600 }}
                  >
                    Customer
                  </h3>
                  <div className="detail-section--field">
                    <div class="dataValue">{submission.displayName}</div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">{submission.email}</div>
                  </div>
                </div>

                <div className="detail-section--box">
                  <h3
                    className="Polaris-Text--root Polaris-Text--headingMd"
                    style={{ fontWeight: 600 }}
                  >
                    Purchase
                  </h3>

                  <div className="detail-section--field">
                    <div className="dataLabel">Method</div>
                    <div class="dataValue">{submission.purchasingMethod}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">Purchase Date</div>
                    <div class="dataValue">
                      {moment(submission.purchaseDate)
                        .utc()
                        .format("MM-DD-YYYY")}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">ReceiptId</div>
                    <div class="dataValue">{submission.receiptId}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">Model Number</div>
                    <div class="dataValue">{submission.modelNumber}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">Retailer</div>
                    <div class="dataValue">{submission.retailer}</div>
                  </div>

                  <div className="detail-section--field">
                    <div className="dataLabel">City, State of Purchase</div>
                    <div class="dataValue">
                      {submission.purchaseCity}, {submission.purchaseState}
                    </div>
                  </div>
                </div>

                <div className="detail-section--box">
                  <h3
                    className="Polaris-Text--root Polaris-Text--headingMd"
                    style={{ fontWeight: 600 }}
                  >
                    Shipping Address
                  </h3>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      {submission.shippingFirstName}{" "}
                      {submission.shippingLastName}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      {submission.shippingAddress1},{" "}
                      {submission.shippingAddress2}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      {submission.shippingCity} {submission.shippingState}{" "}
                      {submission.shippingPostalCode}
                    </div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">{submission.shippingCountry}</div>
                  </div>

                  <div className="detail-section--field">
                    <div class="dataValue">
                      <a>
                        +1{" "}
                        {submission.shippingPhone.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "$1-$2-$3"
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="image-section">
                {isPDFBase64 ? (
                  <PDFViewer name={submission.email} PDFBase64={base64} />
                ) : (
                  <ImageZoomInOut
                    imageBase64={base64}
                    name={submission.email}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modalFooter modalFooterColor">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Select
                  label=""
                  options={denyList}
                  onChange={(value) => setDenyReason(value)}
                  value={denyReason}
                />
              </div>
              <div>
                <RButton
                  className="modalButton deny"
                  disabled={!denyReason}
                  onClick={() => {
                    denySubmission(submission.id);
                  }}
                >
                  Deny
                </RButton>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Select
                  label=""
                  options={holdList}
                  onChange={(value) => setHoldReason(value)}
                  value={holdReason}
                />
              </div>
              <div>
                <RButton
                  className="modalButton deny"
                  disabled={!holdReason}
                  onClick={() => {
                    holdSubmission(submission.id);
                  }}
                >
                  Hold
                </RButton>
              </div>
            </div>

            <div>
              <RButton
                className="modalButton approve"
                disabled={approveDisabled}
                onClick={() => {
                  approveSubmission(submission.id);
                }}
              >
                Approve
              </RButton>
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
}

export default CsrApprovals;
