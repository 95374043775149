import React from 'react';

export default function unauthorized() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      You are unauthorized.
    </div>
  );
}
