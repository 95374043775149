const GIVEAWAYS_SORT_FIELDS = [
    {
        id: 1,
        title: 'Name, A-Z',
        field: 'name',
        order: 'asc'
    },
    {
        id: 2,
        title: 'Name, Z-A',
        field: 'name',
        order: 'desc'
    },
    {
        id: 3,
        title: 'Start Date, Old to New',
        field: 'startDate',
        order: 'asc'
    },
    {
        id: 4,
        title: 'Start Date, New to Old',
        field: 'startDate',
        order: 'desc'
    },
    {
        id: 5,
        title: 'End Date, Old to New',
        field: 'endDate',
        order: 'asc'
    },
    {
        id: 6,
        title: 'End Date, New to Old',
        field: 'endDate',
        order: 'desc'
    },
];

const TRANSACTIONS_SORT_FIELDS = [
    {
        id: 1,
        title: 'Date, Old to New',
        field: 'createdDate',
        order: 'asc'
    },
    {
        id: 2,
        title: 'Date, New to Old',
        field: 'createdDate',
        order: 'desc'
    },
    {
        id: 3,
        title: 'Customer Name, A-Z',
        field: 'customerName',
        order: 'asc'
    },
    {
        id: 4,
        title: 'Customer Name, Z-A',
        field: 'customerName',
        order: 'desc'
    },
    {
        id: 5,
        title: 'Email, A-Z',
        field: 'customerEmail',
        order: 'asc'
    },
    {
        id: 6,
        title: 'Email, Z-A',
        field: 'customerEmail',
        order: 'desc'
    },
    {
        id: 7,
        title: 'Amount, Low-High',
        field: 'amount',
        order: 'asc'
    },
    {
        id: 8,
        title: 'Amount, High-Low',
        field: 'amount',
        order: 'desc'
    },
    {
        id: 9,
        title: 'Reference, A-Z',
        field: 'reference',
        order: 'asc'
    },
    {
        id: 10,
        title: 'Reference, Z-A',
        field: 'reference',
        order: 'desc'
    },
    {
        id: 11,
        title: 'Created By Name, A-Z',
        field: 'adminName',
        order: 'asc'
    },
    {
        id: 12,
        title: 'Created By Name, Z-A',
        field: 'adminName',
        order: 'desc'
    },
];

const BANNER_STATUS = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
};

module.exports = {
    GIVEAWAYS_SORT_FIELDS,
    TRANSACTIONS_SORT_FIELDS,
    BANNER_STATUS
};
