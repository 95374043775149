import React from "react";
import { useState, useEffect } from "react";
import axios from "../../../axiosInstance";
import { io } from "socket.io-client";
import moment from "moment";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Banner,
  Button,
  Card,
  DataTable,
  Frame,
  Link,
  ProgressBar,
  Scrollable,
  Toast,
} from "@shopify/polaris";

import { BANNER_STATUS } from "./constants";

const MODULE = "productMedia";
const SECTION = "productMedia";

function Sync() {
  const [socket, setSocket] = useState(null);

  const [logs, setLogs] = useState([]);
  const [log, setLog] = useState([]);

  const [notify, setNotify] = useState("");
  const [logEntries, setLogEntries] = useState([]);
  const [progress, setProgress] = useState("");
  const [progressValue, setProgressValue] = useState();
  const [processing, setProcessing] = useState(false);

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const getList = async () => {
    const url = `/api/productMedia/logs/list?section=${SECTION}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, list } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Logs",
            msg ? msg : `Error loading logs`
          );
        }

        if (!list || list.length === 0) {
          setLogs([]);
          return;
        }

        const lst = formatLogs(list);
        setLogs(lst);
      } else {
        setLogs([]);

        updateBanner(
          BANNER_STATUS.CRITICAL,
          "Logs",
          msg ? msg : `Error querying logs`
        );
      }
    } catch (err) {
      console.log(err);

      setLogs([]);

      updateBanner(BANNER_STATUS.CRITICAL, "Logs", `Error getting logs`);
    }
  };

  const formatLogs = (list) => {
    const lst = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      lst.push([
        item.status,
        <Link
          removeUnderline
          key={item.displayName}
          onClick={() => openLog(item.fileName)}
        >
          {item.displayName}
        </Link>,
        item.duration,
      ]);
    }

    return lst;
  };

  const reset = () => {
    setProcessing(false);
    setLogEntries([]);
    setProgressValue(null);
    getList();
  };

  useEffect(() => {
    const socket = io();
    setSocket(socket);

    socket.on("connect", () => {
      const data = {
        module: MODULE,
        msg: `Connected to server. Session Id: ${socket.id}`,
        date: moment(new Date()).format("hh:mm:ss"),
        system: true,
      };

      setNotify(JSON.stringify(data));
    });

    socket.on("notify", (data) => {
      if (data && data.length > 0) {
        setNotify(data);
      }
    });

    socket.on("progress", (data) => {
      if (data && data.length > 0) {
        setProgress(data);
      }
    });

    socket.on("disconnect", () => {
      const data = {
        module: MODULE,
        msg: `Disconnected from server.`,
        date: moment(new Date()).format("hh:mm:ss"),
        system: true,
      };

      setNotify(JSON.stringify(data));
    });

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!notify || notify === undefined || notify.length === 0) {
      return;
    }

    try {
      const { module, msg, date, system } = JSON.parse(notify);
      const message = `${date} - ${msg}`;

      if (module && module === MODULE && message) {
        const entries = [...logEntries];
        entries.unshift(message);
        setLogEntries(entries);

        if (!system) {
          setProcessing(true);
        } else {
          setProcessing(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify]);

  useEffect(() => {
    if (!progress || progress === undefined || progress === 0) {
      return;
    }

    try {
      const { module, progress: value } = JSON.parse(progress);

      if (module && module === MODULE) {
        setProgressValue(value);

        if (value === 100) {
          reset();
        }
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const openLog = (fileName) => {
    setLog([]);
    getLog(fileName);
  };

  const getLog = async (fileName) => {
    const url = `/api/productMedia/log?f=${fileName}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, json } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Logs",
            msg ? msg : `Error loading log file`
          );
        }

        const lst = formatLog(json);
        setLog(lst);
      } else {
        setLog([]);

        updateBanner(
          BANNER_STATUS.CRITICAL,
          "Log file",
          msg ? msg : `Error querying log file`
        );
      }
    } catch (err) {
      console.log(err);

      setLog([]);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Log file",
        `Error getting log file`
      );
    }
  };

  const formatLog = (list) => {
    const lst = [];

    for (let i = 0; i < list.length; i++) {
      const { functionName, logLevel, msg, date } = list[i];

      lst.push([`${functionName}`, `${logLevel}`, `${msg}`, `${date}`]);
    }

    return lst;
  };

  const update = async () => {
    setProcessing(true);

    const url = `/api/productMedia/sync`;

    try {
      const response = await axios.post(url);

      if (response && response.status === 200 && response.data) {
        const { status, msg } = response.data;

        setMsg(msg);
        setShowToast(true);
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          "Sync",
          msg ? msg : `Error starting Sync`
        );
      }
    } catch (err) {
      console.log(err);

      updateBanner(BANNER_STATUS.CRITICAL, "Sync", `Error starting Sync`);
    }
  };

  const sync = () => update();

  const refresh = () => {
    setLogs([]);
    setLog([]);

    getList();
  };

  const toggleToast = () => setShowToast((showToast) => !showToast);

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <Container className="p-2 m-0" style={{ maxWidth: "100%" }}>
        <Row className="mb-2">
          <Col>
            <div
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <div>
                <Button primary onClick={sync} disabled={processing}>
                  Sync
                </Button>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <Button primary onClick={refresh}>
                  Refresh
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {progressValue && progressValue > 0 && (
          <Row style={{ marginBottom: "10px" }}>
            <Col className="ml-3" style={{ maxWidth: "600px" }}>
              <ProgressBar progress={progressValue} />
            </Col>
          </Row>
        )}
        {logEntries && logEntries.length > 0 && (
          <Row>
            <Col className="ml-2 mr-2">
              <Card>
                <Scrollable shadow style={{ height: "400px", padding: "10px" }}>
                  {logEntries.map((item, index) => (
                    <p>{item}</p>
                  ))}
                </Scrollable>
              </Card>
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col>
            <DataTable
              columnContentTypes={["text", "text"]}
              headings={["Status", "Logs", "Run time"]}
              rows={logs}
            />
          </Col>
          <Col>
            <DataTable
              columnContentTypes={["text", "text", "text", "text"]}
              headings={["Function", "Log", "Event", "Date"]}
              rows={log}
            />
          </Col>
        </Row>
      </Container>

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Sync;
