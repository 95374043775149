import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import RegistrationCodesConfig from "./components/RegistrationCodes/RegistrationCodesConfig";
import RegistrationCodesHistory from "./components/RegistrationCodes/RegistrationCodesHistory";
import store from "store-js";

function RegistrationCodes() {

  const currentTab = store.get("registrationCodesTab") || 0;
  const [selected, setSelected] = useState(currentTab);

  store.set("registrationCodesTab", selected);

  const handleTabChange = useCallback((index) => setSelected(index), []);

  const tabs = [
    {
      id: "registrationCodesConfig",
      title: "Registration Codes",
      content: "List",
      page: <RegistrationCodesConfig />,
      panelID: "registrationCodesConfigPanel",
    },
    {
      id: "registrationCodesHistory",
      title: "Registration Codes",
      content: "History",
      page: <RegistrationCodesHistory />,
      panelID: "registrationCodesHistoryPanel",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default RegistrationCodes;
