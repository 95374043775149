import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import Customers from "./components/Credits/Customers";
import Transactions from "./components/Credits/Transactions";

function Credits() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((index) => setSelected(index), []);

  const tabs = [
    {
      id: "customers",
      title: "Credits",
      content: "Manage Credits",
      page: <Customers />,
      panelID: "customersConfigPanel",
    },
    {
      id: "transactions",
      title: "Credits",
      content: "Transactions",
      page: <Transactions />,
      panelID: "transactionsConfigPanel",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default Credits;
