import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import "bootstrap/dist/css/bootstrap.min.css";

import * as moment from "moment";

import { CRUD } from "./constants";

class RegistrationCodesHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "Action",
          field: "action",
          width: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            const data = params.data;
            return CRUD[data.action];
          },
        },
        {
          headerName: "Changes",
          field: "changes",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Date",
          field: "createdDate",
          width: 120,
          sortable: true,
          cellRenderer: (params) => {
            const data = params.data;
            return moment(data.createdDate).format("MM/DD/YYYY");
          },
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      rowData: [],
      loading: false,
      statusError: false,
      statusText: "",
    };
  }

  componentDidMount() {
    this.getRegistrationCodesHistory();
  }

  getRegistrationCodesHistory = async () => {
    let url = `/api/registrationCodes/history`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg } = data;
        let list = data.list;

        if (!list || list.length === 0) {
          this.gridApi.setRowData([]);

          this.setState({
            rowData: [],
            statusError: !status,
            statusText: msg,
          });

          return;
        }

        this.gridApi.setRowData(list);

        this.setState({
          rowData: list,
          statusError: false,
          statusText: msg,
        });
      } else {
        this.gridApi.showNoRowsOverlay();
        this.gridApi.setRowData([]);

        this.setState({
          rowData: [],
          statusError: false,
          statusText: `No registration codes found.`,
        });
      }
    } catch (err) {
      console.log(err);

      this.gridApi.showNoRowsOverlay();
      this.gridApi.setRowData([]);

      this.setState({
        rowData: [],
        statusError: true,
        statusText: `Error getting registration codes: ${err}`,
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  };

  render() {
    return (
      <div className="pageWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div
            style={{
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            Status:
          </div>
          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "75vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default RegistrationCodesHistory;
