import React from 'react';
import ReactDOM from 'react-dom';
import cookie from 'cookie';
import './index.css';
import App from './App';

import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import "@shopify/polaris/dist/styles.css";
import { Provider } from '@shopify/app-bridge-react';

const { apiKey, shopName, accessToken } = cookie.parse(document.cookie);
const hasCookie = apiKey && shopName && accessToken;

const config = {
  apiKey: apiKey || '',
  shopOrigin: shopName || '',
  forceRedirect: true
};

ReactDOM.render(
  <React.StrictMode>
    <Provider config={config}>
      <AppProvider i18n={enTranslations}>
        <App hasCookie={hasCookie} />
      </AppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);