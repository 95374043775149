import React, { useContext } from 'react';
import cookie from 'cookie';

const AppContext = React.createContext();

export function useSettings() {
    return useContext(AppContext);
}

export function SettingsProvider({ children }) {
    const { shopName, _token: token, _id: customerId, _c_id: customerToken, _customApp, _resources } = cookie.parse(document.cookie);

    let customApp = [];

    if (_customApp) {
        customApp = _customApp.split(',');
    }
	
	let resources = [];
	
    if (_resources) {
        resources = JSON.parse(_resources);
    }
	
    const settings = { token, customerId, customerToken, customApp, resources, shopName };

    return (
        <AppContext.Provider value={settings}>
            {children}
        </AppContext.Provider>
    )
}