import React, { Component } from "react";

class CheckboxRenderer extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }

  checkedHandler(event) {
    const checked = event.target.checked;
    const colId = this.props.column.colId;

    this.props.node.setDataValue(colId, checked);
  }

  render() {
    return (
      <input
        type="checkbox"
        style={{ marginTop: '5px' }}
        onClick={ this.checkedHandler }
        checked={ this.props.value }
      />
    );
  }
}

export default CheckboxRenderer;