import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import PriceSpiderConfig from "./components/PriceSpider/PriceSpiderConfig";
import store from "store-js";

function PriceSpider() {

  const currentTab = store.get("solrFiltersTab") || 0;
  const [selected, setSelected] = useState(currentTab);

  store.set("solrFiltersTab", selected);

  const handleTabChange = useCallback((index) => setSelected(index), []);

  const tabs = [
    {
      id: "priceSpider",
      title: "PriceSpider",
      content: "Settings",
      page: <PriceSpiderConfig />,
      panelID: "priceSpiderPanel",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default PriceSpider;
