import { useState, useEffect, useRef } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  Card,
  Checkbox,
  DataTable,
  DatePicker,
  Frame,
  Icon,
  Link,
  Pagination,
  Popover,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";
import { SearchMinor, CalendarMinor } from "@shopify/polaris-icons";

import * as moment from "moment";

import { useSettings } from "../../../AppContext";

import { GIVEAWAYS_SORT_FIELDS, BANNER_STATUS } from "./constants";

const FORM_TITLE = {
  LIST: "Giveaways",
  ADD: "Add New Giveaway",
  UPDATE: "Update Giveaway",
  DELETE: "Delete Giveaway",
};

function Setup() {
  const { customerId } = useSettings();

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  // Add Giveaway

  const [form, setForm] = useState(false);
  const [title, setTitle] = useState(FORM_TITLE.ADD);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDateEnabled, setStartDateEnabled] = useState(false);
  const [endDateEnabled, setEndDateEnabled] = useState(false);
  const [registrationCount, setRegistrationCount] = useState("");

  const toggleGiveawayForm = () => setForm((form) => !form);
  const nameChanged = (value) => setName(value);
  const descriptionChanged = (value) => setDescription(value);
  const startDateCheckboxChanged = (value) => setStartDateEnabled(value);
  const endDateCheckboxChanged = (value) => setEndDateEnabled(value);
  const registrationCountChanged = (value) => setRegistrationCount(value);
  const cancel = () => setForm(false);
  const showAddGiveaway = () => {
    resetForm();
    toggleGiveawayForm();
  };

  const submit = async () => {
    const _registrationCount = parseInt(registrationCount);

    const data = {
      admin: customerId,
      name,
      description: description && description.length > 0 ? description : null,
      startDate:
        startDateEnabled && selectedStartDate
          ? moment(selectedStartDate).utc().format("MM/DD/YYYY")
          : null,
      endDate:
        endDateEnabled && selectedEndDate
          ? moment(selectedEndDate).utc().format("MM/DD/YYYY")
          : null,
      registrationCount: _registrationCount > 0 ? _registrationCount : null,
    };

    if (id) {
      updateRecord(data, id);
    } else {
      createRecord(data);
    }
  };

  const createRecord = async (data) => {
    const url = `/api/giveaways`;



    try {
      // RM stringify
      const response = await axios.post(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, giveaway } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            FORM_TITLE.ADD,
            msg ? msg : `Error creating giveaway`
          );
        }

        if (status && giveaway) {
          reset();
        }
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.msg;
      const errMsg = err.response?.data?.errMsg;

      updateBanner(
        BANNER_STATUS.CRITICAL,
        FORM_TITLE.ADD,
        errMsg ? errMsg : msg ? msg : `Error creating giveaway: ${err}`
      );
    }
  };

  const updateRecord = async (data, id) => {
    const url = `/api/giveaways/${id}`;


    try {
      // RM stringify
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, giveaway } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            FORM_TITLE.UPDATE,
            msg ? msg : `Error updating giveaway`
          );
        }

        if (status && giveaway) {
          reset();
          setForm(false);
        }
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.msg;
      const errMsg = err.response?.data?.errMsg;

      updateBanner(
        BANNER_STATUS.CRITICAL,
        FORM_TITLE.UPDATE,
        errMsg ? errMsg : msg ? msg : `Error updating giveaway: ${err}`
      );
    }
  };

  const deleteRecord = async () => {
    const url = `/api/giveaways/${id}`;



    try {
      const response = await axios.delete(url);

      if (response.status === 200 || response.status === 400) {
        const { status, msg } = response.data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            FORM_TITLE.DELETE,
            msg ? msg : `Error deleting giveaway`
          );
        }

        if (status) {
          reset();
          setForm(false);
        }
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.msg;
      const errMsg = err.response?.data?.errMsg;

      updateBanner(
        BANNER_STATUS.CRITICAL,
        FORM_TITLE.DELETE,
        errMsg ? errMsg : msg ? msg : `Error deleting giveaway: ${err}`
      );
    }
  };

  const reset = () => {
    resetForm();

    setQuery({
      ...query,
      page: 1,
      sortField: "createdDate",
      sortOrder: "desc",
    });
  };

  const resetForm = () => {
    setId(null);
    setName("");
    setDescription("");
    setStartDateEnabled(false);
    setEndDateEnabled(false);
    setRegistrationCount("");
  };

  // DataTable

  const [giveaways, setGiveaways] = useState([]);
  const [rows, setRows] = useState([]);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState();

  const rowSelected = (id) => {
    setTitle(FORM_TITLE.UPDATE);

    const found = giveaways.find((item) => item.id === id);

    if (found) {
      console.log({ found });
      setId(id);
      setName(found.name);
      setDescription(found.description);

      if (found.startDate) {
        setStartDateEnabled(true);
        setSelectedStartDate(new Date(found.startDate));
      } else {
        setStartDateEnabled(false);
        setSelectedStartDate(new Date());
      }

      if (found.endDate) {
        setEndDateEnabled(true);
        setSelectedEndDate(new Date(found.endDate));
      } else {
        setEndDateEnabled(false);

        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 12);

        setSelectedEndDate(new Date(endDate));
      }

      setRegistrationCount(`${found.registrationCount}`);
    }

    setForm(true);
  };

  const debounceTimeout = 1000;
  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const getDataTableSource = (list) => {
    const lst = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      lst.push({
        id: item.id,
        name: item.name,
        description: item.description,
        startDate: `${
          item.startDate
            ? moment(item.startDate).utc().format("MM/DD/YYYY")
            : ""
        }`,
        endDate: `${
          item.endDate ? moment(item.endDate).utc().format("MM/DD/YYYY") : ""
        }`,
        registrationCount: item.registrationCount,
      });
    }

    return lst;
  };

  const [query, setQuery] = useState({
    search: "",
    sortField: "name",
    sortOrder: "asc",
    page: 1,
    pageSize: 10,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      params.push(`${key}=${encodeURIComponent(query[key])}`);
    }

    return params;
  };

  const getGiveaways = async () => {
    const params = queryParams();
    const url = `/api/giveaways/list?${params.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, hasPrevious, hasNext, total } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Giveaways",
            msg ? msg : `Error loading giveaways`
          );
        }

        setHasPrevious(hasPrevious);
        setHasNext(hasNext);

        const totalPages = Math.ceil(total / query.pageSize);
        setTotal(totalPages);

        let list = data.list;

        if (!list || list.length === 0) {
          setGiveaways([]);
          return;
        }

        const lst = getDataTableSource(list);
        setGiveaways(lst);
      } else {
        setGiveaways([]);

        updateBanner(
          BANNER_STATUS.CRITICAL,
          "Giveaways",
          msg ? msg : `Error querying giveaways`
        );
      }
    } catch (err) {
      console.log(err);

      setGiveaways([]);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        FORM_TITLE.LIST,
        `Error getting giveaways`
      );
    }
  };

  useEffect(() => {
    const arr = [];

    for (let i = 0; i < giveaways.length; i++) {
      const item = giveaways[i];

      arr.push([
        <Link
          removeUnderline
          key={item.id}
          onClick={() => rowSelected(item.id)}
        >
          <span style={{ fontWeight: "bold" }}>{item.name}</span>
        </Link>,
        item.description,
        item.startDate,
        item.endDate,
        item.registrationCount,
      ]);
    }

    setRows(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giveaways]);

  const searchChanged = (value) => {
    setQuery({
      ...query,
      search: value,
    });
  };

  useEffect(() => {
    debounce(getGiveaways, debounceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const search = () => getGiveaways();

  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);

  useEffect(() => {
    const list = GIVEAWAYS_SORT_FIELDS.map((item) => {
      return {
        label: item.title,
        value: item.id,
      };
    });

    setSortOptions(list);

    const found = GIVEAWAYS_SORT_FIELDS.find(
      (item) => item.field === query.sortField && item.order === query.sortOrder
    );

    if (found) {
      setSelectedSort(found.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortChanged = (option) => {
    setSelectedSort(option);

    const found = GIVEAWAYS_SORT_FIELDS.find(
      (item) => item.id === parseInt(option)
    );

    setQuery({
      ...query,
      sortField: found.field,
      sortOrder: found.order,
    });
  };

  // Start date picker

  const [startDateVisible, setStartDateVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [{ startMonth, startYear }, setStartDate] = useState({
    startMonth: selectedStartDate.getMonth(),
    startYear: selectedStartDate.getFullYear(),
  });

  const formattedValue = (selectedDate) =>
    selectedDate.toISOString().slice(0, 10);

  function startDateOnClose({ relatedTarget }) {
    setStartDateVisible(false);
  }

  function startDateMonthChanged(month, year) {
    setStartDate({ startMonth: month, startYear: year });
  }

  function startDateChanged({ end: newSelectedDate }) {
    setSelectedStartDate(newSelectedDate);
    setStartDateVisible(false);

    setQuery({
      ...query,
      page: 1,
    });
  }

  useEffect(() => {
    if (selectedStartDate) {
      setStartDate({
        startMonth: selectedStartDate.getMonth(),
        startYear: selectedStartDate.getFullYear(),
      });

      setQuery({
        ...query,
        page: 1,
        startDate: moment(selectedStartDate).utc().format("MM/DD/YYYY"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate]);

  // End date picker

  const [endDateVisible, setEndDateVisible] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [{ endMonth, endYear }, setEndDate] = useState({
    endMonth: selectedEndDate.getMonth(),
    endYear: selectedEndDate.getFullYear(),
  });

  function endDateOnClose({ relatedTarget }) {
    setEndDateVisible(false);
  }

  function endDateMonthChanged(month, year) {
    setEndDate({ endMonth: month, endYear: year });
  }

  function endDateChanged({ end: newSelectedDate }) {
    setSelectedEndDate(newSelectedDate);
    setEndDateVisible(false);

    setQuery({
      ...query,
      page: 1,
    });
  }

  useEffect(() => {
    if (selectedEndDate) {
      setEndDate({
        endMonth: selectedEndDate.getMonth(),
        endYear: selectedEndDate.getFullYear(),
      });

      setQuery({
        ...query,
        page: 1,
        endDate: moment(selectedEndDate).utc().format("MM/DD/YYYY"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);

  useEffect(() => {
    const startDate = new Date();
    setSelectedStartDate(startDate);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 12);

    setSelectedEndDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <div style={{ minWidth: "315px" }}>
          <TextField
            placeholder="Search Giveaways"
            value={query.search}
            onChange={searchChanged}
            autoComplete="off"
            prefix={<Icon source={SearchMinor} color="base" />}
            alignItems="end"
          />
        </div>
        <div style={{ marginLeft: 5 }}>
          <Button onClick={search}>Search</Button>
        </div>

        <div style={{ width: "100%" }}></div>

        <div style={{ marginLeft: 30 }}>
          <Select
            label=""
            placeholder="Sort By"
            options={sortOptions}
            onChange={sortChanged}
            value={selectedSort}
          />
        </div>
        {total > 0 && (
          <label
            style={{
              minWidth: "90px",
              textAlign: "right",
              paddingLeft: "10px",
              paddingTop: "8px",
            }}
          >
            {query.page} of {total}
          </label>
        )}
        <div style={{ marginLeft: 10 }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQuery({
                ...query,
                page: query.page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQuery({
                ...query,
                page: query.page + 1,
              });
            }}
          />
        </div>
      </div>

      {!form && (
        <div
          style={{
            paddingLeft: "10px",
            paddingBottom: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
          }}
        >
          <Button onClick={showAddGiveaway}>Add Giveaway</Button>
        </div>
      )}

      {form && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          >
            <Card>
              <div
                style={{
                  padding: "10px",
                  borderBottom: "rgba(225, 227, 229, 1) solid",
                }}
              >
                <label style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {title}
                </label>

                <div style={{ marginTop: "10px" }}>
                  <TextField
                    value={name}
                    onChange={nameChanged}
                    label="Name"
                    type="text"
                    autoComplete="off"
                  />
                </div>

                <div style={{ marginTop: "10px", paddingBottom: "10px" }}>
                  <TextField
                    value={description}
                    onChange={descriptionChanged}
                    label="Description"
                    type="text"
                    autoComplete="off"
                  />
                </div>

                <div style={{ paddingBottom: "10px" }}>Start Date</div>
                <div style={{ display: "flex", paddingBottom: "10px" }}>
                  <div
                    style={{
                      width: "5%",
                      marginRight: "10px",
                    }}
                  >
                    <Checkbox
                      label=""
                      checked={startDateEnabled}
                      onChange={(e) => startDateCheckboxChanged(e)}
                    />
                  </div>

                  <div style={{ width: "95%" }}>
                    <div>
                      <Popover
                        active={startDateVisible}
                        autofocusTarget="none"
                        preferredAlignment="left"
                        fullWidth
                        preferInputActivator={false}
                        preferredPosition="below"
                        preventCloseOnChildOverlayClick
                        onClose={startDateOnClose}
                        activator={
                          <TextField
                            role="combobox"
                            label={""}
                            prefix={<Icon source={CalendarMinor} />}
                            value={formattedValue(selectedStartDate)}
                            onFocus={() => setStartDateVisible(true)}
                            autoComplete="off"
                            disabled={!startDateEnabled}
                          />
                        }
                      >
                        <Card>
                          <DatePicker
                            month={startMonth}
                            year={startYear}
                            selected={selectedStartDate}
                            onMonthChange={startDateMonthChanged}
                            onChange={startDateChanged}
                          />
                        </Card>
                      </Popover>
                    </div>
                  </div>
                </div>

                <div style={{ paddingBottom: "10px" }}>End Date</div>
                <div style={{ display: "flex", paddingBottom: "10px" }}>
                  <div
                    style={{
                      width: "5%",
                      marginRight: "10px",
                    }}
                  >
                    <Checkbox
                      label=""
                      checked={endDateEnabled}
                      onChange={(e) => endDateCheckboxChanged(e)}
                    />
                  </div>

                  <div style={{ width: "95%" }}>
                    <div>
                      <Popover
                        active={endDateVisible}
                        autofocusTarget="none"
                        preferredAlignment="left"
                        fullWidth
                        preferInputActivator={false}
                        preferredPosition="below"
                        preventCloseOnChildOverlayClick
                        onClose={endDateOnClose}
                        activator={
                          <TextField
                            role="combobox"
                            label={""}
                            prefix={<Icon source={CalendarMinor} />}
                            value={formattedValue(selectedEndDate)}
                            onFocus={() => setEndDateVisible(true)}
                            autoComplete="off"
                            disabled={!endDateEnabled}
                          />
                        }
                      >
                        <Card>
                          <DatePicker
                            month={endMonth}
                            year={endYear}
                            selected={selectedEndDate}
                            onMonthChange={endDateMonthChanged}
                            onChange={endDateChanged}
                          />
                        </Card>
                      </Popover>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "10px", maxWidth: "190px" }}>
                  <TextField
                    value={registrationCount}
                    onChange={registrationCountChanged}
                    label="Registration Count"
                    type="number"
                    autoComplete="off"
                  />
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    gap: "10px",
                    marginTop: "15px",
                  }}
                >
                  <Button onClick={cancel}>Cancel</Button>
                  <Button
                    primary
                    style={{ marginLeft: "15px" }}
                    onClick={submit}
                  >
                    {id ? "Update" : "Create"}
                  </Button>
                  &nbsp;
                  {id && (
                    <Button destructive onClick={deleteRecord}>
                      Delete
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}

      {giveaways && (
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "numeric"]}
          headings={[
            "Name",
            "Description",
            "Start Date",
            "End Date",
            "Registration Count",
          ]}
          rows={rows}
        />
      )}

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Setup;
