import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";

import Spinner from "react-bootstrap/Spinner";

function SolrSync() {

  const STATUS_COMPLETED = "Completed";
  const STATUS_REFRESH_INTERVAL = 30000;
  const STATUS_UPDATE_INTERVAL = 5000;

  const callRef = useRef(false);

  const [date, setDate] = useState("");
  const [completed, setCompleted] = useState(false);
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const style = {
    statusBlock: {
      padding: "10px",
    },
    caption: {
      padding: "5px",
      fontWeight: 600,
    },
    list: {
      listStyleType: "none",
      padding: "0px",
      paddingLeft: "15px",
      margin: 0,
    },
    statusControls: {
      padding: "10px",
    },
    loadingStatus: {
      padding: "10px",
      fontSize: "14px",
    },
  };

  useEffect(() => {
    if (callRef.current) return;
    callRef.current = true;

    getSolrSyncStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSolrSyncStatus = async () => {
    setLoading(true);

    const url = `/api/solr/syncStatus`;

    const response = await axios.get(url);

    if (response && response.data) {
      parseRefreshStatusResponse(response.data);
    } else {
      resetStatus();
    }

    setLoading(false);
  };

  const parseRefreshStatusResponse = (data) => {
    let eventDate = new Date(data.EventDate);

    if (eventDate) {
      eventDate = eventDate.toLocaleString();
      setDate(eventDate);
    }

    setCompleted(data.completed);
    setSyncing(!data.completed);

    setStatus(data.status);

    const notesData = data.Notes;

    if (notesData) {
      const notesArr = notesData.split(",");
      setNotes(notesArr);
    } else {
      setNotes([]);
    }

    if (!data.completed) {
      setTimeout(() => {
        getSolrSyncStatus();
      }, STATUS_REFRESH_INTERVAL);
    }
  };

  const refreshStatus = async () => {
    getSolrSyncStatus();
  };

  const startSolrSync = async () => {
    resetStatus();
    setSyncing(true);
    setCompleted(false);

    const url = `/api/solr/update`;

    const response = await axios.get(url);

    if (response && response.data) {
      parseStartSolrSyncResponse(response.data);
    } else {
      resetStatus();
    }
  };

  const parseStartSolrSyncResponse = (data) => {
    resetStatus();

    const responseMessage = [];

    if (data.result) {
      responseMessage.push("SOLR sync started successfully");
    } else {
      responseMessage.push("SOLR sync did  not start");
    }

    setNotes(responseMessage);

    setTimeout(() => {
      getSolrSyncStatus();
    }, STATUS_UPDATE_INTERVAL);
  };

  const resetStatus = () => {
    setDate("");
    setStatus("");
    setNotes([]);
  };

  return (
    <div>
      <div style={style.statusBlock}>
        <div>
          <span style={style.caption}>Date:</span>
          <span>{date}</span>
        </div>

        <div>
          <span style={style.caption}>Last Sync status:</span>
          {completed && <span>{STATUS_COMPLETED}</span>}

          {!completed && <span>{status}</span>}
        </div>

        {notes && notes.length > 0 && (
          <div>
            <span style={style.caption}>Notes:</span>
            <span>
              <ul>
                {notes.map((note, index) => {
                  return (
                    <li key={index} style={style.list}>
                      {note}
                    </li>
                  );
                })}
              </ul>
            </span>
          </div>
        )}
      </div>

      <div style={style.statusControls}>
        <button
          className="export btn btn-primary mr-4"
          style={{ width: "140px" }}
          disabled={loading}
          onClick={refreshStatus}
        >
          {loading && (
            <div>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span style={style.loadingStatus}>Loading...</span>
            </div>
          )}
          {!loading && <span>Refresh status</span>}
        </button>

        <button
          className="export btn btn-primary mr-4"
          style={{ width: "160px" }}
          disabled={syncing || !completed}
          onClick={startSolrSync}
        >
          {syncing && (
            <div>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span style={style.loadingStatus}>Syncing...</span>
            </div>
          )}
          {!syncing && <span>Start SOLR sync</span>}
        </button>
      </div>
    </div>
  );
}

export default SolrSync;
