import { useState, useEffect } from "react";
import axios from "../../../axiosInstance";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Banner, Card, Frame, Toast } from "@shopify/polaris";

import { PolarisVizProvider, BarChart } from "@shopify/polaris-viz";

import { BANNER_STATUS } from "./constants";

function Dashbard() {
  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [submissionsByStatus, setSubmissionsByStatus] = useState([]);
  const [submissionsByDenyReason, setSubmissionsByDenyReason] = useState([]);
  const [stats, setStats] = useState({});
  const [createdToAssigned, setCreatedToAssigned] = useState("");
  const [assignedToApproved, setAssignedToApproved] = useState("");
  const [createdToApproved, setCreatedToApproved] = useState("");

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const getSubmissionsByStatus = async () => {
    const bannerTitle = "Submissions By Status";

    const url = `/api/promo/reports/submissionsByStatus`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, data: chartData } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
          setSubmissionsByStatus(chartData ? chartData : []);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading Records`
          );
          setSubmissionsByStatus([]);
        }
      } else {
        setSubmissionsByStatus([]);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error loading Records`
        );
      }
    } catch (error) {
      setSubmissionsByStatus([]);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error deleting Reason`
      );
    }
  };

  const getSubmissionsByDenyReason = async () => {
    const bannerTitle = "Submissions By Deny Reason";

    const url = `/api/promo/reports/submissionsByDenyReason`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, data: chartData } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
          setSubmissionsByDenyReason(chartData ? chartData : []);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading Records`
          );
          setSubmissionsByDenyReason([]);
        }
      } else {
        setSubmissionsByDenyReason([]);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error loading Records`
        );
      }
    } catch (error) {
      setSubmissionsByDenyReason([]);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error loading Records`
      );
    }
  };

  const getSubmissionsStats = async () => {
    const bannerTitle = "Submissions Stats";

    const url = `/api/promo/reports/submissionsStats`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, data: stats } = data;

        if (status) {
          const {
            createdToAssigned,
            assignedToApproved,
            createdToApproved,
          } = stats;

          if (createdToAssigned !== undefined && createdToAssigned >= 0) {
            setCreatedToAssigned(formatStats(createdToAssigned));
          } else {
            setCreatedToAssigned(`0 Minutes`);
          }

          if (assignedToApproved !== undefined && assignedToApproved >= 0) {
            setAssignedToApproved(formatStats(assignedToApproved));
          } else {
            setAssignedToApproved(`0 Minutes`);
          }

          if (createdToApproved !== undefined && createdToApproved >= 0) {
            setCreatedToApproved(formatStats(createdToApproved));
          } else {
            setCreatedToApproved(`0 Minutes`);
          }

          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading Records`
          );
          setStats({});
        }
      } else {
        setStats({});
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error loading Records`
        );
      }
    } catch (error) {
      setStats({});

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error loading Records`
      );
    }
  };

  useEffect(() => {
    getSubmissionsByStatus();
    getSubmissionsByDenyReason();
    getSubmissionsStats();
  }, []);

  const getError = (error) => {
    console.log(error);

    let err = null;

    if (error?.response) {
      const { status, msg } = error.response.data;
      err = msg;
    } else if (error?.request) {
      err = "Request error";
    } else {
      err = error?.message || "";
    }

    return err;
  };

  function formatStats(seconds) {
    if (seconds < 60) {
      return `${seconds} Second${seconds == 1 ? "" : "s"}`;
    } else if (seconds < 3600) {
      const minutes = parseInt(seconds / 60);
      seconds -= minutes * 60;

      if (seconds > 0) {
        return `${minutes} Min ${seconds} Sec`;
      } else {
        return `${minutes} Min`;
      }
    } else if (seconds < 216000) {
      const hours = parseInt(seconds / 3600);
      seconds -= hours * 3600;
      const minutes = parseInt(seconds / 60);
      seconds -= minutes * 60;

      if (minutes > 0) {
        return `${hours} Hour${hours == 1 ? "" : "s"} ${minutes} Min`;
      } else {
        return `${hours} Hour${hours == 1 ? "" : "s"}`;
      }
    } else if (seconds < 12960000) {
      const days = parseInt(seconds / 216000);
      seconds -= days * 216000;
      const hours = parseInt(seconds / 3600);
      seconds -= hours * 3600;
      const minutes = parseInt(seconds / 60);
      seconds -= minutes * 60;

      if (hours > 0) {
        return `${days} Day${days == 1 ? "" : "s"} ${hours} Hour${
          hours == 1 ? "" : "s"
        }`;
      } else {
        return `${days} Day${days == 1 ? "" : "s"}`;
      }
    } else {
      return `Over a Month`;
    }
  }

  return (
    <Frame>
      <Container className="p-4 m-0" style={{ maxWidth: "100%" }}>
        {showBanner && (
          <Row>
            <Col>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  paddingRight: "10px",
                }}
              >
                <Banner
                  title={bannerTitle}
                  status={bannerStatus}
                  onDismiss={() => {
                    setShowBanner(false);
                  }}
                >
                  <p>{bannerDescription}</p>
                </Banner>
              </div>
            </Col>
          </Row>
        )}
        <Row className="mb-4">
          <Col>
            <div className="stats-value">{createdToApproved}</div>
            <div className="stats-note">
              Average time from Submitted to Approved/Denied
            </div>
          </Col>
          <Col>
            <div className="stats-value">{assignedToApproved}</div>
            <div className="stats-note">
              Average time from Assigned to Approved/Denied
            </div>
          </Col>
          <Col>
            <div className="stats-value">{createdToAssigned}</div>
            <div className="stats-note">
              Average time from Submitted to Assigned
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Card sectioned title={"Submissions by Status"}>
              <PolarisVizProvider
                themes={{
                  Light: {
                    chartContainer: {
                      minHeight: 400,
                    },
                  },
                }}
              >
                <BarChart
                  data={[
                    {
                      data: submissionsByStatus,
                    },
                  ]}
                  theme={"Light"}
                />
              </PolarisVizProvider>
            </Card>
          </Col>
          <Col>
            <Card sectioned title={"Submissions by Deny Reason"}>
              <PolarisVizProvider
                themes={{
                  Light: {
                    chartContainer: {
                      minHeight: 400,
                    },
                  },
                }}
              >
                <BarChart
                  direction="horizontal"
                  data={[
                    {
                      data: submissionsByDenyReason,
                    },
                  ]}
                  theme={"Light"}
                />
              </PolarisVizProvider>
            </Card>
          </Col>
        </Row>
      </Container>

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Dashbard;
