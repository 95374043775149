import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import moment from "moment";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-date-picker";

import "bootstrap/dist/css/bootstrap.min.css";

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: new Date(),
            dateTo: new Date(),
            statusError: false,
            statusText: "",
            // bundle: false,
            // component: false,
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            statusError: false,
            statusText: `Select date From, date To and click Generate Report button`,
        });
    }

    dateFromChanged = (e) => {
        if (e) {
            this.setState({
                dateFrom: e,
            });
        }
    };

    dateToChanged = (e) => {
        if (e) {
            this.setState({
                dateTo: e,
            });
        }
    };

    compareDates = () => {
        const { dateFrom, dateTo } = this.state;
        return (
            moment(dateFrom).format("YYYY-MM-DD") >
            moment(dateTo).format("YYYY-MM-DD")
        );
    };

    generateReport = async (e) => {
        this.setState({
            statusError: false,
            statusText: `Report is in progress, Please Wait ...`,
        });

        this.setState({
            loading: true,
        });

        const { dateFrom, dateTo } = this.state;
        let date_from = moment(dateFrom).format("YYYY-MM-DDT")+"00:00:00Z";
        let date_to =  moment(dateTo).format("YYYY-MM-DDT")+"23:59:59Z";
        // let date_from = moment(dateFrom).utc().toJSON();
        // let date_to = moment(dateTo).utc().toJSON();

        const url = `/api/knot?dateFrom=${date_from}&dateTo=${date_to}`;

        try {
            const response = await axios.get(url);
            const data = response.data;

            this.setState({
                statusError: !data?.status,
                statusText: data?.msg || "",
                loading: false,
            });
        } catch (err) {
            console.log(err);

            this.setState({
                statusError: true,
                statusText: `Error generating report: ${err}`,
                loading: false,
            });
        }
    };

    render() {
        return (
            <Container className="p-2">
                <Row className="p-2">
                    <Col style={{ maxWidth: "50px" }}>
                        <label className="mt-2">From:</label>
                    </Col>
                    <Col xs={10}>
                        <DatePicker
                            onChange={this.dateFromChanged}
                            clearIcon={null}
                            value={this.state.dateFrom}
                            name="dateFrom"
                            className="mr-5"
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                        />
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col style={{ maxWidth: "50px" }}>
                        <label className="mt-2">To:</label>
                    </Col>
                    <Col xs={10}>
                        <DatePicker
                            onChange={this.dateToChanged}
                            clearIcon={null}
                            value={this.state.dateTo}
                            name="dateTo"
                            className="mr-5"
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                        />
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col>
                        <button
                            onClick={this.generateReport}
                            className="export btn btn-primary mr-5"
                            disabled={this.compareDates()}
                            style={{ fontSize: "14px", width: "150px" }}
                        >
                            {this.state.loading && (
                                <div>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span
                                        style={{
                                            padding: "10px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Loading...
                                    </span>
                                </div>
                            )}
                            {!this.state.loading && (
                                <span>Generate Report</span>
                            )}
                        </button>
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col>
                        <span
                            style={{
                                color: this.state.statusError ? "red" : "green",
                                fontSize: "15px",
                            }}
                        >
                            {this.state.statusText}
                        </span>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Report;
