import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import cloneDeep from "lodash/cloneDeep";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Button, Alert, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import { suppressDeprecationWarnings } from "moment";
import { Stack, RadioButton } from "@shopify/polaris";

// import moment from 'moment';
const moment = require("moment-timezone");
var XLSX = require("xlsx");

class ImportPrice extends Component {
  constructor(props) {
    super(props);
    

    this.customerId = props.customerId;

    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.state = {
      modules: AllCommunityModules,
      value: "InvitedUser",
      result: { ids: [], removeTag: {}, addTag: {} },
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "SKU",
          field: "MODEL",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
          // headerCheckboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // checkboxSelection: true,
        },
        {
          headerName: "Price",
          field: "number",
          sortable: true,
          width: 70,
          //comparator:  customComparator,
          valueFormatter: (params) => params.data.PRICE.toFixed(2),
        },
        {
          headerName: "Compare At Price",
          field: "number",
          sortable: true,
          width: 70,
          //comparator:  customComparator,
          cellRenderer: (params) => {
            if (params.data.COMPARE_AT_PRICE) {
              const val = params.data.COMPARE_AT_PRICE.toFixed(2);
              return val;
            }
          },
        },
        {
          headerName: "Effective Date",
          field: "EFFECTIVE_DATE",
          width: 70,
          cellRenderer: (data) => {
            let dat = data.data.EFFECTIVE_DATE.split(".");
            let effdate = dat[0];
            return moment(effdate).format("MM-DD-YYYY HH:mm:ss");
          },
        },
        {
          headerName: "Below MAPP Item Tag",
          field: "BELOW_MAPP_ITEM_TAG",
          width: 70,
          //comparator:  customComparator
        },
        {
          headerName: "Edit",
          field: "icon",
          width: 35,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-primary";
            link.innerHTML = '<i class="fa fa-pencil"> Edit</i>';
            link.addEventListener("click", (e) => {
              e.preventDefault();

              let dat = params.data.EFFECTIVE_DATE.split(".");
              let effdate = dat[0];
              this.setState({
                ID: params.data.ID,
                MODEL: params.data.MODEL,
                PRICE: params.data.PRICE,
                COMPARE_AT_PRICE: params.data.COMPARE_AT_PRICE,
                EFFECTIVE_DATE: effdate
                  ? effdate === "undefined"
                    ? new Date()
                    : new Date(effdate)
                  : new Date(),
                BELOW_MAPP_ITEM_TAG: params.data.BELOW_MAPP_ITEM_TAG,
              });
              this.Popup();
            });
            return link;
          },
        },
        {
          headerName: "Resend Invite",
          field: "icon",
          hide: true,
          width: 35,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-primary";
            link.innerHTML = '<i class="fa fa-pencil"> Resend </i>';
            link.addEventListener("click", (e) => {
              e.preventDefault();
              let cusid = params.data.id;
              console.log("csut id", cusid);
              let arrid = cusid.split("/");
              let id = arrid[arrid.length - 1];

              let data = {};
              axios
                .post(`/api/partnersendinvite/` + id, data)
                .then((response) => response.data)
                .then(
                  (sucess) => {
                    // this.setState({
                    //     rowData: sucess.rows, pageData: sucess
                    // })
                    console.log(this.state.rowData);
                    if (sucess.rows.length === 0) {
                      this.gridApi.showNoRowsOverlay();
                    }
                  },

                  (error) => {
                    console.log(error);
                  }
                );
              //this.Popup();
            });
            return link;
          },
        },
      ],
      defaultColDef: {
        resizable: true,
        domLayout: "autoHeight",
      },
      openmodel: false,
      opencreateusermodel: false,
      fullname: "",
      rowData: [],
      selectedRowData: [],
      pageData: [],
      paginationPageSize: 25,
      showMessage: false,
      profile_crm_id: "",
      profile_as400_id: "",
      profile_privacy_policy_consent: false,
      profile_email_policy_consent: false,
      ID: "",
      name: "",
      updateresult: {
        ID: {},
        MODEL: {},
        PRICE: {},
        COMPARE_AT_PRICE: {},
        EFFECTIVE_DATE: {},
        BELOW_MAPP_ITEM_TAG: {},
      },
      // search
      searchText: "",
      searchValue: "",
      //  searchDate: null,
      //  searchType:null,
      rowDataExcel: [],
      first_name: "",
      last_name: "",
      email: "",
      additional_tags: "",
      notvalidemail: false,
      notvalidfname: false,
      notvalidlname: false,
      reinviteLoader: false,
      notvalidMODEL: false,
      notvalidPRICE: false,
      notvalidCOMPARE_AT_PRICE: false,
      notvalidEFFECTIVE_DATE: false,
      notvalidBELOW_MAPP_ITEM_TAG: false,
    };
  }

  componentDidMount() {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    axios
      .post(`/api/bulkprice`, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess,
            pageData: sucess,
          });
          console.log(this.state.rowData);
          if (sucess.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },

        (error) => {
          console.log(error);
        }
      );
  }

  // eslint-disable-next-line no-undef
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  onBtNext = () => {
    this.setState({ selectedRowData: [], reinviteLoader: false });
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    axios
      .post(
        `/api/partnerinviteuserslist?direction=next&last_id=${this.state.pageData.nextCursor}`,
        data
      )
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  onBtPrevious = () => {
    this.setState({ selectedRowData: [], reinviteLoader: false });
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    axios
      .post(
        `/api/partnerinviteuserslist?direction=previous&last_id=${this.state.pageData.previousCursor}`,
        data
      )
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  onReady = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    axios
      .post(`/api/bulkprice`, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess,
            pageData: sucess,
          });
          console.log(this.state.rowData);
          if (sucess.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },

        (error) => {
          console.log(error);
        }
      );
  };

  // eslint-disable-next-line no-undef
  onSelectionChanged = (data) => {
    this.setState(
      {
        result: {
          ids: data,
          removeTag: this.state.value,
          addTag: data.Status,
        },
      },
      () => this.connected()
    );
  };

  connected = (params) => {
    let payload = {
      excelData: params,
    };

    axios
      .post(`/api/bulkpriceupload`, payload)
      .then((response) => response.data)
      .then(
        (result) => {
          this.setState({ showMessage: true }, () =>
            setTimeout(() => {
              window.location.reload(false);
            }, 3000)
          );
        },
        (err) => {
          console.log(err);
        }
      );
  };

  changeHandler = async (event) => {
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          this.processExcel(reader.result);
        };
        reader.readAsBinaryString(event.target.files[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  processExcel = (data) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    console.log("excelRows", excelRows);
    this.connected(excelRows);
  };

  refreshPage = () => {
    window.location.reload(false);
  };
  opencreateuser = () => {
    console.log("create user enty");
    this.setState({
      opencreateusermodel: true,
    });
  };

  Popup = () => {
    this.setState({
      openmodel: true,
    });
  };

  closemodel = () => {
    this.setState({
      openmodel: false,
      opencreateusermodel: false,
      ID: "",
      loading: false,
      BELOW_MAPP_ITEM_TAG: "",
      COMPARE_AT_PRICE: "",
      EFFECTIVE_DATE: "",
      MODEL: "",
      PRICE: "",
      additional_tags: "",
      notvalidfname: false,
      notvalidlname: false,
      notvalidemail: false,
      notvalidMODEL: false,
      notvalidPRICE: false,
      notvalidCOMPARE_AT_PRICE: false,
      notvalidEFFECTIVE_DATE: false,
      notvalidBELOW_MAPP_ITEM_TAG: false,
    });
  };
  MODEL = (e) => {
    if (!e.target.value) this.setState({ notvalidMODEL: true });
    else this.setState({ notvalidMODEL: false });
    this.setState({ MODEL: e.target.value });
  };

  PRICE = (e) => {
    if (!e.target.value) this.setState({ notvalidPRICE: true });
    else this.setState({ notvalidPRICE: false });

    this.setState({ PRICE: parseFloat(e.target.value).toFixed(2) });
  };

  COMPARE_AT_PRICE = (e) => {
    if (e.target.value) {
      this.setState({ notvalidCOMPARE_AT_PRICE: true });
      this.setState({
        COMPARE_AT_PRICE: parseFloat(e.target.value).toFixed(2),
      });
    } else {
      this.setState({ COMPARE_AT_PRICE: null });
    }
  };

  EFFECTIVE_DATE = (e) => {
    if (e) {
      if (Date.parse(e) > Date.parse(new Date())) {
        this.setState({ notvalidEFFECTIVE_DATE: false, EFFECTIVE_DATE: e });
      } else {
        this.setState({ notvalidEFFECTIVE_DATE: true });
      }
    } else {
      this.setState({ notvalidEFFECTIVE_DATE: true });
    }
  };

  BELOW_MAPP_ITEM_TAG = (val) => {
    if (val) {
      this.setState({
        BELOW_MAPP_ITEM_TAG: val,
      });
    }
  };
  email = (e) => {
    this.setState({ email: e.target.value });
  };
  bluremail = (e) => {
    let isemailvalid = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g
    ).test(e);
    if (!isemailvalid) {
      this.setState({ notvalidemail: true });
    } else {
      this.setState({ notvalidemail: false });
    }

    console.log("isemailvalid ", isemailvalid);
    // this.setState({ email: e })
  };
  rfirstname = (e) => {
    if (!e.target.value) this.setState({ notvalidfname: true });
    else this.setState({ notvalidfname: false });
    this.setState({ firstName: e.target.value });
  };

  rlastname = (e) => {
    if (!e.target.value) this.setState({ notvalidlname: true });
    else this.setState({ notvalidlname: false });
    this.setState({ lastName: e.target.value });
  };

  oncreateuser = () => {
    let isvalidfailed = false;
    if (!this.state.first_name) {
      this.setState({ notvalidfname: true });
      isvalidfailed = true;
    } else this.setState({ notvalidfname: false });
    if (!this.state.last_name) {
      this.setState({ notvalidlname: true });
      isvalidfailed = true;
    } else this.setState({ notvalidlname: false });

    let isemailvalid = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g
    ).test(this.state.email);
    if (!isemailvalid) {
      this.setState({ notvalidemail: true });
      isvalidfailed = true;
    } else this.setState({ notvalidemail: false });

    if (isvalidfailed) {
      return;
    }

    const postdata = {
      excelData: [
        {
          id: "",
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          additional_tags: "",
        },
      ],
    };
    axios
      .post(`/api/partnereditinviteuser`, postdata)
      .then((response) => response.id)
      .then(
        (result) => {
          setTimeout(() => {
            this.onReady();
            this.closemodel();
          }, 2000);
        },
        (err) => {
          console.log(err);
        }
      );
  };

  onupdate = () => {
    let isvalidfailed = false;
    if (!this.state.MODEL) {
      this.setState({ notvalidMODEL: true });
      isvalidfailed = true;
    } else this.setState({ notvalidMODEL: false });
    if (!this.state.PRICE) {
      this.setState({ notvalidPRICE: true });
      isvalidfailed = true;
    } else this.setState({ notvalidPRICE: false });

    // if (!this.state.COMPARE_AT_PRICE) {
    //     this.setState({ notvalidCOMPARE_AT_PRICE: true });
    //     isvalidfailed = true;
    // }
    // else
    //     this.setState({ notvalidCOMPARE_AT_PRICE: false });

    if (!this.state.EFFECTIVE_DATE) {
      this.setState({ notvalidEFFECTIVE_DATE: true });
      isvalidfailed = true;
    } else if (Date.parse(this.state.EFFECTIVE_DATE) > Date.parse(new Date())) {
      this.setState({
        notvalidEFFECTIVE_DATE: false,
        EFFECTIVE_DATE: this.state.EFFECTIVE_DATE,
      });
      isvalidfailed = false;
    } else {
      this.setState({ notvalidEFFECTIVE_DATE: true });
      isvalidfailed = true;
    }

    if (isvalidfailed) return;

    this.setState(
      {
        updateresult: {
          ID: this.state.ID,
          MODEL: this.state.MODEL,
          PRICE: this.state.PRICE,
          COMPARE_AT_PRICE: this.state.COMPARE_AT_PRICE,
          EFFECTIVE_DATE: this.state.EFFECTIVE_DATE,
          BELOW_MAPP_ITEM_TAG: this.state.BELOW_MAPP_ITEM_TAG,
        },
        loading: true,
      },
      () => this.onupdateresult()
    );
  };

  onupdateresult = () => {
    var data = this.state.updateresult;
    console.log(data);
    axios
      .patch(`/api/updatebulkprice`, data)
      .then((response) => response.ID)
      .then(
        (result) => {
          setTimeout(() => {
            this.onReady();
            this.closemodel();
          }, 2000);
        },
        (err) => {
          console.log(err);
        }
      );
  };

  // search
  onSearch = () => {
    console.log(this.state.searchType);
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    axios
      .post(`/api/bulkprice`, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess,
            pageData: sucess,
          });
          console.log(this.state.rowData);
          if (sucess.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },

        (error) => {
          console.log(error);
        }
      );
  };
  onSearchData = (e) => {
    if (e.target.value === "" || e.target.value) {
      this.parseDate(e.target.value);
      this.setState({ searchText: e.target.value });
    }
  };

  // onDateSearch = (e) => {
  //   this.setState({
  //     searchDate: e,
  //     searchType: e != null ? moment(e).format('DD-MM-YYYY'): null
  //   }, () => this.onSearch())
  // }

  parseDate(input) {
    // const parsedDate = moment(input, 'MM/DD/YYYY');
    // if (input.length == 10 && parsedDate.isValid()) {
    //     this.setState({
    //         searchType: 'Date',
    //         searchValue: moment(parsedDate).format('DD-MM-YYYY'),
    //     });
    // } else {
    this.setState({
      searchType: "SKU",
      searchValue: input,
    });
    // }
  }
  //export excel
  exportexcelrow = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };
    console.log("excel filt-", data);
    axios
      .post(`/api/partnerinviteduser/excelexport`, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState(
            {
              rowDataExcel: sucess.data,
            },
            () => this.exportexcel()
          );
          if (sucess.data.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  exportexcel = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    // const {rowDataExcel,startDate,endDate}=this.state;
    const { rowDataExcel } = this.state;
    console.log("excel ", rowDataExcel);
    const sheetname =
      "Invited_Partner_Users_Report_" +
      moment().format("MM_DD_YYYY_ h:mm:ss_A");
    const worksheet = workbook.addWorksheet("Invited_Partner_Users_Report_1");
    worksheet.mergeCells("A1", "F1");
    worksheet.getCell("A1,B1,C1,D1,").value = "Invited Partner Users Report";
    worksheet.getCell("A1,B1,C1,D1,").alignment = { horizontal: "center" };
    worksheet.getCell("A1,B1,C1,D1,").font = {
      bold: true,
      name: "Roboto",
      size: 12,
    };
    // worksheet.getCell('A3').value = 'From-Date ';
    // worksheet.getCell('A3').alignment = { horizontal: 'left' };
    // worksheet.getCell('A3').font = { bold: true, name: 'Roboto', size: 10 };
    // worksheet.getCell('B3').value = moment(startDate).format('MM/DD/YYYY');
    // worksheet.getCell('B3').alignment = { vertical: 'bottom', horizontal: 'left' };
    // worksheet.getCell('A4').value = 'To-Date ';
    // worksheet.getCell('A4').alignment = { horizontal: 'left' };
    // worksheet.getCell('A4').font = { bold: true, name: 'Roboto', size: 10 };
    // worksheet.getCell('B4').value = moment(endDate).format('MM/DD/YYYY');
    // worksheet.getCell('B4').alignment = { vertical: 'bottom', horizontal: 'left' };

    worksheet.getCell("A5").value = "Created on";
    worksheet.getCell("A5").alignment = { horizontal: "left" };
    worksheet.getCell("A5").font = { bold: true, name: "Roboto", size: 10 };
    worksheet.getCell("B5").value = moment().format("MM/DD/YYYY h:mm:ss A ");
    worksheet.getCell("B5").alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    // worksheet.addRow({"":''})
    worksheet.getRow(9).values = ["Name", "Email", "Tags", "Created Date"];
    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getRow(9).font = {
      bold: true,
      name: "Roboto",
      size: 10,
    };
    worksheet.columns = [
      { key: "Name", width: 35 },
      { key: "Email", width: 35 },
      { key: "Tags", width: 35 },
      { key: "Created Date", width: 35 },
    ];

    var row_count = 9;
    console.log(this.state);
    rowDataExcel.forEach((e, index) => {
      row_count++;
      let fliternp = e.tags.filter((tag) =>
        tag.toLowerCase().startsWith("np:")
      );
      let tagnp = "";
      if (fliternp.length > 0) {
        tagnp = fliternp[0].split(":")[1] || "";
      }
      worksheet.addRow({
        Name: e.displayName != null ? e.displayName : "",
        Email: e.email != null ? e.email : "",
        Tags: e.tags.join(", "),
        "Created Date": moment(e.createdAt).format("MM/DD/YYYY"),
      });
    });
    const insideColumns = ["A", "B", "C", "D"]; // 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U','V','V','W' ]
    var last_row_count = 1;
    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      last_row_count++;
      if (rowNumber !== 9 && rowNumber > 9) {
        insideColumns.forEach((c) => {
          worksheet.getCell(`${c}${row_count}`).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "thin" },
            right: { style: "none" },
          };
          // if(c!=='B'){
          worksheet.getCell(`${c}${rowNumber}`).alignment = {
            horizontal: "left",
          };
          //  }
          if (c === "V") {
            worksheet.getCell(`V${rowNumber}`).border = {
              top: { style: "none" },
              bottom: { style: "none" },
              left: { style: "none" },
              right: { style: "thin" },
            };
            if (`V${row_count + 1}`) {
              worksheet.getCell(`V${row_count}`).border = {
                top: { style: "none" },
                bottom: { style: "thin" },
                left: { style: "none" },
                right: { style: "thin" },
              };
            }
          }
          if (rowNumber >= 2) {
            if (rowNumber % 2 === 0) {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "D6EDED" },
              };
            } else {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" },
              };
            }
          }
        });
      }
    });
    insideColumns.forEach((v) => {
      if (v !== "Z") {
        worksheet.getCell(`${v}${9}`).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "none" },
          right: { style: "none" },
        };
        if (v === "V") {
          worksheet.getCell(`${v}${9}`).border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "none" },
            right: { style: "thin" },
          };
        }
        // worksheet.getCell(`${v}${6}`).alignment = { horizontal: "center" }
        worksheet.getCell(`${v}${9}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "43ABAC" },
        };
      }
    });
    let FileSaver = require("file-saver");
    // save workbook to disk
    // eslint-disable-next-line no-undef
    workbook.xlsx
      .writeBuffer("D:/Invitesample.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
  };
  //export excel

  bulkReInvite = (e) => {
    e.preventDefault();

    let data = this.state.selectedRowData.map((row) => {
      return {
        id: row.id,
        email: row.email,
      };
    });
    //console.log("post data", data)
    axios
      .post(`/api/bulkreinvite`, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            reinviteLoader: true,
          });
          setTimeout(() => {
            this.gridApi.deselectAll();
            this.setState({
              reinviteLoader: false,
              selectedRowData: [],
            });
          }, 5000);
          //console.log("success",sucess);
        },

        (error) => {
          console.log(error);
        }
      );
  };
  render() {
    const {
      openmodel,
      opencreateusermodel,
      notvalidMODEL,
      notvalidPRICE,
      notvalidCOMPARE_AT_PRICE,
      notvalidEFFECTIVE_DATE,
      notvalidBELOW_MAPP_ITEM_TAG,
      reinviteLoader,
      BELOW_MAPP_ITEM_TAG,
      COMPARE_AT_PRICE,
      EFFECTIVE_DATE,
      ID,
      MODEL,
      PRICE,
    } = this.state;

    return (
      <div>
        <div class="headertop">
          <label style={{ color: "red" }}>
            * Please allow few mins to reflect the changes.
          </label>
          <button class="transprant" onClick={this.refreshPage}>
            <i class="facss fa fa-refresh fa-2x"></i>
          </button>
        </div>
        <div class="headertop">
          {/* search */}
          <div className="headerbottom searchbar">
            <div class="inputbox">
              <input
                class="mr-1 searchtinput"
                maxlength="60"
                value={this.state.searchText}
                onKeyPress={(event) => event.key === "Enter" && this.onSearch()}
                onChange={this.onSearchData}
              ></input>
              <button class="btn btn-primary mr-5" onClick={this.onSearch}>
                Search
              </button>
            </div>
            <div class="inputhint">
              <small>Please enter product sku</small>
            </div>
          </div>

          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            // style={{ display: 'none' }}
            id="contained-button-file"
            single
            type="file"
            onChange={this.changeHandler.bind(this)}
          />
        </div>

        {/* <div className="space">
                <div className="text">Search Date:</div>
                    <div className="datefield">
                      <DatePicker
                        onChange={this.onDateSearch}  format="MM/dd/yyyy"
                        value={this.state.searchDate} name="searchDate"
                      />
                    </div>
                  </div> */}

        {this.state.showMessage && (
          <div className="showMessage">
            File is uploaded to server, check the page few min later{" "}
          </div>
        )}
        {reinviteLoader && (
          <Alert variant="primary">
            <Spinner animation="border" /> Background reinvite process is
            started...
          </Alert>
        )}
        <div className="headertop">
          {/* {this.state.rowData.length > 0 && <div class="headerexcel"> <button onClick={this.exportexcelrow} className="export btn btn-primary mr-5">
                        Export Excel

                    </button>
                    </div>} */}
        </div>

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "20px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={false}
              suppressScrollOnNewData={true}
              rowSelection={"multiple"}
              onSelectionChanged={() => {
                this.setState({
                  selectedRowData: this.gridApi.getSelectedRows(),
                });
              }}
            />
          </div>
        </div>
        {/* <div className="headerbottom">
                    <button class="previous" disabled={!this.state.pageData.hasPreviousPage} onClick={() => this.onBtPrevious()}>Previous</button>
                    <button class="next" disabled={!this.state.pageData.hasNextPage} onClick={() => this.onBtNext()}>Next</button>
                </div> */}
        <Modal
          show={openmodel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closemodel}
          className="importprice-model-header"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              SKU: {MODEL}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <fieldset>
              <legend>Price Details</legend>
              <div className="space">
                <div className="text"> Sku</div>

                <input
                  className={notvalidMODEL ? "boderred" : ""}
                  value={MODEL}
                  onChange={this.MODEL}
                />
              </div>
              {notvalidMODEL && (
                <div className="inputError">
                  <small> Sku not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Price</div>

                <input
                  type="number"
                  className={notvalidPRICE ? "boderred" : ""}
                  value={PRICE}
                  onChange={this.PRICE}
                  min="0"
                  step="0.1"
                />
              </div>
              {notvalidPRICE && (
                <div className="inputError">
                  <small> Price not valid.</small>
                </div>
              )}
              <div className="space">
                <div className="text"> Compare At Price</div>
                <input
                  type="number"
                  value={COMPARE_AT_PRICE}
                  onChange={this.COMPARE_AT_PRICE}
                  step="0.1"
                />
              </div>
              {/* {
                                notvalidCOMPARE_AT_PRICE && <div className="inputError"><small> Compare At Price not valid.</small></div>
                            } */}

              <div className="space">
                <div className="text"> Date From: </div>
                <div className="datefield">
                  <DateTimePicker
                    onChange={this.EFFECTIVE_DATE}
                    // minDate={moment().toDate()}
                    clearIcon={null}
                    format="MM/dd/yyyy h:mm:ss a"
                    value={EFFECTIVE_DATE}
                  />
                </div>
              </div>
              {notvalidEFFECTIVE_DATE && (
                <div className="inputError">
                  <small> Date not valid.</small>
                </div>
              )}
              {/* <div className="space">
                                <div className="text"> Effective Date</div>
                                <input className={notvalidEFFECTIVE_DATE ? "boderred" : ''} value={EFFECTIVE_DATE} onChange={this.EFFECTIVE_DATE}  />
                            </div>
                            {
                                notvalidEFFECTIVE_DATE && <div className="inputError"><small> Effective Date not valid.</small></div>
                            } */}
              <div className="space">
                <div className="text"> Below MAPP Item Tag</div>
                {/* <input className={notvalidBELOW_MAPP_ITEM_TAG ? "boderred" : ''} value={BELOW_MAPP_ITEM_TAG} onChange={this.BELOW_MAPP_ITEM_TAG} /> */}

                <Stack>
                  <RadioButton
                    label="True"
                    checked={BELOW_MAPP_ITEM_TAG === "true"}
                    value="true"
                    name="accounts"
                    onChange={(e) => this.BELOW_MAPP_ITEM_TAG("true")}
                  />
                  <RadioButton
                    label="False"
                    value="false"
                    name="accounts"
                    checked={BELOW_MAPP_ITEM_TAG === "false"}
                    onChange={(e) => this.BELOW_MAPP_ITEM_TAG("false")}
                  />
                </Stack>
              </div>
              {/* {
                                notvalidBELOW_MAPP_ITEM_TAG && <div className="inputError"><small> Below MAPP Item Tag not valid.</small></div>
                            } */}
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onupdate}>Submit</Button>
            <Button onClick={this.closemodel}>Close</Button>
          </Modal.Footer>
        </Modal>

        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default ImportPrice;
