import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import SolrSync from "./components/Solr/SolrSync";
import store from "store-js";

function Solr() {
  const currentTab = store.get("solrTab") || 0;
  const [selected, setSelected] = useState(currentTab);

  store.set("solrTab", selected);

  const handleTabChange = useCallback((index) => setSelected(index), []);

  const tabs = [
    {
      id: "solrSync",
      title: "SOLR",
      content: "SOLR Sync",
      page: <SolrSync />,
      panelID: "solrSyncPanel",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default Solr;
