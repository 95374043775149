const ASSIGNMENT_QUEUE_SORT_FIELDS = [
    {
        id: 1,
        title: 'Created Date, Old to New',
        field: 'createdDate',
        order: 'asc'
    },
    {
        id: 2,
        title: 'Created Date, New to Old',
        field: 'createdDate',
        order: 'desc'
    },
    {
        id: 3,
        title: 'Name, A-Z',
        field: 'firstName',
        order: 'asc'
    },
    {
        id: 4,
        title: 'Name, Z-A',
        field: 'lastName',
        order: 'desc'
    },
    {
        id: 5,
        title: 'Email, A-Z',
        field: 'email',
        order: 'asc'
    },
    {
        id: 6,
        title: 'Email, Z-A',
        field: 'email',
        order: 'desc'
    },
    {
        id: 7,
        title: 'Status, A-Z',
        field: 'status',
        order: 'asc'
    },
    {
        id: 8,
        title: 'Status, Z-A',
        field: 'status',
        order: 'desc'
    },
    {
        id: 9,
        title: 'Assigned Date, Old to New',
        field: 'assignedDate',
        order: 'asc'
    },
    {
        id: 10,
        title: 'Assigned Date, New to Old',
        field: 'assignedDate',
        order: 'desc'
    },
    {
        id: 11,
        title: 'Reviewer, A-Z',
        field: 'reviewer',
        order: 'asc'
    },
    {
        id: 12,
        title: 'Reviewer, Z-A',
        field: 'reviewer',
        order: 'desc'
    },
];

const CSR_APPROVAL_SORT_FIELDS = [
    {
        id: 1,
        title: 'Name, A-Z',
        field: 'firstName',
        order: 'asc'
    },
    {
        id: 2,
        title: 'Name, Z-A',
        field: 'lastName',
        order: 'desc'
    },
    {
        id: 3,
        title: 'Email, A-Z',
        field: 'email',
        order: 'asc'
    },
    {
        id: 4,
        title: 'Email, Z-A',
        field: 'email',
        order: 'desc'
    },
    {
        id: 5,
        title: 'Submission Date, Old to New',
        field: 'createdDate',
        order: 'asc'
    },
    {
        id: 6,
        title: 'Submission Date, New to Old',
        field: 'createdDate',
        order: 'desc'
    },
    {
        id: 7,
        title: 'Submission Age, High - Low',
        field: 'age',
        order: 'asc'
    },
    {
        id: 8,
        title: 'Submission Age, Low - High',
        field: 'age',
        order: 'desc'
    },
    {
        id: 9,
        title: 'Status, A-Z',
        field: 'status',
        order: 'asc'
    },
    {
        id: 10,
        title: 'Status, Z-A',
        field: 'status',
        order: 'desc'
    },
    {
        id: 11,
        title: 'Assigned Date, Old to New',
        field: 'assignedDate',
        order: 'asc'
    },
    {
        id: 12,
        title: 'Assigned Date, New to Old',
        field: 'assignedDate',
        order: 'desc'
    },
    {
        id: 13,
        title: 'Address, A-Z',
        field: 'shippingAddress1',
        order: 'asc'
    },
    {
        id: 14,
        title: 'Address, Z-A',
        field: 'shippingAddress1',
        order: 'desc'
    },
    {
        id: 15,
        title: 'Address Used, Low To High',
        field: 'addressUsed',
        order: 'asc'
    },
    {
        id: 16,
        title: 'Address Used, High To Low',
        field: 'addressUsed',
        order: 'desc'
    }
];

const ALL_SUBMISSIONS_SORT_FIELDS = [
    {
        id: 1,
        title: 'Name, A-Z',
        field: 'firstName',
        order: 'asc'
    },
    {
        id: 2,
        title: 'Name, Z-A',
        field: 'lastName',
        order: 'desc'
    },
    {
        id: 3,
        title: 'Email, A-Z',
        field: 'email',
        order: 'asc'
    },
    {
        id: 4,
        title: 'Email, Z-A',
        field: 'email',
        order: 'desc'
    },
    {
        id: 5,
        title: 'Submission Date, Old to New',
        field: 'createdDate',
        order: 'asc'
    },
    {
        id: 6,
        title: 'Submission Date, New to Old',
        field: 'createdDate',
        order: 'desc'
    },
    {
        id: 7,
        title: 'Address, A-Z',
        field: 'shippingAddress1',
        order: 'asc'
    },
    {
        id: 8,
        title: 'Address, Z-A',
        field: 'shippingAddress1',
        order: 'desc'
    },
    {
        id: 9,
        title: 'Address Used, Low To High',
        field: 'addressUsed',
        order: 'asc'
    },
    {
        id: 10,
        title: 'Address Used, High To Low',
        field: 'addressUsed',
        order: 'desc'
    },
    {
        id: 11,
        title: 'Status, A-Z',
        field: 'status',
        order: 'asc'
    },
    {
        id: 12,
        title: 'Status, Z-A',
        field: 'status',
        order: 'desc'
    },

    {
        id: 13,
        title: 'Assignee, A-Z',
        field: 'reviewer',
        order: 'asc'
    },
    {
        id: 14,
        title: 'Assignee, Z-A',
        field: 'reviewer',
        order: 'desc'
    },
    {
        id: 15,
        title: 'Order Number, Low To High',
        field: 'orderNumber',
        order: 'asc'
    },
    {
        id: 16,
        title: 'Order Number, High To Low',
        field: 'orderNumber',
        order: 'desc'
    }
];

const ASSIGNMENT_TYPE = [
    {
        id: 1,
        title: 'Unassigned',
        routePath: 'unAssigned'
    },
    {
        id: 2,
        title: 'Assigned',
        routePath: 'assigned'
    },
    {
        id: 3,
        title: 'On Hold',
        routePath: 'hold'
    },
    {
        id: 4,
        title: 'All',
        routePath: 'assignmentQueue'
    },
]

const PAGE_SIZE = [
    {
        id: 1,
        title: '10',
        field: 'pageSize',
        size: '10'
    },
    {
        id: 2,
        title: ' 20',
        field: 'pageSize',
        size: '20'
    },
    {
        id: 3,
        title: ' 30',
        field: 'pageSize',
        size: '30'
    },
    {
        id: 4,
        title: ' 40',
        field: 'pageSize',
        size: '40'
    },
    {
        id: 5,
        title: '50',
        field: 'pageSize',
        size: '50'
    }
];

const DEFAULT_SORT_QUERY = {
    search: "",
    sortField: "Created Date, Old to New",
    sortOrder: "asc",
    page: 1,
    pageSize: 20,
}

const BANNER_STATUS = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
};

const DEFAULT_INDEX = {
    ASSIGNMENT_QUEUE: {
        PAGE_SIZE: "2",
        SORT_TYPE: "1"
    },
    CRS_APPROVAL: {
        PAGE_SIZE: "1",
        SORT_TYPE: "5"
    },
    ALL_SUBMISSIONS: {
        PAGE_SIZE: "2",
        SORT_TYPE: "5"
    }
}

const SUBMISSION_STATUS = {
    UN_ASSIGNED: "Unassigned",
    ASSIGNED: "Assigned",
    IN_REVIEW: "In Review",
    DENIED: "Denied",
    APPROVED: "Approved",
    ORDER_CREATED: "Order Created",
    SHIPPED: "Shipped",
    DELIVERED: "Delivered",
};

const FILE_TYPE = {
    JPG: "jpg",
    PNG: "png",
    PDF: "pdf",
    UNKNOWN: "unknown",
  };


module.exports = {
    DEFAULT_SORT_QUERY,
    DEFAULT_INDEX,
    ASSIGNMENT_TYPE,
    BANNER_STATUS,
    PAGE_SIZE,
    ASSIGNMENT_QUEUE_SORT_FIELDS,
    ALL_SUBMISSIONS_SORT_FIELDS,
    CSR_APPROVAL_SORT_FIELDS,
    SUBMISSION_STATUS,
    FILE_TYPE
};
