import { useState, useEffect, useRef } from "react";
import { triggerBase64Download } from "react-base64-downloader";
import "./ImageZoomInOut.css";

const ImageZoomInOut = ({ imageBase64, name }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const imageRef = useRef(null);

  const zoomIn = () => {
    setScale((scale) => scale + 0.1);
  };

  const zoomOut = () => {
    setScale((scale) => scale - 0.1);
  };

  const reset = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const download = () => {
    triggerBase64Download(imageBase64, name);
  };

  useEffect(() => {
    const image = imageRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;

      prevPosition = { x: e.clientX, y: e.clientY };

      setPosition((position) => ({
        x: position.x + deltaX,
        y: position.y + deltaY,
      }));
    };

    const handleMouseUp = (e) => {
      isDragging = false;
    };

    image?.addEventListener("mousedown", handleMouseDown);
    image?.addEventListener("mousemove", handleMouseMove);
    image?.addEventListener("mouseup", handleMouseUp);

    return () => {
      image?.removeEventListener("mousedown", handleMouseDown);
      image?.removeEventListener("mousemove", handleMouseMove);
      image?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [imageRef, scale]);

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        position: "relative",
        overflow: "hidden",
        maxHeight: "100%",
      }}
    >
      <div className="btn-container">
        <button onClick={zoomIn}>
          <span class="material-symbols-outlined">add</span>
        </button>
        <button onClick={zoomOut}>
          <span class="material-symbols-outlined">remove</span>
        </button>
        <button onClick={reset}>
          <span class="material-symbols-outlined">history</span>
        </button>
        <button onClick={download}>
          <span class="material-symbols-outlined">download</span>
        </button>
      </div>

      <img
        ref={imageRef}
        src={imageBase64}
        alt=""
        style={{
          width: "40vw",
          height: "auto",
          cursor: "move",
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
        }}
        draggable={false}
      />
    </div>
  );
};

export default ImageZoomInOut;
