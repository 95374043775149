import React, { Component } from "react";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { io } from "socket.io-client";

var XLSX = require("xlsx");

const MODULE = "invites";

class Invited extends Component {
  constructor(props) {
    super(props);

    const socket = io();

    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "Name",
          field: "displayName",
          sortable: true,
          width: 120,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        },
        {
          headerName: "First Name",
          field: "firstName",
          width: 100,
          hide: true,
        },
        {
          headerName: "Last Name",
          field: "lastName",
          width: 100,
          hide: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 150,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "User Type",
          field: "userType",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Invited Date",
          field: "createdAt",
          width: 70,
          cellRenderer: (data) => {
            return moment(data.data.createdAt).format("MM/DD/YYYY");
          },
        },
        {
          headerName: "Resend Invite",
          field: "icon",
          width: 70,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "export btn btn-primary";
            link.innerHTML = '<span style="font-size:14px">Resend</span>';
            link.addEventListener("click", (e) => {
              e.preventDefault();

              const { firstName, lastName, email } = params.data;
              this.setState({
                valid: true,
                firstName,
                lastName,
                email,
              });

              this.sendInvite();
            });
            return link;
          },
        },
        {
          headerName: "Delete",
          field: "icon",
          width: 70,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "export btn btn-primary";
            link.innerHTML = '<span style="font-size:14px">Delete</span>';
            link.addEventListener("click", (e) => {
              e.preventDefault();

              const { customerID } = params.data;
              this.setState({
                id: customerID,
              });

              this.deleteInvite();
            });
            return link;
          },
        },
      ],

      formVisible: false,
      userTypes: [],
      rowData: [],
      hasNextPage: false,
      hasPreviousPage: false,
      total: 0,
      count: 0,
      page: 1,
      pageSize: 10,
      id: "",
      userType: "",
      firstName: "",
      notValidFirstName: false,
      lastName: "",
      notValidLastName: false,
      email: "",
      notValidEmail: false,
      tags: "",
      valid: false,
      loading: false,
      disableSubmit: false,
      status: "",
      error: false,
      selectedRowData: [],
      searchText: "",
      socket: socket,
    };

    this.fileUploadRef = React.createRef();
  }

  getUserTypes = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const url = `/api/invites/userTypes/list`;

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (success) => {
          const { status, msg, list } = success;
          this.updateStatus(!status, msg, true);

          this.setState({
            userTypes: list,
            userType: list && list.length > 0 ? list[0].name : "",
          });
        },

        (error) => {
          console.log(error);
          this.updateStatus(true, "Error loading user types", true);
        }
      );
  };

  getList = (page) => {
    const headers = {
      "Content-Type": "application/json",
    };

    let pg = this.state.page;
    if (page !== undefined) {
      pg = page;
    }

    let url = `/api/invites/list?page=${pg}&pageSize=${this.state.pageSize}`;

    if (this.props.status && this.props.status.length > 0) {
      if (this.props.status === "registered") {
        url = `${url}&registered=1`;
      }
      if (this.props.status === "invited") {
        url = `${url}&registered=0`;
      }
    }

    if (this.state.searchText && this.state.searchText.length > 0) {
      url = `${url}&search=${encodeURIComponent(this.state.searchText)}`;
    }

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (success) => {
          const { status, msg, list, hasPrevious, hasNext, total } = success;
          this.updateStatus(!status, msg, true);

          if (!list || list.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }

          let count = this.state.page * this.state.pageSize;

          if (count > total) {
            count = total;
          }

          this.setState({
            rowData: list,
            hasNextPage: hasNext,
            hasPreviousPage: hasPrevious,
            total,
            count,
          });
        },

        (error) => {
          console.log(error);
          this.hideLoadingOverlay();
          this.updateStatus(true, "Error loading invite log", true);
        }
      );
  };

  componentDidMount() {
    const socket = this.state.socket;

    if (socket) {
      socket.on("connect", () => {
        const data = {
          module: MODULE,
          msg: `Connected to server. Session Id: ${socket.id}`,
          date: moment(new Date()).format("hh:mm:ss"),
          system: true,
        };

        console.log("connect", JSON.stringify(data));
      });

      socket.on("notify", (data) => {
        if (data && data.length > 0) {
          const { msg } = JSON.parse(data);
          this.updateStatus(false, msg, false);
        }
      });

      socket.on("progress", (data) => {
        if (data && data.length > 0) {
          this.getList();
        }
      });

      socket.on("disconnect", () => {
        const data = {
          module: MODULE,
          msg: `Disconnected from server.`,
          date: moment(new Date()).format("hh:mm:ss"),
          system: true,
        };

        console.log("disconnect", data);
      });
    }

    this.getUserTypes();
    this.getList();
  }

  onReady = (customer = null) => {
    this.fileUploadRef.current.value = "";
    this.showLoadingOverlay();
    this.getList();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
  }

  hideLoadingOverlay() {
    this.gridApi.hideOverlay();
  }

  userTypeChanged = (e) => {
    console.log(e.target);
    this.setState({
      userType: e.target.value,
    });

    this.validateForm();
  };

  firstNameChanged = (e) => {
    this.setState({
      notValidFirstName: !e.target.value,
      firstName: e.target.value,
    });

    this.validateForm();
  };

  lastNameChanged = (e) => {
    this.setState({
      notValidLastName: !e.target.value,
      lastName: e.target.value,
    });

    this.validateForm();
  };

  emailChanged = (e) => {
    this.setState({ email: e.target.value });
    this.validateForm();
  };

  validateEmail = (e) => {
    const valid = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g
    ).test(e);
    this.setState({ notValidEmail: !valid });

    this.validateForm();
  };

  tagsChanged = (e) => {
    this.setState({ tags: e.target.value });
  };

  validateForm = (e) => {
    const valid =
      this.state.userType &&
      this.state.userType.length > 0 &&
      this.state.firstName &&
      this.state.firstName.length > 0 &&
      this.state.lastName &&
      this.state.lastName.length > 0 &&
      this.state.email &&
      this.state.email.length > 0;

    this.setState({ valid });
  };

  openForm = () => {
    this.setState({
      formVisible: true,
    });
  };

  closeForm = () => {
    this.reset();
  };

  reset = () => {
    this.setState({
      formVisible: false,
      id: "",
      firstName: "",
      notValidFirstName: false,
      lastName: "",
      notValidLastName: false,
      email: "",
      notValidEmail: false,
      tags: "",
      valid: false,
      loading: false,
      disableSubmit: false,
      selectedRowData: [],
    });
  };

  sendInvite = () => {
    if (!this.state.valid) {
      return;
    }

    const { userType, firstName, lastName, email, tags } = this.state;

    const data = {
      userType,
      firstName,
      lastName,
      email,
    };

    if (tags && tags.length > 0) {
      data.tags = tags;
    }

    const url = `/api/invites/user`;

    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error sending invite", true);
        }
      );

    this.closeForm();
  };

  deleteInvite = () => {
    const { id } = this.state;

    const url = `/api/invites/user/${id}`;

    axios
      .delete(url)
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error deleting invite", true);
        }
      );

    this.closeForm();
  };

  updateStatus = (error, status, clear) => {
    this.setState({
      status,
      error,
    });

    const timeOut = error ? 10000 : 5000;

    if (clear) {
      setTimeout(() => {
        this.setState({ status: "" });
      }, timeOut);
    }
  };

  loadNextPage = () => {
    this.setState({ selectedRowData: [] });

    let page = this.state.page;

    if (this.state.hasNextPage) {
      page += 1;

      this.setState({ page: page });
    }

    this.getList(page);
  };

  loadPreviousPage = () => {
    this.setState({ selectedRowData: [] });

    let page = this.state.page;

    if (this.state.hasPreviousPage) {
      if (page !== 1) {
        page -= 1;
      }

      this.setState({ page: page });
    }

    this.getList(page);
  };

  searchValueChanged = (e) => {
    this.setState({ searchText: e.target.value });
  };

  search = () => {
    this.getList();
  };

  sampleFile = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    const sheetname = "sample_file";
    const worksheet = workbook.addWorksheet("sample_file");

    worksheet.getRow(1).values = [
      "first_name",
      "last_name",
      "email",
      "user_type",
      "tags",
    ];

    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getRow(1).font = {
      bold: true,
      name: "Roboto",
      size: 10,
    };

    worksheet.columns = [
      { key: "first_name", width: 35 },
      { key: "last_name", width: 35 },
      { key: "email", width: 35 },
      { key: "user_type", width: 35 },
      { key: "tags", width: 35 },
    ];

    worksheet.addRow({
      first_name: "first",
      last_name: "last",
      email: "sample@sample.com",
      user_type: "Partner",
      tags: "tag1,tag2,tag3",
    });

    let FileSaver = require("file-saver");

    workbook.xlsx
      .writeBuffer("D:/sample.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
  };

  bulkInvite = async (event) => {
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          this.processExcel(reader.result);
        };
        reader.readAsBinaryString(event.target.files[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  processExcel = (data) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );

    if (excelRows.length > 0) {
      if (excelRows[0]?.email) {
        const list = excelRows.map((item) => {
          return {
            firstName: item.first_name,
            lastName: item.last_name,
            email: item.email,
            userType: item.user_type,
            tags: item.tags,
          };
        });

        this.bulkInviteApi(list);
      } else {
        this.updateStatus(
          true,
          `Excel should have "email" header. Check the sample file.`,
          true
        );
      }
    } else {
      this.updateStatus(true, "No rows found in Excel file", true);
    }
  };

  bulkInviteApi = (list) => {
    const url = `/api/invites/users`;

    axios
      .post(url, { list })
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          this.fileUploadRef.current.value = "";

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error sending invite", true);
        }
      );

    this.reset();
  };

  bulkReInvite = (e) => {
    e.preventDefault();
    this.bulkInviteApi(this.state.selectedRowData);
  };

  bulkDeleteApi = (selected) => {
    const list = selected.map((item) => {
      return {
        id: item.customerID,
        email: item.email,
      };
    });
    const url = `/api/invites/users`;

    axios
      .delete(url, { data: { list } })
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          this.fileUploadRef.current.value = "";

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error sending invite", true);
        }
      );

    this.reset();
  };

  bulkDelete = (e) => {
    e.preventDefault();
    this.bulkDeleteApi(this.state.selectedRowData);
  };

  exportData = (e) => {
    const url = `/api/invites/users/export`;

    axios
      .post(url)
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error exporting users", true);
        }
      );
  };

  render() {
    const {
      userTypes,
      formVisible,
      hasNextPage,
      hasPreviousPage,
      total,
      count,
      firstName,
      notValidFirstName,
      lastName,
      notValidLastName,
      email,
      notValidEmail,
      tags,
      valid,
      disableSubmit,
      status,
      error,
      selectedRowData,
    } = this.state;

    return (
      <div>
        <div class="ml-2 mt-3">
          <div style={{ display: "flex" }}>
            <input
              class="mr-1 searchtinput"
              maxlength="60"
              value={this.state.searchText}
              onKeyPress={(event) => event.key === "Enter" && this.search()}
              onChange={this.searchValueChanged}
            ></input>
            <button
              class="export btn btn-primary mr-2"
              style={{ width: "120px" }}
              onClick={this.search}
            >
              Search
            </button>

            <button
              onClick={this.exportData}
              className="export btn btn-primary mr-2"
              disabled={this.state.rowData.length > 0 ? false : true}
            >
              Export
            </button>

            {status && (
              <div
                class="ml-3 mt-2"
                style={{
                  color: error ? "red" : "green",
                }}
              >
                {status}
              </div>
            )}
          </div>
        </div>

        <div className="ml-2">
          <button
            onClick={this.sampleFile}
            className="export btn btn-primary mr-2"
            style={{ width: "120px" }}
          >
            Sample File
          </button>

          <label
            htmlFor="filePicker"
            className="export btn btn-primary mr-2"
            style={{
              width: "120px",
              marginTop: "5px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Bulk Invite
          </label>

          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="filePicker"
            single
            type="file"
            ref={this.fileUploadRef}
            style={{ visibility: "hidden", width: "0px" }}
            onChange={this.bulkInvite}
          />

          <button
            onClick={this.openForm}
            className="export btn btn-primary mr-2"
          >
            Invite
          </button>

          <button
            onClick={(e) => {
              this.bulkReInvite(e);
            }}
            disabled={selectedRowData?.length > 0 ? false : true}
            className="export btn btn-primary mr-2"
          >
            Resend
          </button>

          <button
            onClick={(e) => {
              this.bulkDelete(e);
            }}
            disabled={selectedRowData?.length > 0 ? false : true}
            className="export btn btn-primary mr-2"
          >
            Delete
          </button>
        </div>
        <Modal
          show={formVisible}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closeForm}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Invite</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="space">
              <div className="text"> User Type</div>
              <select onChange={this.userTypeChanged}>
                {userTypes.map((item) => (
                  <option key={item.userTypeId} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="space">
              <div className="text"> First Name</div>
              <input
                className={notValidFirstName ? "boderred" : ""}
                value={firstName}
                onChange={this.firstNameChanged}
              />
            </div>
            {notValidFirstName && (
              <div className="inputError">
                <small> First Name not valid.</small>
              </div>
            )}
            <div className="space">
              <div className="text"> Last Name</div>
              <input
                className={notValidLastName ? "boderred" : ""}
                value={lastName}
                onChange={this.lastNameChanged}
              />
            </div>
            {notValidLastName && (
              <div className="inputError">
                <small> Last Name not valid.</small>
              </div>
            )}
            <div className="space checkboxc">
              <div className="text"> Email</div>
              <input
                className={notValidEmail ? "boderred" : ""}
                value={email}
                onChange={this.emailChanged}
                onBlur={() => this.validateEmail(this.state.email)}
              />
            </div>
            {notValidEmail && (
              <div className="inputError">
                <small> Email not valid.</small>
              </div>
            )}
            <div className="space checkboxc">
              <div className="text"> Tags</div>
              <input value={tags} onChange={this.tagsChanged} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.sendInvite}
              disabled={!valid || disableSubmit}
            >
              Submit
            </Button>
            <Button onClick={this.closeForm}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "10px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
              rowSelection={"multiple"}
              onSelectionChanged={() => {
                this.setState({
                  selectedRowData: this.gridApi.getSelectedRows(),
                });
              }}
            />
          </div>
        </div>
        <div className="headerbottom">
          {count > 0 && (
            <label className="mt-1 mr-2">
              {count} of {total}
            </label>
          )}
          <button
            class="previous"
            disabled={!hasPreviousPage}
            onClick={() => this.loadPreviousPage()}
          >
            Previous
          </button>
          <button
            class="next"
            disabled={!hasNextPage}
            onClick={() => this.loadNextPage()}
          >
            Next
          </button>
        </div>

        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default Invited;
