import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import CouponsList from "./components/coupon/CouponsList";
import store from "store-js";

export default function Coupons() {
  const currentTab = store.get("CouponsList") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("CouponsList", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "CouponsList",
      title: "Coupons  List",
      content: "Coupons  List",
      page: <CouponsList />,
      panelID: "CouponsList",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
