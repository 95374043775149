import React, { Component } from "react";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

class UserTypes extends Component {
  constructor(props) {
    super(props);

    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.state = {
      modules: AllCommunityModules,
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "ID",
          field: "userTypeID",
          width: 120,
          hide: true,
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          width: 120,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Tags",
          field: "tags",
          sortable: true,
          width: 150,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Edit",
          field: "icon",
          width: 50,
          cellRenderer: (params) => {
            const { userTypeID, name, tags, readOnly } = params.data;

            const link = document.createElement("button");
            link.className = "export btn btn-primary";
            link.innerHTML = '<span style="font-size:14px">Edit</span>';
            link.disabled = readOnly;
            link.addEventListener("click", (e) => {
              e.preventDefault();

              this.setState({
                mode: "Edit",
                formVisible: true,
                id: userTypeID,
                name,
                tags,
              });

              this.validateForm();
            });
            return link;
          },
        },
        {
          headerName: "Delete",
          field: "icon",
          width: 50,
          cellRenderer: (params) => {
            const { userTypeID, name, tags, readOnly } = params.data;

            const link = document.createElement("button");
            link.className = "export btn btn-primary";
            link.innerHTML = '<span style="font-size:14px">Delete</span>';
            link.disabled = readOnly;
            link.addEventListener("click", (e) => {
              e.preventDefault();

              this.setState({
                mode: "Delete",
                formVisible: true,
                id: userTypeID,
                name,
                tags,
              });

              this.validateForm();
            });
            return link;
          },
        },
      ],

      formVisible: false,
      mode: "",
      rowData: [],
      hasNextPage: false,
      hasPreviousPage: false,
      total: 0,
      page: 1,
      pageSize: 10,
      id: "",
      name: "",
      notValidName: false,
      tags: "",
      valid: false,
      loading: false,
      disableSubmit: false,
      status: "",
      error: false,
      searchText: "",
    };
  }

  getList = (page) => {
    const headers = {
      "Content-Type": "application/json",
    };

    let pg = this.state.page;
    if (page !== undefined) {
      pg = page;
    }

    let url = `/api/invites/userTypes/pages?page=${pg}&pageSize=${this.state.pageSize}`;

    if (this.props.status && this.props.status.length > 0) {
      if (this.props.status === "registered") {
        url = `${url}&registered=1`;
      }
      if (this.props.status === "invited") {
        url = `${url}&registered=0`;
      }
    }

    if (this.state.searchText && this.state.searchText.length > 0) {
      url = `${url}&search=${encodeURIComponent(this.state.searchText)}`;
    }

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => response.data)
      .then(
        (success) => {
          const { status, msg, list, hasPrevious, hasNext, total } = success;
          this.updateStatus(!status, msg, true);

          if (!list || list.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }

          let count = this.state.page * this.state.pageSize;

          if (count > total) {
            count = total;
          }

          if (!list || list.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }

          this.setState({
            rowData: list,
            hasNextPage: hasNext,
            hasPreviousPage: hasPrevious,
            total,
            count,
          });
        },

        (error) => {
          console.log(error);
          this.hideLoadingOverlay();
          this.updateStatus(true, "Error loading user types", true);
        }
      );
  };

  componentDidMount() {
    this.getList();
  }

  onReady = (customer = null) => {
    this.showLoadingOverlay();
    this.getList();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
  }

  hideLoadingOverlay() {
    this.gridApi.hideOverlay();
  }

  nameChanged = (e) => {
    this.setState({
      notValidName: !e.target.value,
      name: e.target.value,
    });

    this.validateForm();
  };

  tagsChanged = (e) => {
    this.setState({ tags: e.target.value });
  };

  validateForm = (e) => {
    const valid = this.state.name && this.state.name.length > 0;
    this.setState({ valid });
  };

  openForm = () => {
    this.setState({
      mode: "Add",
      formVisible: true,
    });
  };

  closeForm = () => {
    this.reset();
  };

  reset = () => {
    this.setState({
      formVisible: false,
      mode: "",
      id: "",
      name: "",
      notValidName: false,
      tags: "",
      valid: false,
      loading: false,
      disableSubmit: false,
    });
  };

  submit = () => {
    if (this.state.mode === "Add") {
      this.addUserType();
    } else if (this.state.mode === "Edit") {
      this.editUserType();
    } else if (this.state.mode === "Delete") {
      this.deleteUserType();
    }
  };

  addUserType = () => {
    if (!this.state.valid) {
      return;
    }

    const { name, tags } = this.state;

    const data = {
      name,
    };

    if (tags && tags.length > 0) {
      data.tags = tags;
    }

    this.setState({ disableSubmit: true });

    const url = `/api/invites/userType`;

    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error creating user type", true);
        }
      );

    this.closeForm();
  };

  editUserType = () => {
    if (!this.state.valid) {
      return;
    }

    const { id, name, tags } = this.state;

    const data = {
      name,
    };

    if (tags && tags.length > 0) {
      data.tags = tags;
    }

    this.setState({ disableSubmit: true });

    const url = `/api/invites/userType/${id}`;

    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error updating user type", true);
        }
      );

    this.closeForm();
  };

  deleteUserType = () => {
    if (!this.state.valid) {
      return;
    }

    const { id } = this.state;

    this.setState({ disableSubmit: true });

    const url = `/api/invites/userType/${id}`;

    axios
      .delete(url)
      .then((response) => response.data)
      .then(
        (result) => {
          const { status, msg } = result;
          this.updateStatus(!status, msg, false);

          setTimeout(() => {
            this.getList();
          }, 2000);
        },
        (err) => {
          console.log(err);
          this.updateStatus(true, "Error deleting user type", true);
        }
      );

    this.closeForm();
  };

  updateStatus = (error, status, clear) => {
    this.setState({
      status,
      error,
    });

    const timeOut = error ? 10000 : 5000;

    if (clear) {
      setTimeout(() => {
        this.setState({ status: "" });
      }, timeOut);
    }
  };

  loadNextPage = () => {
    let page = this.state.page;

    if (this.state.hasNextPage) {
      page += 1;

      this.setState({ page: page });
    }

    this.getList(page);
  };

  loadPreviousPage = () => {
    let page = this.state.page;

    if (this.state.hasPreviousPage) {
      if (page !== 1) {
        page -= 1;
      }

      this.setState({ page: page });
    }

    this.getList(page);
  };

  searchValueChanged = (e) => {
    this.setState({ searchText: e.target.value });
  };

  search = () => {
    this.getList();
  };

  render() {
    const {
      formVisible,
      hasNextPage,
      hasPreviousPage,
      total,
      count,
      id,
      name,
      notValidName,
      tags,
      valid,
      disableSubmit,
      status,
      error,
    } = this.state;

    return (
      <div>
        <div class="ml-2 mt-3">
          <div style={{ display: "flex" }}>
            <input
              class="mr-1 searchtinput"
              maxlength="60"
              value={this.state.searchText}
              onKeyPress={(event) => event.key === "Enter" && this.search()}
              onChange={this.searchValueChanged}
            ></input>
            <button
              class="export btn btn-primary mr-2"
              style={{ width: "120px" }}
              onClick={this.search}
            >
              Search
            </button>

            <button
              onClick={this.openForm}
              className="export btn btn-primary mr-2"
            >
              Add New
            </button>

            {status && (
              <div
                class="ml-3 mt-2"
                style={{
                  color: error ? "red" : "green",
                }}
              >
                {status}
              </div>
            )}
          </div>
        </div>

        <Modal
          show={formVisible}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closeForm}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.mode} User Type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(this.state.mode === "Edit" || this.state.mode === "Delete") && (
              <div className="space">
                <div className="text">ID</div>
                <input
                  className={notValidName ? "boderred" : ""}
                  value={id}
                  disabled
                />
              </div>
            )}
            <div className="space">
              <div className="text">User Type</div>
              <input
                className={notValidName ? "boderred" : ""}
                value={name}
                onChange={this.nameChanged}
                disabled={this.state.mode === "Delete"}
              />
            </div>
            {notValidName && (
              <div className="inputError">
                <small>User Type not valid.</small>
              </div>
            )}
            <div className="space checkboxc">
              <div className="text"> Tags</div>
              <input
                value={tags}
                maxlength="500"
                onChange={this.tagsChanged}
                disabled={this.state.mode === "Delete"}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submit} disabled={!valid || disableSubmit}>
              Submit
            </Button>
            <Button onClick={this.closeForm}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "10px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
            />
          </div>
        </div>
        <div className="headerbottom">
          {count > 0 && (
            <label className="mt-1 mr-2">
              {count} of {total}
            </label>
          )}
          <button
            class="previous"
            disabled={!hasPreviousPage}
            onClick={() => this.loadPreviousPage()}
          >
            Previous
          </button>
          <button
            class="next"
            disabled={!hasNextPage}
            onClick={() => this.loadNextPage()}
          >
            Next
          </button>
        </div>

        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default UserTypes;
