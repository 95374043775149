import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

// import PartnersInvite from './components/partnersusers/partnersinvited';
// import PartnersRegister from './components/partnersusers/partnersregistered';
import ImportPrice from "./components/bulkprice/importprice";
// import ActivePrice from './components/bulkprice/activeprice';
import store from "store-js";

export default function BulkPrice({ customerId }) {
  const currentTab = store.get("bulkPriceTab") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("bulkPriceTab", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "ImportPrice",
      title: "Import Price",
      content: "Import Price",
      page: <ImportPrice customerId={customerId} />,
      panelID: "ImportPrice",
    },
    // {
    //     id: 'ActivePrice',
    //     title: 'Active Price',
    //     content: 'Active Price',
    //     page: <ActivePrice />,
    //     panelID: 'ActivePrice',
    // }
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
