import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import List from "./components/Events/List";
import store from "store-js";

export default function Events() {
  const currentTab = store.get("eventTab") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("eventTab", selected);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Events",
      title: "Events",
      content: "Events",
      page: <List currentEventTab="Events" />,
      panelID: "Events",
    },
    {
      id: "Published",
      title: "Published",
      content: "Published",
      page: <List currentEventTab="Published" />,
      panelID: "Published",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].id} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
