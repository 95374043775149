import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

class SalsifyDisplayNameList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 50,
      headerHeight: 60,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "Meta Key",
          field: "key",
          width: 50,
        },
        {
          headerName: "Meta Value",
          field: "value",
          editable: true,
          width: 50,
        },
      ],
      metaFieldId: "",
      defaultColDef: {
        resizable: true,
        domLayout: "autoHeight",
      },
      rowData: [],
      loading: false,
      showMessage: false,
      isSave: false,
      alertMsg: "",
    };
  }

  componentDidMount() {
    this.getMetaFieldList();
  }

  getMetaFieldList = () => {
    axios
      .get("/api/salsifyDisplayName/list")
      .then((response) => response.data)
      .then(
        (result) => {
          if (result.data?.length) {
            this.gridApi.showNoRowsOverlay();
            this.gridApi.setRowData(result.data);
            this.setState({
              rowData: result.data,
              metaFieldId: result.metaFieldId,
            });
          } else {
            this.gridApi.showNoRowsOverlay();
            this.gridApi.setRowData([]);
            this.setState({
              rowData: [],
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  updateMetaFieldList = () => {
    this.setState({
      isSave: true,
    });
    const { rowData, metaFieldId } = this.state;
    const data = {};
    const filter = rowData
      .filter((i) => i.value !== "")
      .map((i) => {
        return { key: i.key, value: i.value?.trim() };
      })
      .filter((i) => i.value !== undefined);
    data.updateList = filter;
    data.id = metaFieldId;
    axios
      .put("/api/salsifyDisplayName/list", data)
      .then((response) => response.data)
      .then(
        (result) => {
          if (result.success) {
            this.setState({
              alertMsg: "success",
            });
            setTimeout(() => {
              this.setState({
                alertMsg: "",
              });
            }, 1500);
          } else {
            this.setState({
              alertMsg: "error",
            });
            setTimeout(() => {
              this.setState({
                alertMsg: "",
              });
            }, 1500);
          }
          this.setState({
            isSave: false,
          });
        },
        (error) => {
          this.setState({
            alertMsg: "error",
            isSave: false,
          });
          setTimeout(() => {
            this.setState({
              alertMsg: "",
            });
          }, 1500);
        }
      );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();

    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();
    }
    // this.gridApi.setDomLayout("autoHeight");
    // document.querySelector("#myGrid").style.height = "";
  };

  render() {
    return (
      <div className="salsifyDisplayNameWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "10px",
          }}
        >
          <div
            style={{
              color: this.state.alertMsg === "success" ? "green" : "red",
              padding: "5px 10px 0 0",
              fontSize: "15px",
            }}
          >
            {this.state.alertMsg === "success" && "Updated Successfully"}
            {this.state.alertMsg === "error" && "Failed to Update Records"}
          </div>

          <button
            onClick={this.updateMetaFieldList}
            className="export btn btn-primary"
            style={{ fontSize: "14px" }}
            disabled={this.state.rowData.length === 0 || this.state.isSave}
          >
            {this.state.isSave ? <Spinner animation="border" /> : "Save"}
          </button>
        </div>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "80vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
              stopEditingWhenCellsLoseFocus={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default SalsifyDisplayNameList;
