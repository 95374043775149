import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import "bootstrap/dist/css/bootstrap.min.css";

import { controlStyles, custom } from "./constants";

class FilterUI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter,
    };
  }

  componentDidMount() {}

  updateOverwrite = async (e) => {
    const filter = this.state.filter;
    filter.overwrite = e.target.value;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateShow = async (e) => {
    const filter = this.state.filter;
    filter.show = e.target.checked;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateOrder = async (e) => {
    const filter = this.state.filter;

    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value)) {
        filter.order = parseInt(e.target.value);
      }

      this.setState({
        filter: filter,
      });

      this.props.filterChanged(filter);
    } catch (err) {}
  };

  updateStyle = async (e) => {
    const filter = this.state.filter;
    filter.style = e.target.value;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateCounts = async (e) => {
    const filter = this.state.filter;
    filter.counts = e.target.checked;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateExpand = async (e) => {
    const filter = this.state.filter;
    filter.expand = e.target.checked;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateCustom = async (e) => {
    const filter = this.state.filter;
    filter.custom = e.target.value;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateParams = async (e) => {
    const filter = this.state.filter;
    filter.params = e.target.value;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateCustomRange = async (e) => {
    const filter = this.state.filter;
    filter.customRange = e.target.checked;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateCustomRangeButton = async (e) => {
    const filter = this.state.filter;
    filter.customRangeButton = e.target.value;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateClear = async (e) => {
    const filter = this.state.filter;
    filter.clear = e.target.value;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  updateOptionFinder = async (e) => {
    const filter = this.state.filter;
    filter.optionFinder = e.target.checked;

    this.setState({
      filter: filter,
    });

    this.props.filterChanged(filter);
  };

  render() {
    return (
      <Container className="p-2">
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Filter:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Label>{this.state.filter.name}</Form.Label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Overwrite:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              value={this.state.filter.overwrite}
              onChange={this.updateOverwrite}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                checked={this.state.filter.show}
                onClick={this.updateShow}
              />{" "}
              Show
            </label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Order:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              style={{
                maxWidth: "60px",
                border: "1px solid black",
                fontSize: "14px",
              }}
              required
              type="number"
              maxLength="3"
              value={this.state.filter.order}
              onChange={this.updateOrder}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Control Style:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              as="select"
              style={{
                maxWidth: "200px",
                fontSize: "14px",
              }}
              value={this.state.filter.style}
              onChange={this.updateStyle}
            >
              {controlStyles &&
                controlStyles.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                checked={this.state.filter.counts}
                onClick={this.updateCounts}
              />{" "}
              Show Counts
            </label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                checked={this.state.filter.expand}
                onClick={this.updateExpand}
              />{" "}
              Expand Filters
            </label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Custom:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              as="select"
              style={{
                maxWidth: "200px",
                fontSize: "14px",
              }}
              disabled={this.state.filter.style !== "Custom"}
              value={this.state.filter.custom}
              onChange={this.updateCustom}
            >
              {custom &&
                custom.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Params:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                minWidth: "100%",
                fontSize: "14px",
              }}
              disabled={this.state.filter.style !== "Custom"}
              value={this.state.filter.params}
              onChange={this.updateParams}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                disabled={this.state.filter.custom !== "Range"}
                checked={this.state.filter.customRange}
                onClick={this.updateCustomRange}
              />{" "}
              Custom Range
            </label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">
              Custom Range button caption:
            </Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                maxWidth: "150px",
                fontSize: "14px",
              }}
              disabled={!this.state.filter.customRange}
              value={this.state.filter.customRangeButton}
              onChange={this.updateCustomRangeButton}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <Form.Label className="mt-2">Reset button caption:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              size="lg"
              style={{
                maxWidth: "150px",
                fontSize: "14px",
              }}
              value={this.state.filter.clear}
              onChange={this.updateClear}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                disabled={this.state.filter.style === "Custom"}
                checked={this.state.filter.optionFinder}
                onClick={this.updateOptionFinder}
              />{" "}
              Show Option Finder
            </label>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FilterUI;
