import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import "bootstrap/dist/css/bootstrap.min.css";

import CheckboxRenderer from "../Common/CheckboxRenderer.jsx";

const COLUMNS = {
  ACTIVE: "active",
  DELIMITER: "delimiter",
};

class SalsifyConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "Active",
          field: "active",
          cellRenderer: "checkboxRenderer",
          width: 50,
          sortable: true,
        },
        {
          headerName: "Metafield",
          field: "field",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Delimiter for multiple items",
          field: "delimiter",
          editable: true,
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      frameworkComponents: {
        checkboxRenderer: CheckboxRenderer,
      },
      rowData: [],
      loading: false,
      statusError: false,
      statusText: "",
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = async () => {
    const url = `/api/salsify/getFields`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const activeList = data.filter((item) => item.active);

        this.gridApi.setRowData(data);

        let status = [];

        if (data.length > 0) {
          status.push(
            `${data.length} metafield${data.length === 1 ? "" : "s"} loaded.`
          );
        }

        if (activeList.length > 0) {
          status.push(`${activeList.length} activated for monitoring.`);
        } else {
          status.push(`No activated for monitoring metafields found.`);
        }

        this.setState({
          rowData: data,
          statusError: false,
          statusText: status.join(" "),
        });
      } else {
        this.gridApi.showNoRowsOverlay();
        this.gridApi.setRowData([]);

        this.setState({
          rowData: [],
          statusError: false,
          statusText: `No metafields found.`,
        });
      }
    } catch (err) {
      console.log(err);

      this.gridApi.showNoRowsOverlay();
      this.gridApi.setRowData([]);

      this.setState({
        rowData: [],
        statusError: true,
        statusText: `Error getting metafields: ${err}`,
      });
    }
  };

  selectAllFields = async (e) => {
    e.preventDefault();

    await this.updateAllFields(true);
    this.getList();
  };

  unSelectAllFields = async (e) => {
    e.preventDefault();

    await this.updateAllFields(false);
    this.getList();
  };

  updateAllFields = async (active) => {
    this.setState({
      loading: true,
    });

    const url = `/api/salsify/selectAllFields`;
    // RM stringify
    const data = {
      active: active,
    };

    try {
      await axios.post(url, data);

      let status = "";

      if (active) {
        status = `Metafield activated for monitoring`;
      } else {
        status = `Metafield removed from monitoring`;
      }

      this.setState({
        statusError: false,
        statusText: status,
      });
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating metafields configuration: ${err}`,
      });
    }

    this.setState({
      loading: false,
    });
  };

  generateReport = async (e) => {
    const url = `/api/salsify/report`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      await axios.get(url, { headers: headers });

      this.setState({
        statusError: false,
        statusText: `Request for generating report is submitted. Email will be send on completion.`,
      });
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error generating report: ${err}`,
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
  };

  onCellValueChanged = async (params) => {
    const colId = params.column.colId;

    const field = params.data.field;
    let active = params.data.active;
    let delimiter = params.data.delimiter;

    if (colId === COLUMNS.ACTIVE) {
      active = params.value;
    } else if (colId === COLUMNS.DELIMITER) {
      delimiter = params.value;
    }

    const response = await this.updateField(field, active, delimiter);

    if (response) {
      const changes = [params.node.data];
      params.api.applyTransaction({ update: changes });
    } else {
      const item = params.node.data;

      if (colId === COLUMNS.ACTIVE) {
        item.active = params.oldValue;
      } else if (colId === COLUMNS.DELIMITER) {
        item.delimiter = params.oldValue;
      }

      const changes = [item];
      params.api.applyTransaction({ update: changes });
    }
  };

  updateField = async (field, active, delimiter) => {
    const url = "/api/salsify/updateField";

    // RM stringify

    const data = {
      field: field,
      active: active,
      delimiter: delimiter,
    };

    try {
      const response = await axios.post(url, data);

      if (response.status === 200) {
        this.setState({
          statusError: false,
          statusText: response.data,
        });

        return true;
      }
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error updating ${field}: ${err}`,
      });
    }

    return false;
  };

  render() {
    return (
      <div className="salsifyDisplayNameWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "10px",
          }}
        >
          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              padding: "5px 10px 0 0",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>

          <button
            onClick={this.selectAllFields}
            className="export btn btn-primary"
            style={{ fontSize: "14px", width: "200px", marginRight: "5px" }}
          >
            Select All Fields and Save
          </button>
          <button
            onClick={this.unSelectAllFields}
            className="export btn btn-primary"
            style={{ fontSize: "14px", width: "200px", marginRight: "5px" }}
          >
            Unselect All Fields and Save
          </button>
          <button
            onClick={this.generateReport}
            className="export btn btn-primary"
            style={{ fontSize: "14px", width: "150px" }}
          >
            Generate Report
          </button>
        </div>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "80vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default SalsifyConfig;
