import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import Setup from "./components/Giveaways/Setup";
import Report from "./components/Giveaways/Report";

function Giveaways() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((index) => setSelected(index), []);

  const tabs = [
    {
      id: "setup",
      title: "Giveaways",
      content: "Manage Giveaways",
      page: <Setup />,
      panelID: "setupConfigPanel",
    },
    {
      id: "report",
      title: "Giveaways",
      content: "Report",
      page: <Report />,
      panelID: "reportConfigPanel",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}

export default Giveaways;
