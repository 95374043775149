import React, { Component } from "react";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import "bootstrap/dist/css/bootstrap.min.css";

import CheckboxRenderer from "../Common/CheckboxRenderer.jsx";

class SolrOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "Copy",
          field: "copy",
          cellRenderer: "checkboxRenderer",
          width: 50,
          sortable: true,
        },
        {
          headerName: "Name",
          field: "title",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      frameworkComponents: {
        checkboxRenderer: CheckboxRenderer,
      },
      rowData: props.collections,
      loading: false,
      statusError: false,
      statusText: "",
    };
  }

  componentDidMount() {}

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onCellValueChanged = async (params) => {
    this.props.collectionsChanged(this.state.rowData);
  };

  render() {
    return (
      <div className="pageWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 100px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "70vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              onCellValueChanged={this.onCellValueChanged.bind(this)}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default SolrOptions;
