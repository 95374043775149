import React, { Component } from "react";
import axios from "../../../axiosInstance";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-date-picker";
import moment from "moment";
var XLSX = require("xlsx");

class National extends Component {
  constructor(props) {
    super(props);
    

    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };
    this.state = {
      modules: AllCommunityModules,
      value: "National",
      result: { ids: [], removeTag: {}, addTag: {} },
      rowHeight: 45,
      headerHeight: 45,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loaderr"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs2: [
        {
          headerName: "Name",
          field: "displayName",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Tags",
          field: "tags",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Create Date",
          field: "createdAt",
          width: 70,
          cellRenderer: (data) => {
            return moment(data.data.createdAt).format("MM/DD/YYYY");
          },
        },
        {
          headerName: "National Partner",
          field: "nationalPartner",
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        // { headerName: 'CRM ID', field: 'crm_id', sortable: true, width: 70, sortingOrder: ['asc', 'desc'], },
        // { headerName: 'AS400 ID', field: 'as400_id', sortable: true, width: 70, sortingOrder: ['asc', 'desc'], },
        {
          headerName: "Email Policy Consent",
          field: "profile_email_policy_consent",
          cellRenderer: (params) => {
            const text = params.value == "1" ? "Yes" : "No";
            return `${text}`;
          },
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Privacy Policy Consent",
          field: "profile_privacy_policy_consent",
          cellRenderer: (params) => {
            const text = params.value == "1" ? "Yes" : "No";
            return `${text}`;
          },
          sortable: true,
          width: 70,
          sortingOrder: ["asc", "desc"],
          comparator: customComparator,
        },
        {
          headerName: "Edit",
          field: "icon",
          width: 35,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            link.className = "btncss btn btn-primary";
            link.innerHTML = '<i class="fa fa-pencil"> Edit</i>';
            link.addEventListener("click", (e) => {
              e.preventDefault();

              let privacy = false;
              if (params.data.profile_privacy_policy_consent) {
                privacy = params.data.profile_privacy_policy_consent === "1";
              }

              let email = false;
              if (params.data.profile_email_policy_consent) {
                email = params.data.profile_email_policy_consent === "1";
              }

              this.setState({
                profile_crm_id: params.data.profile_crm_id
                  ? params.data.profile_crm_id
                  : "",
                profile_as400_id: params.data.profile_as400_id
                  ? params.data.profile_as400_id
                  : "",
                profile_privacy_policy_consent: privacy,
                profile_email_policy_consent: email,
                id: params.data.id,
                name: params.data.displayName,
              });

              this.Popup();
            });
            return link;
          },
        },
      ],

      defaultColDef: {
        resizable: true,
        domLayout: "autoHeight",
      },
      openmodel: false,
      fullname: "",
      rowData: [],
      pageData: [],
      paginationPageSize: 25,
      showMessage: false,
      profile_crm_id: "",
      profile_as400_id: "",
      profile_privacy_policy_consent: false,
      profile_email_policy_consent: false,
      id: "",
      name: "",
      updateresult: {
        id: {},
        crm_id: {},
        as400_id: {},
        privacy_policy_consent: {},
        email_policy_consent: {},
      },
      // search
      searchText: "",
      searchValue: "",
      //  searchDate: null,
      //  searchType:null,
      rowDataExcel: [],
    };
  }

  componentDidMount() {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    const url = `/api/nationalpartners`;
    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          console.log(this.state.rowData);
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },

        (error) => {
          console.log(error);
        }
      );
  }

  // eslint-disable-next-line no-undef
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    const gridWidth = document.getElementById("myGrid").offsetWidth;
    if (gridWidth < 500) {
      const allColIds = this.gridColumnApi
        .getAllColumns()
        .map((column) => column.colId);
      this.gridColumnApi.autoSizeColumns(allColIds);
      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.gridApi.setDomLayout("autoHeight");
    document.querySelector("#myGrid").style.height = "";
  };

  onBtNext = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    const url = `/api/nationalpartners?direction=next&last_id=${this.state.pageData.nextCursor}`;
    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  onBtPrevious = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    const url = `/api/nationalpartners?direction=previous&last_id=${this.state.pageData.previousCursor}`;
    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  onReady = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    const url = `/api/nationalpartners`;
    axios
      .post(url, data)
      .then((response) => response.data, {
        headers: { "Cache-Control": "no-cache" },
      })
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },

        (error) => {
          console.log("err", error);
        }
      );
  };

  // eslint-disable-next-line no-undef
  onSelectionChanged = (data) => {
    this.setState(
      {
        result: {
          ids: data,
          removeTag: this.state.value,
          addTag: data.Status,
        },
      },
      () => this.connected()
    );
  };

  connected = (params) => {
    let payload = {
      excelData: params,
    };

    const url = `/api/nationalpartner`;
    axios
      .post(url, payload)
      .then((response) => response.data)
      .then(
        (result) => {
          this.setState({ showMessage: true }, () =>
            setTimeout(() => {
              window.location.reload(false);
            }, 3000)
          );
        },
        (err) => {
          console.log(err);
        }
      );
  };

  changeHandler = async (event) => {
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          this.processExcel(reader.result);
        };
        reader.readAsBinaryString(event.target.files[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  processExcel = (data) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    this.connected(excelRows);
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  Popup = () => {
    this.setState({
      openmodel: true,
    });
  };

  closemodel = () => {
    this.setState({
      openmodel: false,
      id: "",
      loading: false,
      profile_crm_id: "",
      profile_as400_id: "",
      profile_privacy_policy_consent: false,
      profile_email_policy_consent: false,
      name: "",
    });
  };

  crm = (e) => {
    this.setState({ profile_crm_id: e.target.value });
  };

  as400 = (e) => {
    this.setState({ profile_as400_id: e.target.value });
  };

  emailOnChange = (e) => {
    this.setState({ profile_email_policy_consent: e.target.checked });
  };

  privacyOnChange = (e) => {
    this.setState({ profile_privacy_policy_consent: e.target.checked });
  };

  onupdate = () => {
    this.setState(
      {
        updateresult: {
          id: this.state.id,
          crm_id: this.state.profile_crm_id,
          as400_id: this.state.profile_as400_id,
          privacy_policy_consent: this.state.profile_privacy_policy_consent,
          email_policy_consent: this.state.profile_email_policy_consent,
        },
        loading: true,
      },
      () => this.onupdateresult()
    );
  };

  onupdateresult = () => {
    var data = this.state.updateresult;
    // console.log(data);

    const url = `/api/nationalpartner`;
    axios
      .put(url, data)
      .then((response) => response.id)
      .then(
        (result) => {
          setTimeout(() => {
            this.onReady();
            this.closemodel();
          }, 2000);
        },
        (err) => {
          console.log(err);
        }
      );
  };

  // search
  onSearch = () => {
    console.log(this.state.searchType);
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    const url = `/api/nationalpartners`;
    axios
      .post(url, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState({
            rowData: sucess.rows,
            pageData: sucess,
            id: "",
          });
          if (sucess.rows.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  onSearchData = (e) => {
    if (e.target.value === "" || e.target.value) {
      this.parseDate(e.target.value);
      this.setState({ searchText: e.target.value });
    }
  };

  // onDateSearch = (e) => {
  //   this.setState({
  //     searchDate: e,
  //     searchType: e != null ? moment(e).format('DD-MM-YYYY'): null
  //   }, () => this.onSearch())
  // }

  parseDate(input) {
    const parsedDate = moment(input, "MM/DD/YYYY");
    if (input.length == 10 && parsedDate.isValid()) {
      this.setState({
        searchType: "Date",
        searchValue: moment(parsedDate).format("DD-MM-YYYY"),
      });
    } else {
      this.setState({
        searchType: "Email",
        searchValue: input,
      });
    }
  }
  //export excel
  exportexcelrow = () => {
    let data = {
      searchValue: this.state.searchValue,
      searchType: this.state.searchType,
    };

    axios
      .post(`/api/excelexport/nationalpartners`, data)
      .then((response) => response.data)
      .then(
        (sucess) => {
          this.setState(
            {
              rowDataExcel: sucess.data,
            },
            () => this.exportexcel()
          );
          if (sucess.data.length === 0) {
            this.gridApi.showNoRowsOverlay();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  exportexcel = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    // const {rowDataExcel,startDate,endDate}=this.state;
    const { rowDataExcel } = this.state;
    const sheetname =
      "National_Partners_Report_" + moment().format("MM_DD_YYYY_ h:mm:ss_A");
    const worksheet = workbook.addWorksheet("National_Partners_Report_1");
    worksheet.mergeCells("A1", "G1");
    worksheet.getCell("A1,B1,C1,D1,E1,F1,G1").value =
      "National Partners Report";
    worksheet.getCell("A1,B1,C1,D1,E1,F1,G1").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A1,B1,C1,D1,E1,F1,G1").font = {
      bold: true,
      name: "Roboto",
      size: 12,
    };
    // worksheet.getCell('A3').value = 'From-Date ';
    // worksheet.getCell('A3').alignment = { horizontal: 'left' };
    // worksheet.getCell('A3').font = { bold: true, name: 'Roboto', size: 10 };
    // worksheet.getCell('B3').value = moment(startDate).format('MM/DD/YYYY');
    // worksheet.getCell('B3').alignment = { vertical: 'bottom', horizontal: 'left' };
    // worksheet.getCell('A4').value = 'To-Date ';
    // worksheet.getCell('A4').alignment = { horizontal: 'left' };
    // worksheet.getCell('A4').font = { bold: true, name: 'Roboto', size: 10 };
    // worksheet.getCell('B4').value = moment(endDate).format('MM/DD/YYYY');
    // worksheet.getCell('B4').alignment = { vertical: 'bottom', horizontal: 'left' };

    worksheet.getCell("A5").value = "Created on";
    worksheet.getCell("A5").alignment = { horizontal: "left" };
    worksheet.getCell("A5").font = { bold: true, name: "Roboto", size: 10 };
    worksheet.getCell("B5").value = moment().format("MM/DD/YYYY h:mm:ss A ");
    worksheet.getCell("B5").alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    // worksheet.addRow({"":''})
    worksheet.getRow(9).values = [
      "Name",
      "Email",
      "Tags",
      "Created Date",
      "National Partner",
      "Email Policy Consent",
      "Privacy Policy Consent",
    ];
    worksheet.columns.forEach((column) => {
      column.width = 25;
    });
    worksheet.getRow(9).font = {
      bold: true,
      name: "Roboto",
      size: 10,
    };
    worksheet.columns = [
      { key: "Name", width: 35 },
      { key: "Email", width: 35 },
      { key: "Tags", width: 35 },
      { key: "Created Date", width: 35 },
      { key: "National Partner", width: 35 },
      { key: "Email Policy Consent", width: 35 },
      { key: "Privacy Policy Consent", width: 35 },
    ];

    var row_count = 9;
    rowDataExcel.forEach((e, index) => {
      row_count++;
      let fliternp = e.tags.filter((tag) =>
        tag.toLowerCase().startsWith("np:")
      );
      let tagnp = "";
      if (fliternp.length > 0) {
        tagnp = fliternp[0].split(":")[1] || "";
      }
      worksheet.addRow({
        Name: e.displayName != null ? e.displayName : "",
        Email: e.email != null ? e.email : "",
        Tags: e.tags.join(", "),
        "Created Date": moment(e.createdAt).format("MM/DD/YYYY"),
        "National Partner": tagnp,
        "Email Policy Consent": e.acceptsMarketing ? "Yes" : "No",
        "Privacy Policy Consent":
          e.privacy_policy_consent != null
            ? e.privacy_policy_consent.value == "1"
              ? "Yes"
              : "No"
            : "No",
      });
    });
    const insideColumns = ["A", "B", "C", "D", "E", "F", "G"]; // 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U','V','V','W' ]
    var last_row_count = 1;
    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      last_row_count++;
      if (rowNumber !== 9 && rowNumber > 9) {
        insideColumns.forEach((c) => {
          worksheet.getCell(`${c}${row_count}`).border = {
            top: { style: "none" },
            left: { style: "none" },
            bottom: { style: "thin" },
            right: { style: "none" },
          };
          // if(c!=='B'){
          worksheet.getCell(`${c}${rowNumber}`).alignment = {
            horizontal: "left",
          };
          //  }
          if (c === "V") {
            worksheet.getCell(`V${rowNumber}`).border = {
              top: { style: "none" },
              bottom: { style: "none" },
              left: { style: "none" },
              right: { style: "thin" },
            };
            if (`V${row_count + 1}`) {
              worksheet.getCell(`V${row_count}`).border = {
                top: { style: "none" },
                bottom: { style: "thin" },
                left: { style: "none" },
                right: { style: "thin" },
              };
            }
          }
          if (rowNumber >= 2) {
            if (rowNumber % 2 === 0) {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "D6EDED" },
              };
            } else {
              worksheet.getCell(`${c}${rowNumber}`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF" },
              };
            }
          }
        });
      }
    });
    insideColumns.forEach((v) => {
      if (v !== "Z") {
        worksheet.getCell(`${v}${9}`).border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "none" },
          right: { style: "none" },
        };
        if (v === "V") {
          worksheet.getCell(`${v}${9}`).border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "none" },
            right: { style: "thin" },
          };
        }
        // worksheet.getCell(`${v}${6}`).alignment = { horizontal: "center" }
        worksheet.getCell(`${v}${9}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "43ABAC" },
        };
      }
    });
    let FileSaver = require("file-saver");
    // save workbook to disk
    // eslint-disable-next-line no-undef
    workbook.xlsx
      .writeBuffer("D:/sample.xlsx")
      .then((buffer) =>
        FileSaver.saveAs(new Blob([buffer]), sheetname + ".xlsx")
      )
      .catch((err) => console.log("Error writing excel export", err));
  };
  //export excel

  render() {
    const {
      openmodel,
      profile_crm_id,
      profile_as400_id,
      profile_privacy_policy_consent,
      profile_email_policy_consent,
      name,
    } = this.state;

    return (
      <div>
        <div class="headertop">
          <label style={{ color: "red" }}>
            * Please allow few mins to reflect the changes.
          </label>
          <button class="transprant" onClick={this.refreshPage}>
            <i class="facss fa fa-refresh fa-2x"></i>
          </button>
        </div>
        <div class="headertop">
          {/* search */}
          <div className="headerbottom searchbar">
            <div class="inputbox">
              <input
                class="mr-1 searchtinput"
                maxlength="60"
                value={this.state.searchText}
                onKeyPress={(event) => event.key === "Enter" && this.onSearch()}
                onChange={this.onSearchData}
              ></input>
              <button class="btn btn-primary mr-5" onClick={this.onSearch}>
                Search
              </button>
            </div>
            <div class="inputhint">
              <small>
                Please enter email address / create date (12/26/2021)
              </small>
            </div>
          </div>

          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            // style={{ display: 'none' }}
            id="contained-button-file"
            single
            type="file"
            onChange={this.changeHandler.bind(this)}
          />
        </div>

        {/* <div className="space">
          <div className="text">Search Date:</div>
              <div className="datefield">
                <DatePicker
                  onChange={this.onDateSearch}  format="MM/dd/yyyy"
                  value={this.state.searchDate} name="searchDate"
                />
              </div>
            </div> */}

        {this.state.showMessage && (
          <div className="showMessage">
            File is uploaded to server, check the page few min later{" "}
          </div>
        )}

        {this.state.rowData.length > 0 && (
          <div class="headertop">
            {" "}
            <button
              onClick={this.exportexcelrow}
              className="export btn btn-primary mr-5"
            >
              {/* <img
              alt="excellogo"
              title="Click here to export data as Excel"
              src={excellogo}
              style={{ borderRadius: '4px', height: '30px', width: '30px' }}
            /> */}
              Export Excel
            </button>{" "}
          </div>
        )}

        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
            marginTop: "20px",
          }}
        >
          <div
            id="myGrid"
            style={{
              height: "90vh",
              width: "100%",
              "font-size": "14px",
              "row-height": "60px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs2}
              defaultColDef={this.state.defaultColDef}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              paginationPageSize={this.state.paginationPageSize}
              domLayout={this.state.domLayout}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={true}
            />
          </div>
        </div>
        <div className="headerbottom">
          <button
            class="previous"
            disabled={!this.state.pageData.hasPreviousPage}
            onClick={() => this.onBtPrevious()}
          >
            Previous
          </button>
          <button
            class="next"
            disabled={!this.state.pageData.hasNextPage}
            onClick={() => this.onBtNext()}
          >
            Next
          </button>
        </div>
        <Modal
          show={openmodel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.closemodel}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <fieldset>
              <legend>Profile</legend>
              <div className="space">
                <div className="text"> CRM ID</div>
                <input value={profile_crm_id} onChange={this.crm} />
              </div>

              <div className="space">
                <div className="text"> AS400 ID</div>
                <input value={profile_as400_id} onChange={this.as400} />
              </div>

              <div className="space checkboxc">
                <div className="text"> Privacy Policy Consent</div>
                <input
                  type="checkbox"
                  checked={profile_privacy_policy_consent}
                  onChange={this.privacyOnChange}
                />
              </div>

              <div className="space checkboxc">
                <div className="text"> Email Policy Consent</div>
                <input
                  type="checkbox"
                  checked={profile_email_policy_consent}
                  onChange={this.emailOnChange}
                />
              </div>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onupdate}>Submit</Button>
            <Button onClick={this.closemodel}>Close</Button>
          </Modal.Footer>
        </Modal>

        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default National;
