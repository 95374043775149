import React, { useCallback, useState } from "react";
import { Card, Tabs } from "@shopify/polaris";

import InviteList from "./InviteList";

import store from "store-js";

export default function InviteListTab(props) {
  const currentTab = store.get("InviteListTab") || 0;
  const [selected, setSelected] = useState(currentTab);

  store.set("InviteListTab", selected);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "All",
      title: "All",
      content: "All",
      page: (
        <InviteList
          currentEventID={props.currentEventID}
          currentEventName={props.currentEventName}
          status={""}
        />
      ),
      panelID: "All",
    },
    {
      id: "Invited",
      title: "Invited",
      content: "Invited",
      page: (
        <InviteList
          currentEventID={props.currentEventID}
          currentEventName={props.currentEventName}
          status={"invited"}
        />
      ),
      panelID: "Invited",
    },
    {
      id: "Registered",
      title: "Registered",
      content: "Registered",
      page: (
        <InviteList
          currentEventID={props.currentEventID}
          currentEventName={props.currentEventName}
          status={"registered"}
        />
      ),
      panelID: "Registered",
    },
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabs[selected].page}
      </Tabs>
    </Card>
  );
}
