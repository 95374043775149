import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from "@shopify/polaris";

import PriceLogList from "./components/pricelog/PriceLogList";
import store from "store-js";
import { useSettings } from "../AppContext";

export default function PriceLog() {
  const currentTab = store.get("PriceLogList") || 0;
  const [selected, setSelected] = useState(currentTab);
  store.set("PriceLogList", selected);

  const { shopName } = useSettings();
  const shop = ["sbd-online-dev.myshopify.com", "sbd-employee-store-staging.myshopify.com", "sbd-employee-store.myshopify.com","sbd-employee-store-ca-prod.myshopify.com","sbd-employee-store-ca-dev.myshopify.com","sbd-employee-store-ca-staging.myshopify.com"];
  let isEmployeeStoreUS = false;
  if (shop.includes(shopName)) {
    isEmployeeStoreUS = true;
  }

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "PriceLogList",
      title: "Price Log List",
      content: "Price Log List",
      page: <PriceLogList isEmployeeStoreUS={isEmployeeStoreUS} />,
      panelID: "PriceLogList",
    },
  ];

  return (
    <Page breadcrumbs={[{ url: "/" }]} title={tabs[selected].title} fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].page}
        </Tabs>
      </Card>
    </Page>
  );
}
