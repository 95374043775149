import { useState, useEffect, useRef } from "react";

import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  Frame,
  Icon,
  IndexTable,
  Pagination,
  Select,
  TextField,
  Toast,
  Spinner,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

import {
  ASSIGNMENT_QUEUE_SORT_FIELDS,
  PAGE_SIZE,
  ASSIGNMENT_TYPE,
  BANNER_STATUS,
} from "./constants";

import moment from "moment";

import { useIndexResourceState } from "./useIndexResourceState";

function UnassignedRecords() {
  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const maxRecordsUpdate = 100;

  const [customerForm, setCustomerForm] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [total, setTotal] = useState();

  const resourceName = {
    singular: "Record",
    plural: "Records",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearAllSelection,
  } = useIndexResourceState(customers);

  const rowMarkup = customers.map(
    (
      { id, name, email, reviewerEmail, status, assignedDate, createdDate },
      index
    ) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
        checked={selectedChecbox}
      >
        <IndexTable.Cell>{name}</IndexTable.Cell>
        <IndexTable.Cell>{email}</IndexTable.Cell>
        <IndexTable.Cell>{reviewerEmail}</IndexTable.Cell>
        <IndexTable.Cell>{status}</IndexTable.Cell>
        <IndexTable.Cell>{assignedDate}</IndexTable.Cell>
        <IndexTable.Cell>{createdDate}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  useEffect(() => {
    setCustomerForm(false);

    if (!selectedResources || selectedResources.length === 0) {
    } else {
      if (selectedResources.length > maxRecordsUpdate) {
        updateBanner(
          BANNER_STATUS.WARNING,
          "Documents Updated",
          `Max Records to update is set to: ${maxRecordsUpdate}`
        );
      }
    }
  }, [selectedResources]);

  const debounceTimeout = 1000;
  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const getDataTableSource = (list) => {
    const lst = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      lst.push({
        id: item.id,
        name: item.displayName,
        email: item.email,
        reviewerEmail: item.reviewer || "",
        status: item.status,
        assignedDate: item.assignedDate
          ? moment(item.assignedDate).utc().format("MM/DD h:mm a")
          : "",
        createdDate: moment(item.createdDate).utc().format("MM/DD h:mm a"),
      });
    }

    return lst;
  };

  const [query, setQuery] = useState({
    search: "",
    sortField: "Created Date, Old to New",
    sortOrder: "asc",
    page: 1,
    pageSize: 20,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      params.push(`${key}=${encodeURIComponent(query[key])}`);
    }

    return params;
  };

  const [routePath, setRoutePath] = useState("unAssigned");

  const [assignmentOptions, setAssignmentOptions] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("1");

  const [selectedChecbox, setSelectedCheckbox] = useState(false);

  useEffect(() => {
    const list = ASSIGNMENT_TYPE.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setAssignmentOptions(list);
  }, []);

  const assignmentChanged = (option) => {
    setSelectedCheckbox(false);
    // setPageSize(option);
    const found = ASSIGNMENT_TYPE.find((item) => item.id === parseInt(option));
    clearAllSelection();
    setRoutePath(found["routePath"]);
    setSelectedAssignment(option);
  };

  const getAssignmentQueues = async () => {
    const bannerTitle = "Assignment Queue";

    const params = queryParams();

    const assignMentPath = routePath;

    const msgTitle =
      assignMentPath == "assignmentQueue" ? "All" : assignMentPath;

    const url = `/api/promo/submissions/${assignMentPath}?${params.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, hasPrevious, hasNext, total } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
          setShowSpinner(false);
        } else {
          setShowSpinner(false);
          updateBanner(
            BANNER_STATUS.CRITICAL,
            `${msgTitle} Records`,
            msg ? msg : `Error loading Records`
          );
        }

        setHasPrevious(hasPrevious);
        setHasNext(hasNext);

        const totalPages = Math.ceil(total / query.pageSize);
        setTotal(totalPages);

        let list = data.list;

        if (!list || list.length === 0) {
          setCustomers([]);
          return;
        }

        const lst = getDataTableSource(list);
        setCustomers(lst);
      } else {
        setCustomers([]);
        setShowSpinner(false);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          `${msgTitle} Records`,
          msg ? msg : `Error loading Records`
        );
      }
    } catch (error) {
      setCustomers([]);
      setShowSpinner(false);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        `${msgTitle} Records`,
        msg ? err : `Error loading Records`
      );
    }
  };

  const searchChanged = (value) => {
    setQuery({
      ...query,
      search: value,
    });
  };

  useEffect(() => {
    debounce(getAssignmentQueues, debounceTimeout);
  }, [query, routePath]);

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const [sortOptions, setSortOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState("1");

  const [selectedReviewerOptions, setSelectedReviewerOptions] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState("");

  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [selectedPageSize, setPageSize] = useState("2");

  useEffect(() => {
    const list = PAGE_SIZE.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setPageSizeOptions(list);
  }, []);

  const pageSizeChanged = (option) => {
    setPageSize(option);

    const found = PAGE_SIZE.find((item) => item.id === parseInt(option));

    setQuery({
      ...query,
      pageSize: found.size,
    });
  };

  useEffect(() => {
    const list = ASSIGNMENT_QUEUE_SORT_FIELDS.map((item) => {
      return {
        label: item.title,
        value: item.id.toString(),
      };
    });

    setSortOptions(list);
  }, []);

  const sortChanged = (option) => {
    setSelectedSort(option);

    const found = ASSIGNMENT_QUEUE_SORT_FIELDS.find(
      (item) => item.id === parseInt(option)
    );

    setQuery({
      ...query,
      sortField: found.field,
      sortOrder: found.order,
    });
  };

  useEffect(async () => {
    const url = `/api/promo/reviewers/list`;
    const response = await axios.get(url);

    const data = response.data;
    const { status, msg, list } = data;

    if (!status) {
      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Assignee",
        msg ? msg : `Error loading assignee`
      );
    }
    const listOptions = list.map(({ email, id }) => {
      return {
        label: email,
        value: id.toString(),
      };
    });
    setSelectedReviewerOptions(listOptions);
  }, []);

  const reviewerChanged = (option) => {
    setSelectedReviewer(option);
  };

  const assignReviewer = async () => {
    const url = `/api/promo/submissions/assign`;

    const data = {
      list: selectedResources,
      reviewer: selectedReviewer,
    };
    const response = await axios.post(url, data);

    if (response && response.data && response.data.status) {
      const { msg } = response.data;
      updateBanner(
        BANNER_STATUS.SUCCESS,
        "Assignee Changed",
        msg ? msg : `Assignee changes successfully`
      );
      getAssignmentQueues();
    } else {
      updateBanner(
        BANNER_STATUS.CRITICAL,
        "Assignee Changed",
        msg ? msg : `Error : Assignee not changed`
      );
    }
  };

  const exportAssignments = async () => {
    const bannerTitle = "Export";

    const msgTitle = routePath == "assignmentQueue" ? "All" : routePath;

    const url = `/api/promo/submissions/export/${routePath}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            `Export ${msgTitle} Records`,
            msg ? msg : `Error in exporting unassigned records`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          `Export ${msgTitle} Records`,
          msg ? msg : `Error in exporting unassigned Queue`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        `Export ${msgTitle} Records`,
        msg ? err : `Error in exporting unassigned Queue`
      );
    }
  };

  const getError = (error) => {
    console.log(error);

    let err = null;

    if (error?.response) {
      const { status, msg } = error.response.data;
      err = msg;
    } else if (error?.request) {
      err = "Request error";
    } else {
      err = error?.message || "";
    }

    return err;
  };

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <div style={{ minWidth: "330px" }}>
          <TextField
            placeholder="Search Submissions"
            value={query.search}
            onChange={searchChanged}
            autoComplete="off"
            prefix={<Icon source={SearchMinor} color="base" />}
            alignItems="end"
          />
        </div>

        <div className="assignmentType" style={{ marginLeft: "5px" }}>
          <Select
            label=""
            placeholder="Assignment Type"
            options={assignmentOptions}
            onChange={assignmentChanged}
            value={selectedAssignment}
          />
        </div>

        {/*<div style={{ marginLeft: "5px", minWidth: "150px" }}>
          <Button primary fullWidth onClick={exportAssignments}>
            {" "}
            Export
          </Button>
        </div>*/}

        <div style={{ width: "100%" }}></div>

        <div className="headerDropdown">
          <div className="dropdownTitle">
            <label>Page Size:</label>
          </div>
          <div>
            <Select
              label=""
              placeholder="Page Size"
              options={pageSizeOptions}
              onChange={pageSizeChanged}
              value={selectedPageSize}
            />
          </div>
        </div>

        <div className="headerDropdown">
          <div className="dropdownTitle">
            <label>Sort By:</label>
          </div>
          <Select
            label=""
            placeholder="Sort By"
            options={sortOptions}
            onChange={sortChanged}
            value={selectedSort}
          />
        </div>
        {total > 0 && (
          <label
            style={{
              minWidth: "90px",
              textAlign: "right",
              paddingLeft: "10px",
              paddingTop: "8px",
            }}
          >
            {query.page} of {total}
          </label>
        )}
        <div style={{ marginLeft: 10 }}>
          <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              setQuery({
                ...query,
                page: query.page - 1,
              });
            }}
            hasNext={hasNext}
            onNext={() => {
              setQuery({
                ...query,
                page: query.page + 1,
              });
            }}
          />
        </div>
      </div>

      {selectedResources && selectedResources.length > 0 && (
        <div
          style={{
            paddingLeft: "10px",
            paddingBottom: "10px",
            gap: "5px",
            display: "flex",
          }}
        >
          <div style={{ minWidth: "330px" }}>
            <Select
              label=""
              placeholder="Select Assignee"
              options={selectedReviewerOptions}
              onChange={reviewerChanged}
              value={selectedReviewer}
            />
          </div>

          <div style={{ minWidth: "150px" }}>
            <Button
              disabled={!selectedReviewer}
              primary
              fullWidth
              onClick={assignReviewer}
            >
              {routePath == "assigned" ? "Reassign" : "Assign"}
            </Button>
          </div>
        </div>
      )}

      {(!selectedResources || selectedResources.length === 0) && !customerForm && (
        <div
          style={{
            paddingLeft: "10px",
            paddingBottom: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
          }}
        ></div>
      )}

      {showSpinner && (
        <div className="loader">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      )}

      {customerForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          ></div>
        </div>
      )}

      {/* {importForm && (
        <div
          style={{
            padding: "10px",
            borderBottom: "rgba(225, 227, 229, 1) solid",
            width: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "400px",
            }}
          >
          </div>
        </div>
      )} */}

      {customers && (
        <IndexTable
          resourceName={resourceName}
          itemCount={customers.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={[
            { title: "Name" },
            { title: "Email" },
            { title: "Assignee" },
            { title: "Status" },
            { title: "Assigned Date" },
            { title: "Created Date" },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      )}

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default UnassignedRecords;
