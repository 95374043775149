import React, { Component } from "react";
// import axios from "axios";
import axios from "../../../axiosInstance";
import moment from "moment";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-date-picker";

import "bootstrap/dist/css/bootstrap.min.css";

class Report extends Component {
  constructor(props) {
    super(props);

    this.customerId = props.customerId;
    

    this.state = {
      dateFrom: new Date(),
      dateTo: new Date(),
      statusError: false,
      statusText: "",
      refresh: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      statusError: false,
      statusText: `Select date From, date To and click Generate Report button`,
    });
  }

  dateFromChanged = (e) => {
    if (e) {
      this.setState({
        dateFrom: e,
      });
    }
  };

  dateToChanged = (e) => {
    if (e) {
      this.setState({
        dateTo: e,
      });
    }
  };

  compareDates = () => {
    const { dateFrom, dateTo } = this.state;
    return (
      moment(dateFrom).format("YYYY-MM-DD") >
      moment(dateTo).format("YYYY-MM-DD")
    );
  };

  updateRefresh = (e) => {
    this.setState({
      refresh: e.target.checked,
    });
  };

  generateReport = async (e) => {
    this.setState({
      loading: true,
    });

    const { dateFrom, dateTo, refresh } = this.state;
    const url = `/api/fraudCheck/reportFromShopify?dateFrom=${moment(dateFrom).format(
      "YYYY-MM-DD"
    )}&dateTo=${moment(dateTo).format("YYYY-MM-DD")}&refresh=${refresh}`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, { headers: headers });

      const data = response.data;

      this.setState({
        statusError: !data.status,
        statusText: data.msg,
      });
    } catch (err) {
      console.log(err);

      this.setState({
        statusError: true,
        statusText: `Error generating report: ${err}`,
      });
    }

    this.setState({
      loading: false,
    });
  };

  render() {
    return (
      <Container className="p-2">
        <Row className="p-2">
          <Col style={{ maxWidth: "50px" }}>
            <label className="mt-2">From:</label>
          </Col>
          <Col xs={10}>
            <DatePicker
              onChange={this.dateFromChanged}
              clearIcon={null}
              value={this.state.dateFrom}
              name="dateFrom"
              className="mr-5"
              format="MM/dd/yyyy"
              maxDate={new Date()}
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col style={{ maxWidth: "50px" }}>
            <label className="mt-2">To:</label>
          </Col>
          <Col xs={10}>
            <DatePicker
              onChange={this.dateToChanged}
              clearIcon={null}
              value={this.state.dateTo}
              name="dateTo"
              className="mr-5"
              format="MM/dd/yyyy"
            />
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <label
              style={{
                display: "block",
                paddingLeft: "15px",
                textIndent: "-15px",
              }}
            >
              <input
                type="checkbox"
                style={{
                  width: "15px",
                  height: "15px",
                  padding: "0",
                  margin: "0",
                  verticalAlign: "bottom",
                  position: "relative",
                  top: "-3px",
                }}
                checked={this.state.refresh}
                onChange={this.updateRefresh}
              />
              <span className="pl-2">Refresh Ekata</span>
            </label>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <button
              onClick={this.generateReport}
              className="export btn btn-primary mr-5"
              disabled={this.compareDates()}
              style={{ fontSize: "14px", width: "150px" }}
            >
              {this.state.loading && (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ padding: "10px", fontSize: "14px" }}>
                    Loading...
                  </span>
                </div>
              )}
              {!this.state.loading && <span>Generate Report</span>}
            </button>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
            <span
              style={{
                color: this.state.statusError ? "red" : "green",
                fontSize: "15px",
              }}
            >
              {this.state.statusText}
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Report;
