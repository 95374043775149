import './App.css';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie'

import unauthorized from './pages/unauthorized';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './PrivateRoute';
import Students from './pages/Students';
import Staff from './pages/Staff';
import NationalPartners from './pages/National';
import PartnersUser from './pages/partnersusers';
import RetireeUsers from './pages/retireeusers';
import Events from './pages/Events';
import BulkPrice from './pages/bulkprice';
import PriceControl from './pages/PriceLog';
import SalsifyDisplayName from './pages/SalsifyDisplayName';
import Coupons from './pages/coupons';
import Returns from './pages/Returns';
import ProductMedia from './pages/ProductMedia';
import Solr from './pages/Solr';
import BundleReport from "./pages/bundleReport";
import Salsify from './pages/Salsify';
import Ekata from './pages/Ekata';
import SolrFilters from './pages/SolrFilters';
import PriceSpider from './pages/PriceSpider';
import RegistrationCodes from './pages/RegistrationCodes';
import Credits from './pages/Credits';
import KnotReport from './pages/knotReport'
import Giveaways from './pages/Giveaways';
import Algolia from './pages/Algolia';
import Invites from './pages/Invites';
import Promo from './pages/Promo';
import NotFound from './pages/NotFound';

import { SettingsProvider } from './AppContext';

function App({ hasCookie }) {
  const routes = [
    {
      path: '/',
      component: <Dashboard />
    },
    {
      path: '/students',
      component: <Students />
    },
    {
      path: '/staff',
      component: <Staff />
    },
    {
      path: '/national',
      component: <NationalPartners />
    },
    {
      path: '/pricecontrol',
      component: <PriceControl />
    },
    {
      path: '/partnerusers',
      component: <PartnersUser />
    },
    {
      path: '/retireeusers',
      component: <RetireeUsers />
    },
    {
      path: '/events',
      component: <Events />
    },
    {
      path: '/bulkprice',
      component: <BulkPrice />
    },
    {
      path: '/salsifyDisplay',
      component: <SalsifyDisplayName />
    },
    {
      path: '/coupons',
      component: <Coupons />
    },
    {
      path: '/orderReturns',
      component: <Returns />
    },
    {
      path: '/productMedia',
      component: <ProductMedia />
    },
    {
      path: '/solr',
      component: <Solr />
    },
    {
      path: '/bundleReport',
      component: <BundleReport />
    },
    {
      path: '/salsify',
      component: <Salsify />
    },
    {
      path: '/ekata',
      component: <Ekata />
    },
    {
      path: '/solrFilters',
      component: <SolrFilters />
    },
    {
      path: '/priceSpider',
      component: <PriceSpider />
    },
    {
      path: '/registrationCodes',
      component: <RegistrationCodes />
    },
    {
      path: '/credits',
      component: <Credits />
    },
    {
      path: '/knotReport',
      component: <KnotReport />
    },
    {
      path: '/giveaways',
      component: <Giveaways />
    },
    {
      path: '/algolia',
      component: <Algolia />
    },
    {
      path: '/invites',
      component: <Invites />
    },
    {
      path: '/promo',
      component: <Promo />
    },
    {
      path: '*',
      component: <NotFound />
    }
  ];

  useEffect(() => {
    return () => {
      const list = ['state', 'apiKey', 'shopName', 'accessToken', '_token', '_id', '_c_id', 'customerToken', '_customApp', '_resources'];
      list.map(item => Cookies.remove(item));
    }
  }, []);

  return (
    <SettingsProvider>
      <Router>
        <Switch>
          {routes && routes.map(route => {
            return <PrivateRoute exact path={route.path} hasCookie={hasCookie}>
              {route.component}
            </PrivateRoute>
          })}
          <Route path="/unauthorized" component={unauthorized} />
        </Switch>
      </Router>
    </SettingsProvider>
  );
}

export default App;
