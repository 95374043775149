const dataTypes = ['String', 'Boolean', 'Integer', 'Float', 'Date'];
const controlStyles = ['Checkbox', 'Radio', 'Custom'];
const custom = ['', 'Range', 'Range/Stars'];

const COLUMNS = {
    ACTIVE: 'active'
};

module.exports = {
    dataTypes,
    controlStyles,
    custom,
    COLUMNS
};