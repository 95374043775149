import React, { useCallback, useState } from "react";
import { Page, Card } from "@shopify/polaris";

import Sync from "./components/Algolia/Sync";

function Algolia() {
  return (
    <Page breadcrumbs={[{ url: "/" }]} title="Algolia Sync" fullWidth>
      <Card>
		<Sync />
      </Card>
    </Page>
  );
}

export default Algolia;
