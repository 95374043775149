import React, { useCallback, useState } from "react";
import { Page, Card, Tabs } from '@shopify/polaris';

import SalsifyDisplayNameList from './components/SalsifyDisplayName/SalsifyDisplayNameList';
import store from 'store-js';


export default function SalsifyDisplayName() {
    const currentTab = store.get('SalsifyDisplayNameList') || 0;
    const [selected, setSelected] = useState(currentTab);
    store.set('SalsifyDisplayNameList', selected);
    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );


    const tabs = [
        {
            id: 'SalsifyDisplayNameList',
            title: 'Salsify Display Names',
            content: 'Salsify Display Names',
            page: <SalsifyDisplayNameList
             />,
            panelID: 'SalsifyDisplayNameList',
        }
    ];


    return (
        <Page
            breadcrumbs={[{ url: '/' }]}
            title = {tabs[selected].title}
            fullWidth>
            <Card>
                
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    {tabs[selected].page}
                </Tabs>
            </Card>
        </Page>
    );

}
