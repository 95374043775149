import { useState, useEffect, useRef } from "react";

import axios from "../../../axiosInstance";
import {
  Banner,
  Button,
  Frame,
  TextField,
  Toast,
  DataTable,
  Link,
  Spinner,
} from "@shopify/polaris";

import { BANNER_STATUS } from "./constants";

const MODE = {
  ADD: "add",
  EDIT: "edit",
};

function DenyList() {
  const [showSpinner, setShowSpinner] = useState(true);

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const [list, setList] = useState([]);
  const [mode, setMode] = useState(MODE.ADD);
  const [id, setId] = useState(null);
  const [reason, setReason] = useState("");
  const [orderInList, setOrderInList] = useState("");

  const mapData = (list) => {
    return list.map((item) => {
      const link = (
        <Link
          removeUnderline
          onClick={(e) => {
            setMode(MODE.EDIT);

            const { id, reason, orderInList } = item;

            setId(id);
            setReason(reason);

            if (orderInList) {
              setOrderInList(orderInList.toString());
            } else {
              setOrderInList(null);
            }
          }}
        >
          {item.reason}
        </Link>
      );

      return [link, item.orderInList];
    });
  };

  const debounceTimeout = 1000;
  const timerRef = useRef(null);

  const debounce = function (func, delay) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(func, delay);
  };

  const getList = async () => {
    const url = `/api/promo/deny/list`;

    try {
      const response = await axios.get(url);

      if (response && response.data) {
        setShowSpinner(false);

        const data = response.data;
        const { status, msg, list } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          setShowSpinner(false);

          updateBanner(
            BANNER_STATUS.CRITICAL,
            "Deny Reason",
            msg ? msg : `Error loading deny list`
          );
        }

        let lst = list;

        if (!lst || lst.length === 0) {
          setList([]);
          return;
        }

        lst = mapData(lst);
        setList(lst);
      } else {
        setShowSpinner(false);

        setList([]);

        updateBanner(
          BANNER_STATUS.CRITICAL,
          "Deny Reason",
          msg ? msg : `Error querying deny list`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error getting deny list`
      );
    }
  };

  const columnContentTypes = ["text", "text"];

  const headings = ["Reason", "Order in list"];

  useEffect(() => {
    debounce(getList, debounceTimeout);
  }, []);

  const reasonChanged = (value) => {
    setReason(value);
  };

  const orderInListChanged = (value) => {
    setOrderInList(value);
  };

  const reset = () => {
    setMode(MODE.ADD);

    setId(null);
    setReason("");
    setOrderInList("");
  };

  const cancel = () => {
    reset();
  };

  const addDenyReason = async () => {
    const bannerTitle = "Deny Reason";

    const url = `/api/promo/deny`;

    const data = {
      reason,
      orderInList:
        orderInList !== undefined && orderInList && orderInList.length > 0
          ? orderInList
          : null,
    };

    try {
      const response = await axios.post(url, data);

      if (response && response.data) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          updateBanner(
            BANNER_STATUS.SUCCESS,
            bannerTitle,
            msg ? msg : `New Reason deleted`
          );

          reset();
          getList();
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error creating Reason`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error creating Reason`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error creating Reason`
      );
    }
  };

  const updateDenyReason = async () => {
    const bannerTitle = "Deny Reason";

    const url = `/api/promo/deny/${id}`;

    const data = {
      reason,
      orderInList:
        orderInList !== undefined && orderInList && orderInList.length > 0
          ? orderInList
          : null,
    };

    try {
      const response = await axios.patch(url, data);

      if (response && response.data) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          updateBanner(
            BANNER_STATUS.SUCCESS,
            bannerTitle,
            msg ? msg : `New Reason updated`
          );

          reset();
          getList();
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error updating Reason`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error updating Reason`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error updating Reason`
      );
    }
  };

  const deleteDenyReason = async () => {
    const bannerTitle = "Deny Reason";

    const url = `/api/promo/deny/${id}`;

    try {
      const response = await axios.delete(url);

      if (response && response.data) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          updateBanner(
            BANNER_STATUS.SUCCESS,
            bannerTitle,
            msg ? msg : `Reason deleted`
          );

          reset();
          getList();
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error deleting Reason`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error deleting Reason`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error deleting Reason`
      );
    }
  };

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const getError = (error) => {
    console.log(error);

    let err = null;

    if (error?.response) {
      const { status, msg } = error.response.data;
      err = msg;
    } else if (error?.request) {
      err = "Request error";
    } else {
      err = error?.message || "";
    }

    return err;
  };

  return (
    <Frame>
      {showBanner && (
        <div
          style={{
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
          }}
        >
          <Banner
            title={bannerTitle}
            status={bannerStatus}
            onDismiss={() => {
              setShowBanner(false);
            }}
          >
            <p>{bannerDescription}</p>
          </Banner>
        </div>
      )}

      <div
        style={{
          display: "flex",
          padding: "10px",
        }}
      >
        <div style={{ minWidth: "330px" }}>
          <TextField
            placeholder="Reason"
            value={reason}
            onChange={reasonChanged}
            autoComplete="off"
          />
        </div>

        <div style={{ marginLeft: "5px", minWidth: "80px" }}>
          <TextField
            placeholder="Order"
            value={orderInList}
            onChange={orderInListChanged}
            autoComplete="off"
          />
        </div>

        {mode === MODE.ADD && (
          <>
            <div style={{ marginLeft: "10px", minWidth: "130px" }}>
              <Button primary fullWidth onClick={addDenyReason}>
                Add
              </Button>
            </div>

            <div style={{ marginLeft: "10px", minWidth: "130px" }}>
              <Button primary fullWidth onClick={cancel}>
                Clear
              </Button>
            </div>
          </>
        )}

        {mode === MODE.EDIT && (
          <>
            <div style={{ marginLeft: "10px", minWidth: "130px" }}>
              <Button primary fullWidth onClick={updateDenyReason}>
                Update
              </Button>
            </div>

            <div style={{ marginLeft: "10px", minWidth: "130px" }}>
              <Button primary fullWidth onClick={deleteDenyReason}>
                Delete
              </Button>
            </div>

            <div style={{ marginLeft: "10px", minWidth: "130px" }}>
              <Button primary fullWidth onClick={cancel}>
                Cancel
              </Button>
            </div>
          </>
        )}

        <div style={{ width: "100%" }}></div>
      </div>

      {showSpinner && (
        <div className="loader">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      )}
      {list && (
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={list}
        />
      )}
      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default DenyList;
